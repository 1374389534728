<template>
  <div class="description">
    <div v-if="icon" :class="['icon', icon]">
      <img :src="iconSrc" :alt="title" v-if="iconSrc" />
    </div>
    <div class="description-content">
      <div :class="['title', { underline: isUnderline, 'title-mini': isMini }]">
        <a
          v-if="titleHref"
          :target="titleHref.indexOf('http') !== -1 ? '_blank' : undefined"
          :href="titleHref"
          class="title-span"
          v-html="title"
        ></a>
        <span v-else class="title-span" v-html="title"></span>
      </div>
      <div v-if="more" class="more" v-html="more"></div>
      <div
        :class="['desc', { 'desc-mini': isMini }]"
        v-if="desc"
        v-html="desc"
      ></div>
    </div>
    <ul
      class="dotted"
      :class="[{ 'is-horizontal': isListHorizontal }]"
      v-if="list"
    >
      <li v-for="(item, index) in list" :key="index" v-html="item"></li>
    </ul>
    <ButtonComponent v-if="button" :href="href" :url="href" class="button-description">{{
      button
    }}</ButtonComponent>
  </div>
</template>

<script>
export default {
  name: "Description",
  props: {
    icon: String,
    iconSrc: String,
    more: String,
    button: String,
    titleHref: String,
    title: String,
    desc: String,
    href: String,
    list: Array,
    isUnderline: Boolean,
    isMini: Boolean,
    isListHorizontal: Boolean
  }
};
</script>

<style lang="scss">
.description {
  .description-content {
    display: grid;
    @media all and (max-width: $md) {
      grid-gap: 20px;
    }
    grid-gap: 25px;
  }
  display: grid;
  .title {
    a {
      color: $text-main;
      @extend %link;
    }
  }
  &.icon-left {
    .description-content {
      display: grid;
      @media all and (max-width: $md) {
        grid-gap: 20px;
      }
      grid-gap: 14px;
    }
    @media all and (max-width: $md) {
      grid-template-columns: 32px 1fr;
    }
    grid-gap: 13px 22px;
    grid-template-columns: 42px 1fr;
    .title {
      @media all and (max-width: $md) {
        margin-top: 4px;
      }
    }
    .icon {
      margin-left: 14px;
      width: 28px;
      height: 28px;
      padding: 0;
      background: $color-accent;
      border-radius: 14px;
      font-size: 28px;
      display: grid;
      justify-content: center;
      align-content: center;
      &::before {
        color: $text-inverse-main;
      }
    }
    ul {
      grid-column: 2;
      margin-bottom: 0;
    }
    .button-component {
      grid-column: 2;
      margin-bottom: 0;
      width: 186px;
    }
  }
  &.icon-top {
    grid-gap: 13px;
    .icon {
      height: 40px;

      img {
        max-width: 40px;
        max-height: 40px;
        object-fit: contain;
        object-position: left top;
      }
    }
  }
  &.default {
    .title {
      @extend %text-h2;
    }
    .desc {
      @extend %text-big;
    }
    @media all and (max-width: $md) {
      grid-gap: 20px;
    }
    grid-gap: 20px;
  }
  &.big {
    .title {
      @extend %text-h1;
    }
    .desc {
      @extend %text-big;
    }
    display: grid;
    grid-gap: 20px;
  }
  &.small {
    .title {
      @extend %text-h3;
    }
    .desc {
      @extend %text-big;
      p {
        margin-top: 0;
        margin-bottom: 0;
      }
      p + p {
        margin-top: 10px;
      }
    }
    @media all and (max-width: $md) {
      grid-gap: 10px 20px;
    }
    grid-gap: 25px;
  }
  &.mini {
    .title {
      @extend %text-h3;
    }
    .desc {
      @extend %text-default;
    }
  }
  &.desc-small {
    .desc {
      @extend %text-default;
    }
  }
  .more {
    @extend %text-h3;
  }
  ul.is-horizontal {
    grid-auto-flow: column;
  }
}
</style>
