<template>
  <div
    :class="['banner', `theme-${theme}`]"
    id="main-banner"
    :style="{
      '--count': banners.length
    }"
    v-if="banners"
  >
    <div class="area">
      <div class="banners-container" ref="animated">
        <section
          class="list-section-baner row"
          v-for="(l, i) in banners"
          :key="i"
          :class="[
            { current: current === i, 'animated-banner': banners.length > 1 }
          ]"
          :style="{
            transform: translate
          }"
        >
          <img
            class="img-banner"
            v-if="l.img"
            :alt="l.img.src"
            :src="l.img.src"
            :style="styleImg"
          />
          <video
            class="img-banner"
            muted
            autoplay
            playsinline
            webkit-playsinline
            loop
            v-if="l.video"
            :style="styleImg"
          >
            <source :src="l.video.src" />
          </video>
          <div class="content col-5 col-md-8" v-if="l.text">
            <Description
              class="big"
              ref="bannerDescription"
              :typeEffect="isMain"
              id="description-header"
              :title="l.text.title"
              :desc="l.text.desc"
              :isUnderline="isUnderline"
              :button="l.button"
              href="#callback"
            />
          </div>
        </section>
        <ul class="nav-carousel" v-show="banners.length > 1">
          <li
            v-for="(l, i) in banners.length"
            :class="['default', { active: current === i }]"
            :id="i"
            :key="i"
            @click.prevent="() => selectNav(i)"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { setTimeout, clearTimeout } from "timers";
import firebase from "../firebase";
import AppData from "../AppData";
export default {
  name: "Banner",
  props: {
    isMain: Boolean,
    theme: {
      default: "default"
    },
    list: Array,
    isUnderline: {
      default: true
    },
    index: Number,
    styleImg: Object
  },
  data() {
    return {
      banners: Array,
      current: 0,
      timer: 0,
      firebase: firebase
    };
  },
  computed: {
    translate() {
      return `translateX(-${this.currentBanner * 100}%)`;
    }
  },
  methods: {
    selectNav(index) {
      this.current = index;
      this.startTimer();
    },
    startTimer() {
      var banners = this.banners;
      if (banners && banners.length > 1) {
        clearTimeout(this.timer);
        this.timer = setTimeout(
          this.selectNav,
          5000,
          (this.current + 1 + banners.length) % banners.length
        );
      }
    }
  },
  mounted() {
    const db = firebase.firestore();
    const settings = {};
    db.settings(settings);
    AppData.fetchData("main").then(result => {
      if (this.index !== undefined) {
        this.banners = [result.banner.list[this.index]];
      } else {
        this.banners = result.banner.list;
      }
      this.startTimer();
    });

    this.startTimer();
    setTimeout(() => {
      if (this.$refs.animated) {
        this.$refs.animated.classList.add("animated");
      }
    }, 1000);
  },
  components: {}
};
</script>

<style lang="scss">
$count: var(--count);
.banner {
  @media all and (max-width: $md) {
    min-height: 223px;
  }
  overflow: hidden;
  background: $bg-green;
  .banners-container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    &.animated {
      .list-section-baner {
        visibility: visible;
        &.current {
        }
      }
    }
  }
  .list-section-baner {
    &.animated-banner {
      @extend %switch-anim;
    }
    position: relative;
    visibility: hidden;
    &.current {
      visibility: visible;
      display: grid;
    }
    &::before {
      @media all and (max-width: $md) {
        min-height: 223px;
        width: 203px;
        height: 197px;
        right: -57px;
      }
      width: 460px;
      height: 445px;
      background: url("../assets/icons/banner-bg.svg") right top no-repeat;
      position: absolute;
      right: -137px;
      top: 0;
      content: "";
      display: block;
    }
    @media all and (max-width: $md) {
      min-height: 223px;
    }
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    align-content: center;
    min-height: 364px;
    .img-banner {
      @media all and (max-width: $md) {
        object-fit: cover;
        max-height: 180px;
        max-width: auto;
        height: 180px;
        transform: translateX(10%);
      }
      @media all and (max-width: $sm) {
        transform: translateX(40%);
      }
      max-height: 350px;
      position: absolute;
      right: -20px;
      bottom: 0;
    }
    .button-description {
      @media all and (max-width: $md) {
        margin-top: 5px;
      }
      width: 186px;
      margin-top: 20px;
    }
    .content {
      @media all and (max-width: $md) {
        grid-column: span 2;
      }
      position: relative;
      z-index: 1;
      grid-gap: 40px;
      display: grid;
      justify-content: flex-start;
      justify-items: flex-start;
      grid-column: 1 / span 6;
    }
  }
  min-height: 364px;
  .nav-carousel {
    @media all and (max-width: $md) {
      padding: 0 14px;
      grid-auto-flow: column;
      grid-gap: 0;
      grid-auto-columns: minmax(0, 1fr);
      grid-template-columns: unset;
    }
    margin-top: 50px;
    position: absolute;
    display: grid;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    grid-template-columns: repeat($count, 93px);
    grid-column-gap: 25px;
    list-style-type: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    z-index: 10;
    bottom: 28px;
    li {
      cursor: pointer;
      display: block;
      justify-self: flex-start;
      align-self: center;
      width: 100%;
      padding: 10px 0;
      transition: border-color 0.5s;
      border-bottom: 2px solid #fff;
      border-radius: 0;
    }

    .default {
      @media all and (max-width: $md) {
        border-color: $bg-main;
      }
      border-color: $bg-main;
    }

    .active {
      @media all and (max-width: $md) {
        border-color: $color-accent;
      }
      border-color: $color-accent;
    }
  }
}
</style>
