<template>
  <div class="footer-container">
    <div class="area">
      <div class="row">
        <ul class="menu col-9 col-md-12" v-if="!isMini">
          <li
            class="menu-item"
            v-for="(l, i) in menu"
            :key="i"
            :class="[{ opened: openedMenus.indexOf(i) !== -1 }]"
          >
            <div>
              <router-link
                class="link inverse"
                :href="l.href"
                tag="a"
                :to="l.href"
                v-html="l.title"
              ></router-link>
              <a
                href="/"
                class="button-expand icon-button-up"
                @click.prevent="
                  () => {
                    expandMenu(i);
                  }
                "
              ></a>
            </div>
            <ul
              class="submenu"
              ref="submenu"
              :class="[{ opened: openedMenus.indexOf(i) !== -1 }]"
              :style="{
                'max-height': openedMenus.indexOf(i) === -1 ? 0 : height[i]
              }"
              v-if="l.subMenu"
            >
              <li
                class="submenu-item"
                v-for="(sub, ind) in l.subMenu"
                :key="ind"
                v-show="sub.visible"
              >
                <router-link
                  class="link inverse"
                  :href="sub.href"
                  tag="a"
                  :to="sub.href"
                  v-html="sub.title"
                ></router-link>
              </li>
            </ul>
          </li>
        </ul>
        <Contacts
          v-if="!isMini"
          class="contacts inverse col-3 col-md-12"
          :title="contacts.title"
          :place="contacts.place"
          :phone="contacts.phone"
          :email="contacts.email"
          :is-inverse="true"
        />
        <div class="copyright col-9 col-md-12">
          <router-link to="/" class="logo-footer"></router-link>
          <span class="copy" v-html="desc"></span>
          <div>
            <a
              class="policy link inverse"
              :href="policy.href"
              :target="
                policy.href.indexOf('http') !== -1 ? '_blank' : undefined
              "
              v-html="policy.title"
            ></a>
          </div>
        </div>
        <div class="made-in col-3 col-md-12">
          <a class="move-to-top" href="/" @click.prevent="scrollToTop">
            <i class="icon-button-up"></i>
          </a>
          <BaseLabel class="madein" icon-class="icon-bermudas-logo-footer">
            <span v-html="madein"></span>
          </BaseLabel>
        </div>
      </div>
    </div>
    <!-- Yandex.Metrika counter -->
  </div>
</template>

<script>
import Contacts from "../components/Contacts";
import utils from "../utils";
export default {
  name: "FooterContainer",
  props: {
    isMini: Boolean,
    menu: Array,
    contacts: Object,
    desc: String,
    madein: String,
    policy: Object
  },

  data() {
    return {
      openedMenus: [],
      height: [],
      utils: utils
    };
  },
  methods: {
    scrollToTop() {
      this.$emit("scrolltotop");
      this.utils.scrollToTop();
    },
    expandMenu(index) {
      if (this.openedMenus.indexOf(index) !== -1) {
        this.openedMenus.splice(this.openedMenus.indexOf(index), 1);
      } else {
        this.openedMenus.push(index);
      }
    },
    onResize() {
      if (this.$refs.submenu) {
        this.$refs.submenu.forEach((el, index) => {
          this.height[index] = el.scrollHeight + "px";
        });
      }
    }
  },
  created() {
    if (this.$refs.submenu) {
      this.height = [];
      this.menu.forEach(() => {
        this.height.push(0);
      });
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  components: {
    Contacts
  }
};
</script>

<style lang="scss">
.footer-container {
  overflow: hidden;
  align-self: flex-end;
  background: $bg-inverse-main;
  @extend %page-block;
  @extend %text-inverse;
  .row {
    grid-row-gap: 38px;
  }
  .logo-footer {
    @media all and (max-width: $md) {
      grid-column: unset;
      margin-left: 0;
      grid-row: 1;
    }

    margin-bottom: 27px;
    width: 142px;
    height: 54px;
    background: url("../assets/icons/logo-footer.svg") center / contain
      no-repeat;
  }
  ul {
    margin-bottom: 0;
  }
  .menu {
    @media all and (max-width: $md) {
      grid-auto-columns: 1fr;
      grid-auto-flow: row;
    }
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(min-content, 235px);
    br {
      @media all and (max-width: $md) {
        display: none;
      }
    }
    .menu-item {
      position: relative;
      @media all and (max-width: $md) {
        margin-bottom: 0;
        border: 1px solid $text-inverse-main-half;
        padding: 6px 11px;
        display: block;
      }
      grid-gap: 28px;
      display: grid;
      align-content: flex-start;
      a {
        font-size: 15px;
      }
      a.button-expand {
        @media all and (max-width: $md) {
          font-size: 5px;
          text-decoration: none;
          display: grid;
          position: absolute;
          right: 0px;
          top: 0px;
          height: 28px;
          width: 48px;
          justify-content: center;
          align-content: center;
          &::before {
            width: 10px;
            transition: transform 0.5s;
            transform: rotate(180deg);
            color: $text-inverse-main;
          }
        }
        display: none;
      }
      &.opened {
        a.button-expand {
          &::before {
            transform: rotate(0);
          }
        }
      }
      .submenu {
        transition: max-height 0.5s ease-in-out, margin 0.5s ease-in-out,
          padding 0.5s ease-in-out, opacity 0.5s ease-in-out;
        @media all and (max-width: $md) {
          opacity: 0;
          max-height: 0px;
          padding-bottom: 0;
          overflow: hidden;
        }
        &.opened {
          @media all and (max-width: $md) {
            opacity: 1;
            margin-top: 10px;
            padding-bottom: 5px;
            overflow: hidden;
          }
        }
      }
      .submenu-item {
        a {
          font-size: 15px;
          color: $text-inverse-main-half;
          &:hover {
            color: $text-inverse-main;
          }
        }
      }
    }
    .kontur {
      max-width: 60px;
      position: relative;
      top: 4px;
    }
  }
  .contacts {
    padding-right: 60px;
  }
  .copyright {
    @media all and (max-width: $md) {
      grid-gap: 0;
    }
    display: grid;
    grid-gap: 15px;

    .policy {
      color: $text-inverse-main-half;
      &:hover {
        color: $text-inverse-main;
      }
    }
  }
  .made-in {
    display: grid;
    .move-to-top {
      @media all and (max-width: $md) {
        grid-column: 2;
        align-self: flex-end;
        justify-self: flex-end;
      }
      border: 1px solid $text-inverse-main-half;
      display: grid;
      align-content: center;
      justify-content: center;
      align-self: flex-start;
      justify-self: flex-start;
      width: 28px;
      height: 28px;
      transition: all 0.5s;
      i {
        position: relative;
        font-size: 5px;
        left: -2px;
        top: 0px;
        &::before {
          transition: all 0.5s;
          color: $text-inverse-main;
        }
      }
      &:hover {
        border-color: $color-accent;
        i {
          &::before {
            color: $color-accent;
          }
        }
      }
    }
    .madein {
      @media all and (max-width: $md) {
        grid-column: 1;
        grid-row: 1;
      }
      display: grid;
      grid-template-columns: 35px minmax(0, 1fr);
      grid-gap: 10px;
      &::before {
        font-size: 30px;
        color: $text-inverse-main-half;
        margin: 0;
      }
      .base-label-text {
        @extend %text-small;
        color: $text-inverse-main-half;
        line-height: 1.38em;
      }
      a.inverse {
        color: $text-inverse-main-half;
      }
      align-self: flex-end;
    }
  }
}
</style>
