<template>
  <div class="gallery-component">
    <div class="gallery-container">
      <agile>
        <div class="slide" v-for="(l, i) in list" :key="i">
          <img :src="l.img.src" :alt="l.img.alt" />
        </div>
      </agile>
    </div>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  props: {
    theme: {
      default: "default"
    },
    list: Array
  },
  data() {
    return {};
  },
  components: {}
};
</script>

<style lang="scss">
.gallery-component {
  width: 100%;
  overflow: hidden;
  display: grid;
  .gallery-container {
    overflow: hidden;
  }
  img {
    max-width: 100%;
    width: 100%;
    max-height: 600px;
    object-fit: cover;
  }
  .agile {
    touch-action: manipulation;
    &__slide {
      display: grid;
      align-content: stretch;
    }
    ul.agile__dots {
      @media all and (max-width: 1088px) {
        bottom: 35px;
      }
      position: absolute;
      left: 50%;
      bottom: 45px;
      grid-gap: 3px;
      display: grid;
      grid-auto-columns: max-content;
      grid-auto-flow: column;
      transform: translateX(calc(-50% - 10px));
      .agile__dot {
        display: grid;
        grid-template-columns: 7px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        transition: border-color 0.5s, background-color 0.5s;
        box-sizing: content-box;
        border: 7px solid transparent;
        &.agile__dot--current {
          background: $color-accent;
          border: 7px solid $bg-grey;
          button {
            background: $color-accent;
          }
        }
        button {
          overflow: hidden;
          background: $bg-grey;
          box-sizing: border-box;
          width: 7px;
          height: 7px;
          overflow: hidden;
          display: block;
          max-width: 7px;
          padding: 0;
          border-radius: 50%;
        }
      }
    }
    &__arrow {
      @extend %action-shadow;
      width: 28px;
      height: 28px;
      padding: 0;
      background: $bg-main;
      position: absolute;
      left: 50%;
      top: 50%;
      cursor: pointer;
      &::before {
        font-family: fontello;
      }
      &--prev {
        @media all and (max-width: 1088px) {
          left: 0;
          transform: translateY(calc(-50% - 6px)) translateX(14px);
        }
        transform: translateY(calc(-50% - 6px)) translateX(-544px);
        &::before {
          content: "\e814";
          color: $color-accent;
        }
      }
      &--next {
        @media all and (max-width: 1088px) {
          left: unset;
          right: 0;
          transform: translateY(calc(-50% - 6px)) translateX(-14px);
        }
        transform: translateY(calc(-50% - 6px)) translateX(516px);
        &::before {
          content: "\e813";
          color: $color-accent;
        }
      }
      &:hover {
        transition: background-color 0.5s;
        background: $color-accent;
        &::before {
          transition: color 0.5s;
          color: $text-inverse-main;
        }
      }
      &[disabled] {
        display: none;
      }
      svg {
        display: none;
      }
    }
    button {
      border: none;
    }
  }
}
</style>
