<template>
  <div class="events-component">
    <div class="area">
      <div class="row">
        <div
          class="events-container col-10"
          v-animated-show="utils.handleScroll"
        >
          <h2 v-if="title" v-html="title"></h2>
          <div class="row" v-if="list">
            <section
              class="list-section col-4 col-md-12"
              v-for="(l, i) in list"
              :key="i"
            >
              <div class="content" v-if="l.title">
                <strong
                  ><span class="date" v-if="l.date" v-html="l.date"></span
                ></strong>
                <h3 v-if="l.title" v-html="l.title"></h3>
                <router-link tag="a" :to="'/' + l.href" :href="'/' + l.href">
                  <BaseLabel
                    icon-class="icon-arrow-right"
                    icon-top-offset="-2px"
                    label-margin="2px"
                    is-icon-after
                    >Подробнее</BaseLabel
                  >
                </router-link>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../utils";
import AppData from "../AppData";
export default {
  name: "Events",
  props: {
    theme: {
      default: "default"
    },
    list: Array,
    title: String
  },
  data() {
    return {
      utils: utils
    };
  },
  created() {
    // AppData.fetchData("events", 3)
    //   .then(result => {
    //     for (var key in result) {
    //       result[key].href = "/" + key;
    //     }
    //     this.data = result;
    //   })
    //   .catch(() => {
    //   });
  }
};
</script>

<style lang="scss">
.events-component {
  h2 {
    margin: 0;
  }
  .area {
    @media all and (max-width: $md) {
      background: $bg-green;
    }
    & > .row {
      @extend %page-block;
      grid-row-gap: 28px;
      background: $bg-green;
      .list-section {
        @media all and (max-width: $md) {
          margin-top: 25px;
        }
        margin-top: 33px;
      }
      .events-container {
        @media all and (max-width: $md) {
          margin-left: 0px;
          grid-column: span 12;
        }
        margin-left: -28px;
        grid-column: 2 / span 10;
        .base-label {
          @extend %text-default;
          font-weight: bold;
          color: $color-accent;
          &::before {
            font-size: 7px;
            font-weight: bold;
          }
          .base-label-text {
            @extend %link;
            font-weight: bold;
            border-bottom-color: transparent;
            color: $color-accent;
          }
        }
        a {
          color: $color-accent;
        }
        .date {
          @extend %text-default;
          color: $text-inactive;
        }
      }
    }
  }
  @media all and (max-width: $md) {
    padding-bottom: 27px;
  }
  padding-bottom: 66px;
}
</style>
