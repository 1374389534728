var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{class:[
    'base-label',
    _vm.iconClass,
    {
      'base-label-icon': _vm.iconSrc,
      'base-label-inverted': _vm.isInverted,
      'icon-after': _vm.isIconAfter
    }
  ],style:({
    '--base-icon-color': _vm.iconColor,
    '--base-icon-offset': _vm.iconTopOffset,
    '--base-font-size': _vm.fontSize,
    '--base-icon-size': _vm.iconSize === 'inherit' ? _vm.fontSize : _vm.iconSize,
    '--base-icon-src': 'url(' + _vm.iconSrc + ')'
  })},[_c('span',{class:[
      'base-label-text',
      {
        'base-label-text-underlined': _vm.isUnderlined
      }
    ],style:({
      '--base-label-margin': _vm.labelMargin
    })},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }