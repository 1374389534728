var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map-info"},[_c('div',{staticClass:"info"},[_c('h2',{domProps:{"innerHTML":_vm._s(
        (_vm.selectedAddress !== -1 && this.isCatalog) || !this.isCatalog
          ? _vm.data.title
          : _vm.title
      )}}),(_vm.selectedAddress === -1 && this.isCatalog)?_c('ul',{staticClass:"catalog"},_vm._l((_vm.data.list),function(l,i){return _c('li',{key:i,staticClass:"catalog__item"},[_c('a',{attrs:{"href":"/"},domProps:{"innerHTML":_vm._s(l.title)},on:{"click":function($event){$event.preventDefault();return (function () {
              _vm.openAddress(l, i);
            })($event)}}})])}),0):_vm._e(),((_vm.selectedAddress !== -1 && this.isCatalog) || !this.isCatalog)?_c('div',[(_vm.data.list.length > 1)?_c('a',{staticClass:"nav-arrow nav-arrow--close",attrs:{"href":"/"},on:{"click":function($event){$event.preventDefault();return (function () {
            _vm.selectedAddress = -1;
          })($event)}}}):_vm._e(),_c('p',[_c('BaseLabel',{attrs:{"icon-class":"icon-location_1","icon-top-offset":"-1px","label-margin":"8px"}},[_vm._v(_vm._s(_vm.current.place))])],1),_c('p',[_c('BaseLabel',{attrs:{"icon-class":"icon-metro","icon-top-offset":"-1px","label-margin":"8px"}},[_vm._v(_vm._s(_vm.current.metro))])],1),_c('p',[_c('span',{staticClass:"schedule",domProps:{"innerHTML":_vm._s(_vm.current.schedule.label)}}),_c('br'),_c('span',{staticClass:"schedule",domProps:{"innerHTML":_vm._s(_vm.current.schedule.desc)}})]),_c('p',_vm._l((_vm.current.phone),function(l,i){return _c('strong',{key:i,staticClass:"phone"},[_c('a',{staticClass:"phone-link",attrs:{"href":_vm.utils.getPhoneLink(l)}},[_vm._v(_vm._s(l))]),_c('br')])}),0),_c('p',[_c('span',{staticClass:"email light",domProps:{"innerHTML":_vm._s(_vm.current.email)}})])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }