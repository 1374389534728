<template>
  <div class="map-info">
    <div class="info">
      <h2
        v-html="
          (selectedAddress !== -1 && this.isCatalog) || !this.isCatalog
            ? data.title
            : title
        "
      ></h2>
      <ul class="catalog" v-if="selectedAddress === -1 && this.isCatalog">
        <li class="catalog__item" v-for="(l, i) in data.list" :key="i">
          <a
            href="/"
            @click.prevent="
              () => {
                openAddress(l, i);
              }
            "
            v-html="l.title"
          ></a>
        </li>
      </ul>
      <div v-if="(selectedAddress !== -1 && this.isCatalog) || !this.isCatalog">
        <a
          v-if="data.list.length > 1"
          class="nav-arrow nav-arrow--close"
          href="/"
          @click.prevent="
            () => {
              selectedAddress = -1;
            }
          "
        ></a>
        <p>
          <BaseLabel
            icon-class="icon-location_1"
            icon-top-offset="-1px"
            label-margin="8px"
            >{{ current.place }}</BaseLabel
          >
        </p>
        <p>
          <BaseLabel
            icon-class="icon-metro"
            icon-top-offset="-1px"
            label-margin="8px"
            >{{ current.metro }}</BaseLabel
          >
        </p>
        <p>
          <span class="schedule" v-html="current.schedule.label"></span>
          <br />
          <span class="schedule" v-html="current.schedule.desc"></span>
        </p>
        <p>
          <strong class="phone" v-for="(l, i) in current.phone" :key="i">
            <a class="phone-link" :href="utils.getPhoneLink(l)">{{ l }}</a>
            <br />
          </strong>
        </p>
        <p>
          <span class="email light" v-html="current.email"></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AppData from "../AppData";
import utils from "../utils";
export default {
  name: "MapInfo",
  props: {
    data: Object,
    title: String,
    isCatalog: Boolean
  },
  computed: {
    current() {
      return this.data.list[this.selectedAddress];
    }
  },
  data() {
    return {
      utils: utils,
      selectedAddress: -1,
      description: AppData.callback.description
    };
  },
  components: {},
  methods: {
    openAddress(item, index) {
      this.selectedAddress = index;
    },
    onClickButton() {
      const result = this.phone.indexOf("_") !== -1 || this.phone.length === 0;
      this.errorField = result;
      if (!this.errorField) {
        this.formSent = true;
      }
    },
    onChangeCheckbox(e) {
      this.buttonDisabled = !e.target.checked;
    },
    onInputName(e) {
      this.errorField = false;
      this.name = e.target.value;
    },
    onInput(e) {
      this.errorField = false;
      this.phone = e.target.value;
    }
  },
  created() {
    if (!this.isCatalog || this.data.list.length === 1) {
      this.selectedAddress = 0;
    }
  }
};
</script>

<style lang="scss">
@keyframes showUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
}
.map-info {
  @extend %float-shadow;
  align-self: flex-start;
  background: $bg-main;
  padding: 38px 20px 38px 20px;
  min-height: 395px;
  box-sizing: border-box;
  h2 {
    margin-top: 00px;
    margin-bottom: 30px;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .icon-location_1 {
    .base-label-text {
      @extend %text-big;
    }
    &::before {
      font-size: 14px;
    }
  }
  .icon-metro {
    .base-label-text {
      @extend %text-default;
      color: #f54d40;
      font-weight: bold;
    }

    &::before {
      width: 14px;
      height: 14px;
      background: #f54d40;
      display: inline-block;
      color: $bg-main;
      border-radius: 50%;
      font-size: 12px;
      padding-left: 0px;
      padding-top: 1px;
      box-sizing: border-box;
    }
  }
  .phone {
    @extend %text-big;
    .phone-link {
      color: $text-main;
      font-weight: bold;
    }
  }
  .catalog {
    &__item {
      a {
        @extend %link;
        border-bottom-style: dotted;
        border-bottom-color: #57575680;
        color: $text-main;
        &:hover {
          border-bottom-color: #575756;
        }
      }
    }
  }
  .nav-arrow {
    @media all and (max-width: $lg - 1) {
      right: 28px;
    }
    @media all and (max-width: $md) {
      right: 14px;
    }
    @extend %action-shadow;
    position: absolute;
    right: 28px;
    top: 38px;
    width: 28px;
    height: 28px;
    padding: 0;
    background: $bg-main;
    cursor: pointer;
    display: grid;
    text-decoration: none;
    justify-content: center;
    align-content: center;
    &::before {
      font-family: fontello;
      font-weight: 300;
    }
    &.nav-arrow--close {
      &::before {
        content: "\e800";
        color: $color-accent;
        transition: transform 0.5s;
        transform: rotate(0deg);
      }
      &:hover {
        &::before {
          transition: transform 0.5s;
          transform: rotate(360deg);
        }
      }
    }
    &:hover {
      transition: background-color 0.5s transform 0.5s;
      background: $color-accent;
      &::before {
        transition: color 0.5s;
        color: $text-inverse-main;
      }
    }
  }
}
</style>
