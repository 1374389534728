<template>
  <div
    :style="{
      '--base-height': inputHeight,
      '--base-margin': topMargin,
      '--base-bg-color': bgColor
    }"
    :class="['base-input-container', { error: errorLabel }]"
    @click="onInputClick"
  >
    <h3 v-if="inputLabel && inputType !== 'checkbox'" class="input-label">
      {{ inputLabel }}
    </h3>
    <div :class="['flex-wrap', { 'base-checkbox': inputType === 'checkbox' }]">
      <div :class="['from-container']">
        <!-- не использую v-model потому что в этом случае
        изменение данных отображаеться с небольшой задержкой -->
        <span v-if="inputPrefix" class="input-prefix">
          {{ inputPrefix }}
        </span>
        <input
          @focus="OnInputFocus"
          @blur="OnInputhBlue"
          :disabled="disabled"
          v-if="!isArea"
          :id="inputId"
          :placeholder="inputPlaceholder"
          :class="[
            'base-input',
            {
              'checkbox-label': type === 'checkbox',
              'input-with-prefix': inputPrefix,
              'input-with-clear': isClearVisible && isNeedShowClear
            }
          ]"
          ref="inputField"
          :type="showPassword ? 'text' : inputType"
          :value="
            inputType == 'phone'
              ? inputValue
                ? inputValue
                : phonePattern
              : inputValue
          "
          :min="min"
          :max="max"
          :autocomplete="autocomplete"
          :checked="isChecked"
          :name="inputName"
          @input="onInput"
          @change="onChange"
        />
        <label
          v-if="inputType === 'checkbox'"
          :class="['checkbox-label', 'base-input-label']"
          :for="inputId"
          >{{ inputLabel }}
        </label>
        <span
          v-show="isClearVisible && isNeedShowClear"
          class="input-reset icon-cancel"
          @click="clearInputField"
        ></span>
        <span
          v-show="inputType === 'password'"
          class="input-reset icon-password-show"
          @click="changeType"
        ></span>
        <span v-if="inputIcon" :class="['input-right-icon', inputIcon]"></span>
        <span v-if="required" class="required">*</span>
        <textarea
          v-model="inputValue"
          v-if="isArea"
          class="base-input-area"
          :placeholder="inputPlaceholder"
          @change="onChange"
          @input="onTextareaInput"
        ></textarea>
      </div>

      <ButtonComponent v-if="buttonTitle" :on-click="onInputButtonClick">{{
        buttonTitle
      }}</ButtonComponent>
    </div>
    <h4 v-show="errorLabel" class="error-label">{{ errorLabel }}</h4>
  </div>
</template>

<script>
import ButtonComponent from "./ButtonComponent.vue";

// eslint-disable-next-line prefer-const
let uid = 0;

export default {
  name: "BaseInput",
  props: {
    required: Boolean,
    disabled: {
      default: undefined,
      type: String
    },
    value: {
      default: undefined
    },
    inputName: String,
    autocomplete: String,
    min: Number,
    max: Number,
    onClick: Function,
    inputType: {
      default: "text",
      type: String
    },
    isArea: Boolean,
    topMargin: {
      type: String,
      default: "0"
    },
    inputHeight: {
      type: String,
      default: "42px"
    },
    inputLabel: String,
    inputPrefix: String,
    inputBG: String,
    inputPlaceholder: {
      default: "",
      type: String
    },
    isClearVisible: Boolean,
    buttonTitle: String,
    buttonOnClick: Function,
    inputIcon: String,
    isSelected: Boolean,
    bgColor: {
      default: "#fff",
      type: String
    },
    fullClear: Boolean
  },
  data() {
    return {
      errorLabel: undefined,
      phonePattern: "(___) ___-__-__",
      dialCode: "+7",
      inputValue: "",
      inputId: null,
      isChecked: false,
      isNeedShowClear: false,
      showPassword: false
    };
  },
  methods: {
    changeType() {
      this.showPassword = !this.showPassword;
    },
    setFocus() {
      this.$refs.inputField.focus();
    },
    OnInputFocus(e) {
      this.$emit("focus", e);
    },
    OnInputhBlue(e) {
      this.$emit("blur", e);
    },
    onInputClick() {
      if (this.onClick !== undefined) {
        this.onClick();
      }
    },
    onInputButtonClick() {
      const val = this.$refs.inputField.value;
      this.$emit("click", val);
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    clearInputField() {
      if (this.fullClear) {
        this.inputValue = "";
      } else {
        this.inputValue = this.value;
      }
      this.$emit("input", this.inputValue);
      this.$emit("change", this.inputValue);
    },
    onTextareaInput(e) {
      this.inputValue = e.target.value;
      this.$emit("input", this.inputValue);
    },
    onInput(e) {
      this.inputValue = e.target.value;
      if (this.inputType === "phone") {
        this.updateInputPhone();

        const underscoreIndex = this.inputValue.indexOf("_");
        const selection = this.$refs.inputField.selectionStart;

        e.target.value = this.inputValue;

        // this.$nextTick(() => {
        if (e.inputType === "deleteContentBackward") {
          const re = /[\d]/g;
          const stringNumber = this.inputValue.substring(0, selection);
          let myArray;
          let lastIndex = 0;
          do {
            myArray = re.exec(stringNumber);
            if (myArray !== null) {
              lastIndex = myArray.index;
            }
          } while (myArray !== null);
          this.setCaretPosition(this.$refs.inputField, lastIndex + 1);
        } else if (selection < underscoreIndex || underscoreIndex === -1) {
          const index =
            this.inputValue.substring(selection - 1).search(/([0-9_])/) +
            selection;
          if (index === selection) {
            this.setCaretPosition(this.$refs.inputField, index);
          } else {
            this.setCaretPosition(this.$refs.inputField, index);
          }
        } else if (underscoreIndex !== -1) {
          this.setCaretPosition(this.$refs.inputField, underscoreIndex);
        }
        // });
      } else if (this.inputType === "number") {
        const val = Number.parseInt(this.inputValue, 0);
        if (!Number.isNaN(val)) {
          if (this.min !== undefined) {
            this.inputValue = Math.max(val, this.min);
          }
          if (this.max !== undefined) {
            this.inputValue = Math.min(val, this.max);
          }
        }
      }

      if (e.target.type === "checkbox") {
        this.$emit("input", e.target.checked);
      } else {
        this.$emit("input", this.inputValue);
      }
    },
    onChange(e) {
      if (e !== undefined && e.target.type === "checkbox") {
        this.isChecked = e.target.checked;
        this.$emit("change", e.target.checked);
      } else {
        const val = e.target.value;

        if (this.inputType === "email") {
          const result = this.validateEmail(val);
          this.$emit("validation", result);
        } else if (this.inputType === "phone") {
          const result = val !== undefined && val.indexOf("_") === -1;
          this.$emit("validation", result);
        }
        if (this.inputType === "phone") {
          this.$emit("change", this.dialCode + val);
        } else {
          this.$emit("change", val);
        }
      }
    },
    updateInputPhone() {
      let tmp = this.inputValue.replace(/[^0-9]/g, "");
      while (tmp.length < 10) {
        tmp += "_";
      }
      tmp = tmp.replace(
        /([0-9_]{3})([0-9_]{3})([0-9_]{2})([0-9_]{2})/,
        "($1) $2-$3-$4"
      );
      this.inputValue = tmp.substr(0, 15);
    },

    setCaretPosition(elem, caretPos) {
      if (elem != null) {
        if (elem.createTextRange) {
          const range = elem.createTextRange();
          range.move("character", caretPos);
          range.select();
        } else if (elem.selectionStart) {
          elem.focus();
          elem.setSelectionRange(caretPos, caretPos);
        } else {
          elem.focus();
        }
      }
    },
    onDialCodeChanged(code) {
      this.dialCode = code;
      this.$emit("change", this.dialCode + this.inputValue);
    },
    update(val) {
      this.inputValue = val;
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.inputValue = val;
        if (this.inputType === "email") {
          const result = this.validateEmail(val);
          this.$emit("validation", result);
        } else if (this.inputType === "phone") {
          const result = val !== undefined && val.indexOf("_") === -1;
          this.$emit("validation", result);
        }
      }
    },
    inputValue: {
      immediate: true,
      handler(val) {
        if (this.isClearVisible) {
          const value = Number.parseInt(this.value, 0);
          this.isNeedShowClear = val !== value;
        }
        if (this.inputType === "phone") {
          if (val !== undefined && val.indexOf && val.indexOf("+") !== -1) {
            let tmp = val.replace(/[^0-9]/g, "");
            tmp = tmp.substr(Math.max(0, tmp.length - 10), 10);
            this.inputValue = tmp;
            this.dialCode =
              "+" + val.replace(/[^0-9]/g, "").substr(0, tmp.length - 10);
            this.updateInputPhone();
          }
        }
      }
    }
  },
  created() {
    this.inputId = `input-${uid}`;
    uid += 1;
    this.isNeedShowClear = false;
    if (this.inputType === "checkbox") {
      this.isChecked = this.isSelected;
    }
  },
  mounted() {
    if (this.inputType === "email") {
      const result = this.validateEmail(this.inputValue);
      this.$emit("validation", result);
    } else if (this.inputType === "phone") {
      const result =
        this.inputValue !== undefined && this.inputValue.indexOf("_") === -1;
      this.$emit("validation", result);
    }
  },
  components: {
    ButtonComponent
  }
};
</script>

<style lang="scss">
$base-font-family: Roboto, sans-serif;
$base-font-size: 15px;
$base-label-font-size: 12px;
$base-height: var(--base-height);
$base-margin: var(--base-margin);
$base-bg-color: var(--base-bg-color);
$base-padding: 10px;
$base-right-icon-color: #afafaf;

.input-label {
  padding-top: $base-padding;
  font-family: $base-font-family;
  text-align: left;
  font-size: $base-label-font-size;
  font-weight: normal;
  line-height: 1.5em;
  margin-bottom: 7px;
  height: 1.5em;
}
.base-input-container {
  margin-top: $base-margin;
  .flex-wrap {
    @media all and (max-width: $md) {
      position: relative;
    }
    display: flex;
    width: 100%;
    height: $base-height;
  }

  .from-container {
    @media all and (max-width: $md) {
      position: unset;
    }
    position: relative;
    border: 1px solid #ededed;
    flex-grow: 1;
    display: flex;
    background: $base-bg-color;

    &.country-select {
      width: 100px;
      flex-grow: inherit;
    }
    .input-reset {
      cursor: pointer;
      display: block;
      position: absolute;
      right: 5px;
      padding-top: 2px;

      height: 20px;
      width: 20px;
      line-height: 20px;
      text-align: center;
      font-size: 8px;
      top: calc(50% - 13px);
    }
    .input-reset.icon-password-show {
      cursor: pointer;
      display: block;
      position: absolute;
      right: 15px;
      padding-top: 2px;

      height: 20px;
      width: 30px;
      line-height: 20px;
      text-align: center;
      font-size: 8px;
      top: calc(50% - 13px);
    }
  }

  //height: $base-height;
  .base-input[type="number"]::-webkit-inner-spin-button,
  .base-input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .base-input {
    outline: none;
    background: transparent;
    font-family: $base-font-family;
    font-size: $base-font-size;
    padding: 0px 14px;
    flex-grow: 1;
    border: none;
    max-width: 100%;
    width: 100%;
    &::placeholder {
      color: #000000;
      opacity: 0.35;
    }
    &.input-with-prefix {
      outline: none;
      padding-left: 5px;
    }
    &.input-with-clear {
      outline: none;
      padding-right: 25px;
    }
  }
  .input-prefix {
    padding-left: 10px;
    line-height: $base-height;
    font-size: 14px;
  }
  .input-right-icon {
    line-height: $base-height;
    padding-right: 5px;
    font-size: 18px;
    color: $base-right-icon-color;
  }
  .base-input-area {
    font-family: $base-font-family;
    font-size: 15px;
    flex-grow: 1;
    border: none;
    resize: none;
    padding: 10px 15px;
  }

  .error-label {
    display: block;
    color: #e30613;
    font-size: 13px;
    font-weight: 300;
    margin: 5px 0;
  }
  .required {
    position: absolute;
    right: -13px;
    top: 5px;
    color: #e30613;
    font-size: 15px;
    font-weight: 300;
  }
  &.error {
    .from-container {
      border: 1px solid #e30613;
    }
  }
}
</style>
