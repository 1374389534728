<template>
  <span
    :class="[
      'base-label',
      iconClass,
      {
        'base-label-icon': iconSrc,
        'base-label-inverted': isInverted,
        'icon-after': isIconAfter
      }
    ]"
    :style="{
      '--base-icon-color': iconColor,
      '--base-icon-offset': iconTopOffset,
      '--base-font-size': fontSize,
      '--base-icon-size': iconSize === 'inherit' ? fontSize : iconSize,
      '--base-icon-src': 'url(' + iconSrc + ')'
    }"
  >
    <span
      :class="[
        'base-label-text',
        {
          'base-label-text-underlined': isUnderlined
        }
      ]"
      :style="{
        '--base-label-margin': labelMargin
      }"
    >
      <slot></slot>
    </span>
  </span>
</template>

<script>
export default {
  name: "BaseLabel",
  props: {
    isIconAfter: Boolean,
    isInverted: Boolean,
    isUnderlined: Boolean,
    iconSrc: {
      type: String,
      required: false,
      default: undefined
    },
    fontSize: {
      type: String,
      required: false,
      default: "inherit"
    },
    iconSize: {
      required: false,
      default: "inherit",
      type: String
    },
    iconColor: {
      default: "#1D1D1B",
      type: String
    },
    iconClass: String,
    labelMargin: {
      default: "8px"
    },
    iconTopOffset: {
      default: "0px"
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
$base-padding: 0 0px;
$base-icon-offset: var(--base-icon-offset);
$base-color: #1d1d1b;
$base-underline: 0.5px solid rgba(29, 29, 27, 0.25);
$base-icon-color: var(--base-icon-color);
$base-label-margin: var(--base-label-margin);

$base-bg-image: var(--base-icon-src);

$light-color: white;
$light-underline: 0.5px solid rgba(255, 255, 255, 0.25);

.base-label {
  @extend %text-default;
  display: inline-block;
  padding: $base-padding;

  &::before {
    color: $base-icon-color;
    position: relative;
    top: $base-icon-offset;
  }

  &.base-label-icon {
    &::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: $base-icon-offset;
      background: $base-bg-image no-repeat;
      background-size: contain;
    }
  }

  .base-label-text {
    margin-left: $base-label-margin;
    color: $base-color;

    &.base-label-text-underlined {
      border-bottom: $base-underline;
      padding-bottom: 2px;
    }
  }

  /*светлая тема, текст белый на темном фоне*/
  &.base-label-inverted {
    &::before {
      color: $light-color;
    }
    .base-label-text {
      color: $light-color;
      &.base-label-text-underlined {
        border-bottom: $light-underline;
      }
    }
  }

  /*если нужно чтобы иконка была справа*/
  &.icon-after {
    direction: rtl;
    .base-label-text {
      direction: ltr;
      display: inline-block;
      margin-left: 0;
      margin-right: $base-label-margin;
    }
  }
  .underlined {
    border-bottom: $base-underline;
    text-decoration: none;
    padding-bottom: 2px;
  }
  a {
    color: #1d1d1b;
    text-decoration: none;
  }
}
</style>
