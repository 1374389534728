<template>
  <div class="menu-component" ref="menuContainer">
    <div class="menu-content">
      <ul class="menu">
        <li v-for="(v, i) in list" :key="i" ref="menuItems">
          <a
            :class="[{ current: current === i }]"
            href="/"
            v-html="v"
            @click.prevent="
              () => {
                onClick(i);
              }
            "
          >
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import utils from "../utils";
export default {
  props: {
    list: Array
  },
  data() {
    return {
      current: 0
    };
  },
  methods: {
    onClick(index) {
      this.current = index;
      const el = this.$refs.menuItems[this.current];
      this.$refs.menuContainer.scrollTo(
        el.getBoundingClientRect().x -
          this.$refs.menuContainer.clientWidth / 2 +
          el.clientWidth / 2,
        0
      );
      this.$emit("changed", index);
      // this.animating = true;
      // let start = this.$refs.menuContainer.scrollLeft;
      // let end =
      //   el.getBoundingClientRect().x -
      //   this.$refs.menuContainer.clientWidth / 2 +
      //   el.clientWidth / 2;
      // let currentFrame = 0;
      // let smoothscroll = () => {
      //   if (currentFrame < 360) {
      //     window.requestAnimationFrame(smoothscroll);
      //     currentFrame++;
      //     var t = currentFrame / 360;
      //     this.$refs.menuContainer.scrollTo(
      //       start + utils.easeInOut(t) * (end - start),
      //       0
      //     );
      //   } else {
      //     this.animating = false;
      //   }
      // };
      // smoothscroll();
    }
  }
};
</script>

<style lang="scss">
.menu-component {
  @media all and(max-width: $md) {
    width: 100vw;
    margin: 0 -14px;
    overflow: scroll;
  }
  display: grid;
  grid-template-columns: 1fr;
  $scrollBarHeight: 1px;
  &::-webkit-scrollbar {
    width: $scrollBarHeight;
    height: $scrollBarHeight;
  }

  &::-webkit-scrollbar-button {
    width: $scrollBarHeight;
    height: $scrollBarHeight;
  }
  .menu-content {
    @media all and(max-width: $md) {
      padding: 0 14px;
    }
    ul.menu {
      @media all and(max-width: $lg) {
        grid-gap: 20px;
      }
      @media all and(max-width: $md) {
        grid-gap: 34px;
      }
      border-bottom: 1px solid $bg-grey-dark;
      justify-self: center;
      justify-items: center;
      justify-content: center;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      margin-bottom: 0;
      grid-gap: 84px;

      li {
        @extend %text-small;
        display: grid;
        justify-content: flex-start;
        height: 53px;
        align-items: flex-end;
        a {
          @extend %link;
          align-self: flex-end;
          color: $text-inactive;
          border-bottom-width: 2px;
          border-bottom-color: transparent;
          &:hover,
          &.current {
            color: $text-main;
            border-bottom-color: $color-accent;
          }
          padding-bottom: 16px;
        }
      }
    }
    ul.submenu {
      @media all and(max-width: $md) {
        grid-gap: 24px;
      }
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      margin-bottom: 0;
      grid-gap: 24px;
      li {
        @extend %text-small;
        display: grid;
        height: 55px;
        line-height: 55px;
        align-items: flex-end;
        a {
          @extend %link;
          justify-self: flex-start;
          align-self: flex-end;
          color: $text-main;
          border-bottom: 0;
          &:hover {
            color: $color-accent;
          }
        }
      }
    }
  }
}
</style>
