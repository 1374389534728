<template>
  <div id="fin_support-nav">
    <div class="features-business-component" id="legal_support-nav">
      <div class="area">
        <div class="row" v-animated-show="utils.handleScroll">
          <div class="content-container col-12 col-md-12">
            <h2 class="tabs" v-if="tabs">
              <a
                href="/"
                class="tab link"
                :class="[{ current: current === i }]"
                v-for="(l, i) in tabs"
                :key="i"
                @click.prevent="
                  () => {
                    onTabSelect(i);
                  }
                "
                ><span v-html="l"></span
              ></a>
            </h2>
            <div
              class="features-list"
              v-for="(item, index) in list"
              :key="index"
              :class="[{ current: current === index }]"
            >
              <section
                class="list-section-baner"
                v-for="(l, i) in item.list"
                :key="i"
              >
                <div class="content" v-if="l.title || l.desc">
                  <Description
                    class="small icon-left"
                    ref="bannerDescription"
                    :title="l.title"
                    :desc="l.desc"
                    :list="l.list"
                    :icon="l.icon"
                    href="#callback"
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../utils";
export default {
  name: "FeaturesBusiness",
  props: {
    theme: {
      default: "default"
    },
    tabs: Array,
    list: Array,
    noCallback: Boolean
  },
  data() {
    return {
      current: 0,
      utils: utils
    };
  },
  mounted() {
    if (this.$route.hash === "#fin_support") {
      this.current = 1;
    }
  },
  methods: {
    onTabSelect(index) {
      this.current = index;
    }
  },
  components: {}
};
</script>

<style lang="scss">
.features-business-component {
  background: $bg-main;
  h2 {
    margin-top: 0px;
  }
  @extend %page-block;
  .row {
    grid-row-gap: 28px;
  }
  .content-container {
    display: grid;
    align-items: flex-start;
  }
  .features-list {
    @extend %switch-anim;
    @media all and (max-width: $md) {
      grid-template-columns: minmax(0, 1fr);
      grid-gap: 18px;
    }
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column: 1;
    grid-row: 2;
    margin-top: 40px;
    display: grid;
    grid-gap: 28px;
  }
  .description.small {
    grid-row-gap: 15px;
  }
  .tabs {
    margin-left: -10px;
    .tab {
      color: $text-main;
      // margin: 5px 10px;
      border: 1px dotted transparent;
      display: inline-block;
      padding: 5px 10px;
      span {
        transition: border 0.5s;
        border-bottom: 1px solid $color-accent-half;
      }
      &:hover {
        span {
          border-bottom: 1px solid $color-accent;
        }
        &.current {
          border: 1px dotted $color-accent;
        }
      }
      &.current {
        @media all and (max-width: $md) {
          border: 1px dotted $text-main;
        }
        span {
          border-bottom: 1px solid transparent;
        }
        margin: 0;
        border: 1px dotted $color-accent;
        border-radius: 10px;
        // padding: 5px 10px;
      }
    }
  }
  .callback {
    @media all and (max-width: $md) {
      transform: unset;
      grid-column: 1 / span 12;
    }
    transform: translate(-28px);
    grid-column: 9 / span 4;
  }
}
</style>
