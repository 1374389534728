<template>
  <div class="faq-container">
    <div class="area">
      <div class="row">
        <section
          class="recall col-4 col-md-12"
          v-animated-show="utils.handleScroll"
        >
          <div
            class="content"
            v-if="data.description && data.description.title"
          >
            <Description
              class="default icon-top"
              ref="bannerDescription"
              :title="data.description.title"
              :desc="data.description.desc"
            />
            <p class="more" v-html="data.more"></p>
            <ButtonComponent
              v-if="data.button"
              class="button-description"
              url="#callback"
              >{{ data.button }}</ButtonComponent
            >
          </div>
        </section>
        <ExpandableList
          :list="data.list"
          class="col-8 col-md-12"
          v-animated-show="utils.handleScroll"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ExpandableList from "../components/ExpandableList.vue";
import utils from "../utils";
export default {
  name: "Faq",
  props: {
    theme: {
      default: "default"
    },
    data: Object
  },
  data() {
    return { utils: utils };
  },
  components: {
    ExpandableList
  }
};
</script>

<style lang="scss">
.faq-container {
  @extend %page-block;
  .row {
    grid-row-gap: 28px;
    .button-description {
      width: 214px;
      margin-top: 28px;
    }
  }
  p.more {
    @media all and (max-width: $md) {
      line-height: 1.58em;
    }
    @extend %text-default;
    line-height: 1.6em;
  }
  .phone-link {
    color: $text-main;
    font-weight: bold;
  }
  .description-content {
    @media all and (max-width: $md) {
      grid-gap: 15px;
    }
    grid-gap: 35px;
  }
}
</style>
