<template>
  <div :class="['join-component', `theme-${theme}`]">
    <div class="area">
      <section
        class="list-section-baner row "
        v-animated-show="utils.handleScroll"
        :style="{
          transform: translate
        }"
      >
        <img
          class="img-banner"
          v-if="data.img"
          :alt="data.img"
          :src="data.img"
          :style="styleImg"
        />
        <div class="content col-8 col-md-12" v-if="data.title">
          <Description
            class="default"
            ref="bannerDescription"
            :typeEffect="isMain"
            id="description-header"
            :title="data.title"
            :desc="data.desc"
            :list="data.list"
            :isUnderline="isUnderline"
            :is-list-horizontal="true"
          />
          <span v-if="data.more" class="more" v-html="data.more"></span>
          <ButtonComponent
            v-if="data.button"
            class="button-description"
            url="#callback"
            >{{ data.button }}</ButtonComponent
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import utils from "../utils";
export default {
  name: "Join",
  props: {
    isMain: Boolean,
    theme: {
      default: "default"
    },
    data: Object,
    isUnderline: {
      default: true
    },
    styleImg: Object
  },
  data() {
    return {
      currentBanner: 0,
      utils: utils
    };
  },
  computed: {
    translate() {
      return `translateX(-${this.currentBanner * 100}%)`;
    }
  },
  methods: {},
  components: {}
};
</script>

<style lang="scss">
.join-component {
  position: relative;
  @extend %page-block;
  @media all and (max-width: $md) {
    min-height: 223px;
  }
  padding-top: 0;
  overflow: hidden;
  background: $bg-green;
  .list-section-baner {
    position: relative;
    @media all and (max-width: $md) {
      min-height: 223px;
      padding-bottom: 120px;
    }
    grid-column: span 12;
    width: 100%;
    align-content: center;
    min-height: 322px;
    .img-banner {
      @media all and (max-width: $md) {
        grid-column: span 12;
        grid-row: 2;
        position: absolute;
        max-height: 180px;
        height: 180px;
        transform: translateX(0);
        left: unset;
        right: -40px;
        object-fit: contain;
        bottom: -40px;
        min-width: 400px;
        object-position: right bottom;
      }
      transform: translateX(-40%);
      max-height: 350px;
      position: absolute;
      left: -20px;
      bottom: -66px;
      object-fit: contain;
    }
    .button-description {
      @media all and (max-width: $md) {
        margin-top: 28px;
      }
      width: 186px;
      margin-top: 20px;
    }
    .content {
      @media all and (max-width: $md) {
        grid-gap: 0px;
        grid-column: span 10;
      }
      position: relative;
      z-index: 1;
      grid-gap: 10px;
      display: grid;
      justify-content: flex-start;
      justify-items: flex-start;
      grid-column: 5 / span 8;
    }
    .more {
      @extend %text-default;
      color: $text-main-half;
    }
  }
  min-height: 322px;
  overflow: hidden;
  &.bank {
    .list-section-baner {
      @media all and (max-width: $md) {
        padding-bottom: 140px;
      }
    }
    .content {
      @media all and (max-width: $md) {
        grid-column: span 12;
      }
      grid-column: 5 / span 8;
      grid-template-columns: repeat(8, minmax(0, 1fr));
      .description {
        @media all and (max-width: $md) {
          grid-column: span 8;
          grid-gap: 10px;
        }
        ul {
          margin-bottom: 10px;
        }
        grid-column: 1 / span 5;
      }
      .more {
        @media all and (max-width: $md) {
          grid-column: span 8;
        }
        grid-column: 1 / span 5;
        grid-row: 2;
      }
      .button-description {
        @media all and (max-width: $md) {
          grid-column: span 8;
          margin-top: 25px;
          grid-row: 3;
          justify-self: flex-start;
          width: 120px;
        }
        grid-column: 6 / span 3;
        grid-row: 1 / span 2;
        align-self: center;
        justify-self: center;
      }
    }
    .img-banner {
      @media all and (max-width: $md) {
        transform: translateX(0);
        min-height: 224px;
        right: -10px;
      }
      transform: translateX(-35px);
    }
  }
}
</style>
