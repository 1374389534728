<template>
  <div class="cutaway-component">
    <div class="list">
      <span class="email label" v-if="data.email">Почта:</span>
      <strong
        class="email value"
        v-if="data.email"
        v-html="data.email"
      ></strong>
      <span class="phone label" v-if="data.phone">Телефон:</span>
      <strong class="phone value" v-if="data.phone"
        ><a class="phone-link" :href="utils.getPhoneLink(data.phone)">{{
          data.phone
        }}</a></strong
      >
      <span class="address label" v-if="data.address">Адрес:</span>
      <strong
        class="address value"
        v-if="data.address"
        v-html="data.address"
      ></strong>
    </div>
    <ButtonComponent url="#callback" v-if="data.button">{{
      data.button
    }}</ButtonComponent>
  </div>
</template>

<script>
import utils from "../utils";
export default {
  name: "Cutaway",
  props: {
    theme: {
      default: "default"
    },
    data: Object
  },
  data() {
    return {
      utils: utils
    };
  }
};
</script>

<style lang="scss">
.cutaway-component {
  @media all and (max-width: $md) {
    padding: 28px 18px;
  }
  background: $bg-green-half;
  grid-gap: 25px;
  display: grid;
  padding: 28px;
  align-content: flex-start;
  .list {
    @media all and (max-width: $md) {
      grid-gap: 12px 23px;
    }
    @extend %text-default;
    grid-template-columns: max-content 1fr;
    display: grid;
    grid-gap: 12px 100px;
  }
  .email {
    margin-top: 0;
    a {
      text-decoration: none;
      transition: border-color 0.5s;
      border-bottom: 1px solid transparent;
      &:hover {
        border-bottom: 1px solid $text-main-half;
      }
    }
  }
  p {
    @extend %text-default;
    font-size: 15px;
    margin: 0;
  }
  .phone-link {
    color: $text-main;
  }
  .button-component {
    width: 158px;
  }
}
</style>
