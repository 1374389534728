<template>
  <div>
    <BasicTemplate :page-id="1">
      <Banner :index="1" />
      <Cons v-if="data" :list="data.cons.list" id="jur_adress-nav" />
      <Features
        v-if="data"
        :title="data.features.title"
        :list="data.features.list"
        id="reception-nav"
      />
      <Tarrifs
        v-if="data"
        :title="data.tarrifs.title"
        :list="data.tarrifs.list"
        id="tarrifs-nav"
      />
      <Feedback
        v-if="main"
        :title="main.feedback.title"
        :list="main.feedback.list"
        :cons="main.feedback.cons"
      />
      <Faq v-if="data" :data="data.faq" id="faq-nav" />
      <Map
        v-if="data"
        :title="data.map.title"
        :places="data.map.places"
        :is-catalog="true"
        id="catalog-nav"
      />
      <Services
        v-if="main"
        :title="main.services.title"
        :list="main.services.list"
      />
    </BasicTemplate>
  </div>
</template>

<script>
import BasicTemplate from "./BasicTemplate.vue";
import Banner from "../containers/Banner.vue";
import Cons from "../containers/Cons.vue";
import Features from "../containers/Features.vue";
import Tarrifs from "../containers/Tarrifs.vue";
import Map from "../containers/Map.vue";
import Faq from "../containers/Faq.vue";
import Feedback from "../containers/Feedback.vue";
import Services from "../containers/Services.vue";

import AppData from "../AppData";
import utils from "../utils";
import firebase from "../firebase";
export default {
  name: "Office",
  data() {
    return {
      data: undefined, // AppData.office,
      main: undefined, // AppData.main,
      utils: utils,
      metaTitle: undefined,
      metaDescription: undefined,
      metaKeywords: undefined
    };
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "title", property: "title", content: this.metaTitle },
        {
          name: "description",
          property: "description",
          content: this.metaDescription
        },
        {
          name: "keywords",
          property: "keywords",
          content: this.metaKeywords
        }
      ]
    };
  },
  methods: {},
  mounted() {
    AppData.fetchData("office").then(data => {
      this.metaTitle = data.meta.title;
      this.metaDescription = data.meta.description;
      this.metaKeywords = data.meta.keywords;
      this.data = data;
      if (this.data && this.main) {
        setTimeout(() => {
          document.dispatchEvent(new Event("x-app-rendered"));
        }, 5000);
      }
    });
    AppData.fetchData("main").then(data => {
      this.main = data;
      if (this.data && this.main) {
        setTimeout(() => {
          document.dispatchEvent(new Event("x-app-rendered"));
        }, 5000);
      }
    });
  },
  components: {
    BasicTemplate,
    Banner,
    Cons,
    Features,
    Tarrifs,
    Map,
    Faq,
    Feedback,
    Services
  }
};
</script>

<style lang="scss">
.main {
  .banner-header .description .more {
    @media all and (max-width: $md) {
      margin-bottom: 25px;
      margin-top: 20px;
    }
  }
}
</style>
