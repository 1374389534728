<template>
  <div
    class="slide-block"
    :class="[
      {
        'is-horisontal': isHorizontal,
        'desktop-fixed': desktopFixed,
        'mobile-fixed': mobileFixed,
        centered: isCentered
      }
    ]"
    ref="slideBlock"
  >
    <v-touch
      @panstart="movedHandler"
      @panmove="movingHandler"
      @panend="endHandler"
      :pan-options="{ direction: 'horizontal', threshold: 40 }"
    >
      <div
        class="slidable"
        ref="slidable"
        :class="[
          {
            'is-touching': isTouching,
            'desktop-fixed': desktopFixed,
            'mobile-fixed': mobileFixed
          }
        ]"
        :style="{
          transform: transform,
          '--transition-duration': transitionDuration
        }"
      >
        <slot></slot>
      </div>
    </v-touch>
  </div>
</template>

<script>
export default {
  name: "Slide",
  props: {
    mobileFixed: Boolean,
    desktopFixed: Boolean,
    title: String,
    items: Array,
    isUnderline: Boolean,
    isMini: Boolean,
    isHorizontal: Boolean,
    itemWidth: {
      default: 408
    },
    itemWidthMobile: Number,
    pagesCount: {
      type: Number,
      default: 8
    },
    isCentered: Boolean
  },
  data() {
    return {
      transform: "translate(0px)",
      transformCurrent: 0,
      transformPixels: 0,
      isTouching: false,
      moveStart: 0,
      transitionDuration: "0.25s",
      currentPage: 0
    };
  },
  methods: {
    movedHandler(e) {
      this.isTouching = true;
      this.moveStart = e.center.x;
    },
    movingHandler(e) {
      if (this.isTouching) {
        this.$emit("ondrag");
        if (this.moveStart !== 0) {
          this.transformCurrent =
            this.transformPixels - (this.moveStart - e.center.x);
          this.transform = `translateX(${this.transformCurrent}px)`;
        }
      }
    },
    endHandler(e) {
      const itemWidth =
        screen.width < 1024
          ? this.itemWidthMobile || this.itemWidth
          : this.itemWidth;

      this.isTouching = false;
      this.$emit("ondragend");
      const diff = this.moveStart - e.center.x;
      let swipeCount = Math.round(diff / itemWidth);
      if (Math.abs(e.overallVelocity) > 1) {
        swipeCount += Math.sign(diff);
      }
      if (swipeCount === 0 && diff < 0) {
        swipeCount = -1;
      }
      if (swipeCount === 0 && diff > 0) {
        swipeCount = 1;
      }
      this.currentPage += swipeCount;
      this.currentPage = this.currentPage.clamp(0, this.pagesCount - 1);

      if (this.isCentered) {
        this.transformPixels =
          itemWidth * -this.currentPage + (screen.width / 2 - itemWidth / 2);
        this.transform = `translateX(${this.transformPixels}px)`;
      } else {
        this.transformPixels = -this.currentPage * itemWidth;
        // const maxTranslate = itemWidth * this.pagesCount - this.$refs.slideBlock.clientWidth;
        const maxTranslate =
          this.$refs.slidable.clientWidth - this.$refs.slideBlock.clientWidth;
        this.transformPixels = this.transformPixels.clamp(-maxTranslate, 0);
        // this.transitionDuration = (Math.abs(this.transformPixels - this.transformCurrent) / itemWidth).clamp(0, 1) + 's';
        this.transitionDuration = "0.25s";
        this.transform = `translateX(${this.transformPixels}px)`;
        this.currentPage = Math.round(-this.transformPixels / itemWidth);
      }
    },
    setPage(page) {
      const itemWidth =
        screen.width < 1024
          ? this.itemWidthMobile || this.itemWidth
          : this.itemWidth;
      this.currentPage = page;
      if (this.isCentered) {
        this.transformPixels =
          itemWidth * -this.currentPage + (screen.width / 2 - itemWidth / 2);
        this.transform = `translateX(${this.transformPixels}px)`;
      } else {
        this.transformPixels = -this.currentPage * itemWidth;
        // const maxTranslate = itemWidth * this.pagesCount - this.$refs.slideBlock.clientWidth;
        const maxTranslate =
          this.$refs.slidable.clientWidth - this.$refs.slideBlock.clientWidth;
        this.transformPixels = this.transformPixels.clamp(-maxTranslate, 0);
        // this.transitionDuration = (Math.abs(this.transformPixels - this.transformCurrent) / itemWidth).clamp(0, 1) + 's';
        this.transitionDuration = "0.25s";
        this.transform = `translateX(${this.transformPixels}px)`;
        this.currentPage = Math.round(-this.transformPixels / itemWidth);
      }
    },
    onResize() {
      if (this.isCentered) {
        const itemWidth =
          screen.width < 1024
            ? this.itemWidthMobile || this.itemWidth
            : this.itemWidth;
        this.transformPixels =
          itemWidth * -this.currentPage +
          (window.innerWidth / 2 - itemWidth / 2);
        this.transform = `translateX(${this.transformPixels}px)`;
      }
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style lang="scss">
$transition-duration: var(--transition-duration);
.slide-block {
  display: grid;
  > div {
    user-select: auto !important;
  }
  width: 100%;
  .slidable {
    display: grid;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    transition: transform $transition-duration ease-out;
    &.is-touching {
      transition: none;
    }
    &.desktop-fixed {
      @media all and (min-width: $md) {
        transition: none;
        transform: none !important;
      }
    }
    &.mobile-fixed {
      @media all and (max-width: $md) {
        transition: none;
        transform: none !important;
      }
    }
  }
}
</style>
