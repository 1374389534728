<template>
  <router-link
    class="button-component"
    tag="a"
    :to="url === undefined ? '' : url"
    :href="url"
    :class="[
      'default',
      {
        mini: isMini,
        inverted: isInverted,
        'style-transparent': isTransparent,
        'style-deactive': isDeactive,
        'style-mini-border-none': isArrowUp
      }
    ]"
  >
    <div
      v-if="isMini"
      :class="[
        'icon',
        {
          'arrow-up': isArrowUp,
          'arrow-right': isArrowRight,
          'arrow-left': isArrowLeft,
          white: isWhite,
          deactive: isDeactive
        }
      ]"
    ></div>
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    url: String,
    isMini: Boolean,
    isInverted: Boolean,
    isTransparent: Boolean,
    isArrowUp: Boolean,
    isArrowLeft: Boolean,
    isArrowRight: Boolean,
    isWhite: Boolean,
    isDeactive: Boolean
  }
};
</script>

<style lang="scss">
.big {
  .button-component {
    @extend %text-big;
  }
}
.button-component {
  @extend %button;
  &.default,
  &.mini {
    cursor: pointer;
  }

  &.mini {
    z-index: 1;
    display: flex;
    width: 13px;
    height: 13px;
    border-style: solid;
    border-radius: 50%;
    border-width: 1px;
    border-color: #243141;
    background: transparent;
    padding: 10px;
    justify-content: center;
    transition: background-color 0.2s, color 0.2s;
    &.button-component:hover {
      .arrow-right,
      .arrow-left,
      .arrow-up {
        background: #f7f7fe;
      }
    }
    .arrow-right {
      z-index: 2;
      width: 100%;
      background: #243141;
    }

    .arrow-left {
      z-index: 2;
      width: 100%;
      background: #243141;
      transform: scaleX(-1);
    }

    .arrow-up {
      border-color: transparent;
      z-index: 2;
      width: 70%;
      background: #243141;
      transform: rotate(270deg);
    }

    .white {
      background: #ffffff;
    }

    .deactive {
      background: rgb(170, 170, 170);
    }
  }
}

.inverted {
  background: #243141;
  color: #f7f7fe;
}

.style-mini-border-none {
  background: #f7f7fe;
  border: none;
}

.style-transparent {
  background: transparent;
  border: 1px solid rgba(36, 49, 65, 0.3);
}
.style-deactive {
  opacity: 0.3;
  pointer-events: none;
}
</style>
