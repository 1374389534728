<template>
  <div class="callback-dialog">
    <ModalDialog ref="dialog" @hide="onHide" :is-mini="true">
      <Callback class="callback col-4 col-md-12" />
    </ModalDialog>
  </div>
</template>

<script>
import ModalDialog from "../components/ModalDialog.vue";
import Callback from "../containers/Callback.vue";

import AppData from "../AppData";
import utils from "../utils";
export default {
  name: "CallbackDialog",
  data() {
    return {
      data: AppData.contacts.info,
      form: AppData.contacts.info.form,
      utils: utils,
      formSent: false,
      errorEmail: false,
      errorPhone: false,
      errorName: false,
      errorMessage: false,
      name: "",
      email: "",
      phone: "",
      message: "",
      buttonDisabled: false,
      icon: {
        layout: "default#image",
        imageHref: AppData.placemark,
        imageSize: [51, 51],
        imageOffset: [-15, -25]
      }
    };
  },
  watch: {
    $route(to) {
      if (to.hash === "#callback") {
        this.show();
      }
    }
  },
  methods: {
    initHandler() {},
    onChangeCheckbox(e) {
      this.buttonDisabled = !e.target.checked;
    },
    onInputPhone() {
      this.errorPhone = false;
    },
    onInputName() {
      this.errorName = false;
    },
    onInputEmail() {
      this.errorEmail = false;
    },
    onInputMessage() {
      this.errorMessage = false;
    },
    show() {
      this.$refs.dialog.show();
      this.$router.push(this.$route.path + "#callback");
    },
    hide() {
      this.$refs.dialog.hide();
    },
    onHide() {
      this.$router.push(this.$route.path.replace("#callback", ""));
    },
    onClickButton() {
      this.errorMessage = this.message.length < 2;
      this.errorName = this.name.length < 2;
      this.errorPhone =
        this.phone.indexOf("_") !== -1 || this.phone.length === 0;
      this.errorEmail = !utils.validateEmail(this.email);

      if (
        !this.errorPhone &&
        !this.errorField &&
        !this.errorMessage &&
        !this.errorEmail
      ) {
        this.$refs.dialog.hide();
      }
    }
  },
  mounted() {
    if (this.$route.hash === "#callback") {
      this.show();
    }
  },
  components: {
    ModalDialog,
    Callback
  }
};
</script>

<style lang="scss">
.callback-dialog {
  .callback {
    width: 340px;
    height: 505px;
    .Callback {
      display: grid;
    }
  }
}
</style>
