/* eslint-disable camelcase */
import axios from "axios";
const config = {
  headers: {
    "Content-Type": "application/json",
    Accept: "text/json"
  }
};
const settings = {
  get url() {
    if (location.href.indexOf("https://") !== -1) {
      return "https://feedback.kvadin.ru";
    } else {
      return "https://feedback.kvadin.ru";
    }
  }
};
export default {
  feedback(
    { email = "", message = "", name = "", phone = "" },
    callback,
    errorHandler
  ) {
    this.post("/", arguments[0], callback, errorHandler);
  },

  put(path, data, callback, errorHandler) {
    axios({
      method: "put",
      url: path.indexOf("http") !== -1 ? path : settings.url + path,
      data: data,
      config: { headers: { "Content-Type": "multipart/form-data" } }
    }).then(
      response => {
        callback(response.data);
      },
      error => {
        errorHandler(error);
      }
    );
  },

  get(path, data, callback, errorHandler, isFullUrl) {
    axios
      .get(path.indexOf("http") !== -1 ? path : settings.url + path, data)
      .then(
        response => {
          callback(response.data);
        },
        error => {
          errorHandler(error);
        }
      );
  },

  post(path, data, callback, errorHandler) {
    console.log(data);
    axios({
      method: "post",
      url: path.indexOf("http") !== -1 ? path : settings.url + path,
      data: data,
      config: { headers: { "Content-Type": "application/json" } }
    })
      .then(response => {
        callback(response);
      })
      .catch(error => {
        errorHandler(error);
      });
  },

  delete(path, data, callback, errorHandler) {
    axios({
      method: "delete",
      url: path.indexOf("http") !== -1 ? path : settings.url + path,
      data: data,
      config: { headers: { "Content-Type": "multipart/form-data" } }
    }).then(
      response => {
        callback(response.data);
      },
      error => {
        errorHandler(error);
      }
    );
  }
};
