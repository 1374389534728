var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tarrifs-component"},[_c('div',{staticClass:"area"},[_c('div',{directives:[{name:"animated-show",rawName:"v-animated-show",value:(_vm.utils.handleScroll),expression:"utils.handleScroll"}],staticClass:"row"},[(_vm.title)?_c('h2',{staticClass:"col-12",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),(_vm.list.length > 1)?_c('Menu',{staticClass:"col-12",attrs:{"list":_vm.list.map(function (item) { return item.atitle; })},on:{"changed":_vm.onChangeTarrifs}}):_vm._e(),_c('Slide',{staticClass:"col-12",attrs:{"desktop-fixed":true,"item-width":245,"pages-count":_vm.list[_vm.current].list.length}},[_c('div',{staticClass:"container-slidable"},_vm._l((_vm.list[_vm.current].list),function(l,i){return _c('section',{key:i,class:[
              'list-section-baner',
              { 'list-section-baner--best': l.more }
            ]},[(l.more)?_c('div',{staticClass:"best",domProps:{"innerHTML":_vm._s(l.more)}}):_vm._e(),_c('div',{staticClass:"container"},[(l.title)?_c('div',{staticClass:"content"},[_c('Description',{ref:"bannerDescription",refInFor:true,staticClass:"small ",attrs:{"title":l.title,"desc":l.desc}})],1):_vm._e(),(l.prices && l.prices.length > 0 && l.prices[0].price)?_c('ul',{staticClass:"price-list"},[_vm._l((l.prices),function(item,index){return [(item.price)?_c('li',{key:index,staticClass:"price-item"},[(item.price)?_c('strong',[_c('span',{staticClass:"price",domProps:{"innerHTML":_vm._s(item.price)}}),_vm._v(" \n                      "),_c('span',{staticClass:"currency"},[_vm._v("руб."+_vm._s(l.prices.length === 3 ? " " : ""))]),_c('span',{class:[
                          {
                            duration: l.prices.length === 3,
                            currency: l.prices.length === 1
                          }
                        ],domProps:{"innerHTML":_vm._s(item.duration)}})]):_vm._e(),(item.discount)?_c('strong',{staticClass:"discount",domProps:{"innerHTML":_vm._s(item.discount)}}):_vm._e()]):_vm._e()]})],2):_vm._e(),(l.button)?_c('ButtonComponent',{staticClass:"button-description",attrs:{"url":"#callback"}},[_vm._v(_vm._s(l.button))]):_vm._e(),(l.list && l.list.length > 0 && l.list[0])?_c('ul',{staticClass:"features"},_vm._l((l.list),function(item,index){return _c('li',{key:index,staticClass:"features-item",domProps:{"innerHTML":_vm._s(item)}})}),0):_vm._e(),(l.call)?_c('Recall',{attrs:{"call":l.call,"email":l.email}}):_vm._e()],1)])}),0)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }