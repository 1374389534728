<template>
  <div class="realty">
    <BasicTemplate :page-id="3">
      <Banner :index="3" />
      <Help
        v-if="data"
        id="solutions-nav"
        :title="data.help.title"
        :desc="data.help.desc"
        :list="data.help.list"
      />
      <Features
        v-if="data"
        :title="data.features.title"
        :list="data.features.list"
        id="benefits-nav"
      />
      <Portfolio
        v-if="data"
        :title="data.portfolio.title"
        :list="data.portfolio.list"
        :before="data.portfolio.labelBefore"
        :after="data.portfolio.labelAfter"
        id="objects-nav"
      />
      <Services
        v-if="main"
        :title="main.services.title"
        :list="main.services.list"
        id="services-nav"
      />
    </BasicTemplate>
  </div>
</template>

<script>
import BasicTemplate from "./BasicTemplate.vue";
import Banner from "../containers/Banner.vue";
import Help from "../containers/Help.vue";
import Portfolio from "../containers/Portfolio.vue";
import Features from "../containers/Features.vue";
import Services from "../containers/Services.vue";

import AppData from "../AppData";
import utils from "../utils";
export default {
  name: "Realty",
  data() {
    return {
      data: undefined, //AppData.realty,
      main: undefined, //AppData.main,
      utils: utils,
      metaTitle: undefined,
      metaDescription: undefined,
      metaKeywords: undefined
    };
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "title", property: "title", content: this.metaTitle },
        {
          name: "description",
          property: "description",
          content: this.metaDescription
        },
        {
          name: "keywords",
          property: "keywords",
          content: this.metaKeywords
        }
      ]
    };
  },
  methods: {},
  mounted() {
    AppData.fetchData("realty").then(data => {
      this.metaTitle = data.meta.title;
      this.metaDescription = data.meta.description;
      this.metaKeywords = data.meta.keywords;
      this.data = data;
      if (this.data && this.main) {
        setTimeout(() => {
          document.dispatchEvent(new Event("x-app-rendered"));
        }, 5000);
      }
    });
    AppData.fetchData("main").then(data => {
      this.main = data;
      if (this.data && this.main) {
        setTimeout(() => {
          document.dispatchEvent(new Event("x-app-rendered"));
        }, 5000);
      }
    });
  },
  components: {
    BasicTemplate,
    Banner,
    Help,
    Portfolio,
    Features,
    Services
  }
};
</script>

<style lang="scss">
.realty {
  .banner-header .description .more {
    @media all and (max-width: $md) {
      margin-bottom: 25px;
      margin-top: 20px;
    }
  }
  .banner {
    .list-section-baner {
      .content {
        grid-column: 1 / span 8;
        br {
          @media all and (max-width: $md) {
            display: none;
          }
        }
      }
    }
    .img-banner {
      @media all and (max-width: $md) {
        object-fit: cover;
        max-height: 180px;
        max-width: auto;
        height: 180px;
        transform: translateX(10%);
      }
      @media all and (max-width: $sm) {
        transform: translateX(30%);
      }
    }
  }
}
</style>
