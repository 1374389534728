<template>
  <div class="cons-component">
    <div class="area">
      <div class="row">
        <h1 class="mini col-8 col-md-12" v-html="title"></h1>
        <strong class="col-7 col-md-12"
          ><span class="date" v-if="date" v-html="date"></span
        ></strong>
        <div class="desc col-7 col-md-12" v-html="desc"></div>
        <div class="spacer col-12 col-md-12"></div>
        <Cutaway class="cutaway col-5 col-md-12" :data="contacts" />
      </div>
    </div>
  </div>
</template>

<script>
import Cutaway from "../components/Cutaway.vue";
export default {
  name: "EventContainer",
  props: {
    theme: {
      default: "default"
    },
    title: String,
    desc: String,
    date: String,
    contacts: Object
  },
  data() {
    return {};
  },
  components: { Cutaway }
};
</script>

<style lang="scss">
.cons-component {
  @extend %page-block;
  .row {
    grid-row-gap: 10px;
  }
  .desc {
    @extend %text-big;
  }
  .date {
    @extend %text-default-static;
    color: $text-main-half;
  }
}
</style>
