<template>
  <div class="app-container" v-if="data && footer">
    <Header
      :type-effect="true"
      :titleTyper="data.titleTyper"
      class="main"
      :isMain="true"
      :logo="data.logo"
      :title="data.title"
      :list="data.list"
      :nav="data.menu"
      :page-id="pageId"
      @contacts="showContacts"
    />
    <div class="app-content"><slot></slot></div>
    <Footer
      :desc="footer.info.desc"
      :policy="footer.info.policy"
      :madein="footer.info.madein"
      :menu="footer.menu.list"
      :contacts="footer.contacts"
    />
    <ContactsDialog ref="contacts"> </ContactsDialog>
    <CallbackDialog ref="callback"> </CallbackDialog>
  </div>
</template>

<script>
import Header from "../containers/Header.vue";
import ContactsDialog from "../containers/ContactsDialog";
import CallbackDialog from "../containers/CallbackDialog";
import Footer from "../containers/Footer";
import AppData from "../AppData";
import utils from "../utils";

export default {
  name: "BasicTemplate",
  props: {
    pageId: {
      default: 0
    }
  },
  data() {
    return {
      data: undefined, //AppData.main,
      footer: undefined //AppData.footer
    };
  },
  methods: {
    showContacts() {
      this.$refs.contacts.show();
    },
    onLoad() {}
  },
  created() {
    AppData.fetchData("footer").then(result => {
      this.footer = result;
    });
    AppData.fetchData("main").then(result => {
      this.data = result;
    });
    document.onload = this.onLoad();
  },
  mounted() {
    if (this.$route.hash) {
      var el = document.getElementById(
        this.$route.hash.replace("#", "") + "-nav"
      );
      if (el) {
        setTimeout(() => {
          utils.scrollTo(el);
        }, 1500);
        return { x: 0, y: 0 };
      }
    }
  },
  components: {
    Header,
    ContactsDialog,
    CallbackDialog,
    Footer
  }
};
</script>

<style lang="scss">
body {
  // transition: opacity 0.5s;
}

// .overflow {
//   transition: none;
//   overflow: hidden;
//   position: fixed;
//   height: 100vh;
//   opacity: 0;
// }
.app-container {
  width: 100%;
  background: #fff;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>
