<template>
  <div style="position:relative;" class="input-container">
    <input
      :name="name"
      :id="id"
      class="c-input"
      v-model="inputValue"
      @input="onInput"
      size="5"
      :class="['form-control', { filled: value !== '' }]"
    />
    <label class="form-control-placeholder" :for="id">{{ textDefault }}</label>
  </div>
</template>

<script>
export default {
  name: "InputComponent",
  props: {
    name: { default: "" },
    id: { default: "" },
    value: String,
    textDefault: String
  },
  data() {
    return {
      inputValue: ""
    };
  },
  watch: {
    value(val) {
      this.inputValue = val;
    }
  },
  methods: {
    updateInputPhone() {
      let tmp = this.inputValue.replace("+7 ", "");
      tmp = tmp.replace("7 ", "");
      tmp = tmp.replace("+7", "");
      tmp = tmp.replace("+ ", "");
      tmp = tmp.replace("+", "");
      tmp = tmp.replace(/[^0-9]/g, "");

      while (tmp.length < 10) {
        tmp += "_";
      }
      tmp = tmp.replace(
        /([0-9_]{3})([0-9_]{3})([0-9_]{2})([0-9_]{2})/,
        "+7 ($1) $2-$3-$4"
      );
      this.inputValue = tmp.substr(0, 18);
    },
    onInput(e) {
      const inputField = e.target;
      this.inputValue = e.target.value;
      if (this.id === "phone") {
        this.updateInputPhone();

        const underscoreIndex = this.inputValue.indexOf("_");
        const selection = e.target.selectionStart;

        e.target.value = this.inputValue;

        // this.$nextTick(() => {
        if (e.inputType === "deleteContentBackward") {
          const re = /[\d]/g;
          const stringNumber = this.inputValue.substring(0, selection);
          let myArray;
          let lastIndex = 0;
          do {
            myArray = re.exec(stringNumber);
            if (myArray !== null) {
              lastIndex = myArray.index;
            }
          } while (myArray !== null);
          this.setCaretPosition(inputField, lastIndex + 1);
        } else if (underscoreIndex === -1) {
          const index =
            this.inputValue.substring(selection - 1).search(/([0-9_])/) +
            selection;
          if (index === selection) {
            this.setCaretPosition(inputField, index);
          } else {
            this.setCaretPosition(inputField, index);
          }
        } else if (underscoreIndex !== -1) {
          this.setCaretPosition(inputField, underscoreIndex);
        }
      }
      this.$emit("input", e.target.value);
    },
    setCaretPosition(elem, caretPos) {
      if (elem != null) {
        if (elem.createTextRange) {
          const range = elem.createTextRange();
          range.move("character", caretPos);
          range.select();
        } else if (elem.selectionStart) {
          elem.focus();
          elem.setSelectionRange(caretPos, caretPos);
        } else {
          elem.focus();
        }
      }
    }
  }
};
</script>

<style lang="scss">
.form-control:focus,
.form-control.filled {
  transform: translateY(8px);
  opacity: 1;
}

.c-input {
  border: 0;
  height: 49px;
  width: 100%;
  background: transparent !important;
  color: $text-main;
  font-size: 15px;
  align-self: flex-end;
  outline: none;
  &::placeholder {
    color: $text-inactive;
    opacity: 1;
  }
  position: relative;
}
.input-container {
  display: grid;
  align-content: center;
  height: 49px;
  border-radius: 5px;
  border: 1px solid $border-grey-dark;
  background: $bg-main;
  padding: 0 14px;
  &.error {
    input {
      color: $color-error;
    }
    border: 1px solid $color-error;
  }
  display: grid;
}
.form-control-placeholder {
  pointer-events: none;
  @media all and (max-width: $md) {
    top: 15px;
  }

  position: absolute;
  top: 12px;
  padding: 2px 0 0 18px;
  transition: all 200ms;
  opacity: 1;
  color: $text-inactive;
  @extend %text-default;
  transform-origin: left top;
}

.form-control:focus + .form-control-placeholder,
.form-control.filled + .form-control-placeholder {
  transform: translateY(-40%) scale(0.75);
  opacity: 1;
}
</style>
