<template>
  <div class="features-component">
    <div class="area">
      <div class="row">
        <div
          v-animated-show="utils.handleScroll"
          class="content-container col-md-12"
          :class="[
            {
              'col-7': !noConsult,
              'col-12': noConsult,
              'is-underline': isUnderline
            }
          ]"
        >
          <h2 v-if="title" v-html="title"></h2>
          <div class="partners">
            <a
              v-for="(l, i) in partners"
              :key="i"
              target="_blank"
              rel="nofollow"
              :href="l.href"
              ><img :src="l.img" :style="l.style"
            /></a>
          </div>
          <div class="features-list" :class="[{ double: isDouble }]">
            <section class="list-section-baner" v-for="(l, i) in list" :key="i">
              <div class="content" v-if="l.title || l.desc">
                <Description
                  :title-href="l.href"
                  class="small icon-left"
                  ref="bannerDescription"
                  :title="l.title"
                  :desc="l.desc"
                  :icon="l.icon"
                  :button="l.button"
                  href="#callback"
                />
              </div>
            </section>
          </div>
        </div>
        <Callback v-if="!noConsult" class="callback col-4 col-md-12" />
      </div>
    </div>
  </div>
</template>

<script>
import Callback from "./Callback.vue";
import utils from "../utils";
export default {
  name: "Features",
  props: {
    theme: {
      default: "default"
    },
    isDouble: Boolean,
    isUnderline: Boolean,
    noConsult: Boolean,
    title: String,
    partners: Array,
    list: Array
  },
  data() {
    return {
      utils: utils
    };
  },
  components: {
    Callback
  }
};
</script>

<style lang="scss">
.features-component {
  background: $bg-blue;
  h2 {
    margin-top: 0px;
  }
  @extend %page-block;
  .row {
    grid-row-gap: 28px;
  }
  .features-list {
    @media all and (max-width: $md) {
      br {
        display: none;
      }
    }
    grid-column: 1 / span 2;
    margin-top: 40px;
    display: grid;
    grid-gap: 28px;
    &.double {
      @media all and (max-width: $md) {
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: unset;
        grid-auto-flow: unset;
        margin-top: 10px;
        grid-gap: 18px;
      }
      grid-auto-flow: column;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(2, minmax(0, max-content));
    }
  }
  .description.small {
    grid-row-gap: 15px;
  }
  .callback {
    @media all and (max-width: $md) {
      transform: unset;
      grid-column: 1 / span 12;
    }
    transform: translate(-28px);
    grid-column: 9 / span 4;
  }
  .content-container {
    grid-template-columns: minmax(0, 1fr) minmax(0, max-content);
    display: grid;
    &.is-underline {
      .description .title span {
        border-bottom: 1px solid $color-accent-half;
      }
    }
  }
  .kontur {
    @extend %link;
    color: $text-main;
    font-weight: bold;
    position: relative;
    &::after {
      font-family: fontello;
      content: "\e805";
      font-size: 14px;
      color: $color-accent;
      position: absolute;
      right: -25px;
      top: 2px;
    }
  }
  .partners {
    @media all and (max-width: $md) {
      grid-column: 1;
      grid-row: unset;
      justify-self: flex-start;
      transform: scale(0.75);
      transform-origin: 0 50%;
    }
    grid-column: 2;
    grid-row: 1;
    justify-self: flex-end;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 38px;
    a {
    }
  }
}
</style>
