<template>
  <div class="map-component">
    <div class="map">
      <yandex-map
        :coords="[55.76823, 37.649814]"
        zoom="10"
        style="width: 100%; height: 450px;"
        :cluster-options="{
          1: { clusterDisableClickZoom: true }
        }"
        :behaviors="['drag']"
        :controls="[]"
        @map-was-initialized="initHandler"
      >
        <ymap-marker
          v-for="(l, i) in places.list"
          :key="i"
          :marker-id="i"
          marker-type="placemark"
          :coords="l.coords"
          :hint-content="l.place"
          :balloon="{
            header: title,
            body: '<div style=\'max-width:250px;\'>' + l.place + '</div>'
          }"
          :icon="icon"
          cluster-name="1"
        ></ymap-marker>
      </yandex-map>
    </div>
    <div class="area" v-if="places.list.length > 0">
      <div class="row">
        <MapInfo
          class="map-info col-4 col-md-12"
          :is-catalog="true"
          :data="places"
          :title="title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MapInfo from "../containers/MapInfo.vue";
import AppData from "../AppData";
export default {
  name: "Map",
  props: {
    theme: {
      default: "default"
    },
    isCatalog: Boolean,
    title: String,
    places: Object
  },
  data() {
    return {
      icon: {
        layout: "default#image",
        imageHref: AppData.placemark,
        imageSize: [51, 51],
        imageOffset: [-15, -25]
      }
    };
  },
  methods: {
    initHandler() {}
  },
  components: {
    MapInfo
  }
};
</script>

<style lang="scss">
.map-component {
  grid-template-columns: minmax(0, 1fr);
  overflow: hidden;
  width: 100%;
  max-width: 100vw;
  display: grid;
  align-content: center;
  .map {
    overflow: hidden;
    width: 100%;
    max-width: 100vw;
    grid-row: 1;
    grid-column: 1;
    z-index: 5;
    position: relative;
  }
  .area {
    @media all and (max-width: $md) {
      display: none;
    }
    display: grid;
    width: 100%;
    align-content: center;
    grid-row: 1;
    grid-column: 1;
    position: relative;
  }
  height: 450px;
  .map-info {
    position: relative;
    z-index: 6;
    @media all and (max-width: $md) {
      transform: unset;
      grid-column: 1 / span 12;
    }
    transform: translate(-28px);
    grid-column: 9 / span 4;
  }
  @media all and (max-width: $md) {
    padding-bottom: 27px;
  }
  padding-bottom: 66px;
}
</style>
