<template>
  <div class="tarrifs-component">
    <div class="area">
      <div class="row" v-animated-show="utils.handleScroll">
        <h2 v-if="title" v-html="title" class="col-12"></h2>
        <Menu
          v-if="list.length > 1"
          :list="list.map(item => item.atitle)"
          @changed="onChangeTarrifs"
          class="col-12"
        ></Menu>
        <Slide
          class="col-12"
          :desktop-fixed="true"
          :item-width="245"
          :pages-count="list[current].list.length"
        >
          <div class="container-slidable">
            <section
              :class="[
                'list-section-baner',
                { 'list-section-baner--best': l.more }
              ]"
              v-for="(l, i) in list[current].list"
              :key="i"
            >
              <div class="best" v-if="l.more" v-html="l.more"></div>
              <div class="container">
                <div class="content" v-if="l.title">
                  <Description
                    class="small "
                    ref="bannerDescription"
                    :title="l.title"
                    :desc="l.desc"
                  />
                </div>
                <ul
                  class="price-list"
                  v-if="l.prices && l.prices.length > 0 && l.prices[0].price"
                >
                  <template v-for="(item, index) in l.prices">
                    <li class="price-item" :key="index" v-if="item.price">
                      <strong v-if="item.price">
                        <span class="price" v-html="item.price"></span>&nbsp;
                        <span class="currency"
                          >руб.{{ l.prices.length === 3 ? "&nbsp;" : "" }}</span
                        >
                        <span
                          :class="[
                            {
                              duration: l.prices.length === 3,
                              currency: l.prices.length === 1
                            }
                          ]"
                          v-html="item.duration"
                        ></span>
                      </strong>
                      <strong
                        class="discount"
                        v-if="item.discount"
                        v-html="item.discount"
                      ></strong>
                    </li>
                  </template>
                </ul>

                <ButtonComponent
                  v-if="l.button"
                  url="#callback"
                  class="button-description"
                  >{{ l.button }}</ButtonComponent
                >

                <ul
                  class="features"
                  v-if="l.list && l.list.length > 0 && l.list[0]"
                >
                  <li
                    class="features-item"
                    v-for="(item, index) in l.list"
                    :key="index"
                    v-html="item"
                  ></li>
                </ul>
                <Recall v-if="l.call" :call="l.call" :email="l.email"></Recall>
              </div>
            </section>
          </div>
        </Slide>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
import Slide from "../components/Slide.vue";
import utils from "../utils";
export default {
  name: "Tarrifs",
  props: {
    theme: {
      default: "default"
    },
    list: Array,
    menu: Array,
    title: String
  },
  data() {
    return {
      utils: utils,
      current: 0
    };
  },
  methods: {
    onChangeTarrifs(index) {
      this.current = index;
    }
  },
  components: {
    Menu,
    Slide
  }
};
</script>

<style lang="scss">
.tarrifs-component {
  overflow: hidden;
  @extend %page-block;
  .row {
    grid-row-gap: 28px;
  }
  h2 {
    text-align: center;
    margin: 0;
  }
  .menu-container {
    margin-top: -20px;
  }
  .container-slidable {
    @media all and (max-width: $md) {
      grid-auto-columns: max-content;
      grid-template-columns: unset;
      grid-auto-flow: column;
    }
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 28px;
  }
  .list-section-baner {
    position: relative;
    @media all and (max-width: $md) {
      background: $bg-green-half;
    }
    .container {
      @media all and (max-width: $md) {
        background: transparent;
        padding: 48px 28px 28px 28px;
      }
      padding: 42px;
      position: relative;
      z-index: 1;
      background: $bg-main;
    }
    &--best {
      @media all and (max-width: $md) {
        background: $bg-main;
      }
      z-index: 2;
      &::before {
        @media all and (max-width: $md) {
          height: auto;
          bottom: 0;
        }
        position: absolute;
        left: 0;
        top: 0px;
        right: 0;
        height: 325px;
        display: block;
        content: "";
        z-index: 0;
        @extend %tarrifs-shadow;
      }
      .container {
        @media all and (max-width: $md) {
          background: $bg-main;
        }
      }
    }
    @media all and (max-width: $md) {
      width: 217px;
      grid-gap: 10px;
      grid-template-rows: 1fr max-content;
      align-items: flex-start;
    }
    position: relative;
    background: $bg-main;
    display: grid;
    align-content: flex-start;
    transition: background-color 0.2s;
    ul.price-list {
      margin-top: 35px;
      margin-bottom: 0px;
      grid-gap: 24px;
      .price-item {
        display: grid;
        grid-gap: 5px;
        .price {
          @extend %text-big;
        }
        .currency {
          @extend %text-mini;
        }
        .duration {
          @extend %text-default;
        }
        .discount {
          @extend %text-mini;
          color: $color-accent;
        }
      }
    }
    .button-description {
      @extend %text-big;
      margin-top: 42px;
      @media all and (max-width: $md) {
        width: 120px;
      }
      width: 170px;
    }
    .features {
      @media all and (max-width: $md) {
        margin-top: 18px;
      }
      margin-top: 40px;
      margin-bottom: 00px;
    }
    .best {
      font-size: 13px;
      z-index: 2;
      position: absolute;
      top: 0;
      width: 100%;
      height: 28px;
      font-weight: bold;
      display: grid;
      justify-content: center;
      align-content: center;
      color: $text-inverse-main;
      background: $color-accent;
    }
  }
}
</style>
