<template>
  <div class="App-Callback">
    <div
      class="App-callback-container"
      v-animated-show="utils.handleScroll"
      v-if="description && form"
    >
      <Description
        id="desc-callback"
        class="default desc-small"
        :title="description.title"
        :desc="formSent ? '' : description.desc"
      />
      <form v-show="!formSent" class="form-callback">
        <InputComponent
          id="name"
          name="name"
          :text-default="form.name.placeholder"
          v-model="name"
          @input="onInputName"
          :valid="name !== ''"
          :class="[{ error: errorField }]"
        />
        <InputComponent
          id="phone"
          name="phone"
          :text-default="form.phone.placeholder"
          @input="onInput"
          v-model="phone"
          :valid="phone !== ''"
          :class="[{ error: errorPhone }]"
        />
        <ButtonComponent
          id="button-callback"
          :is-deactive="buttonDisabled"
          @click.native.prevent="onClickButton"
          >{{ form.btn.title }}</ButtonComponent
        >
        <input
          type="checkbox"
          class="checkbox-input"
          style="display:none"
          id="agree"
          checked="true"
          @change="onChangeCheckbox"
          @input="onChangeCheckbox"
        />
        <label class="checkbox-label" for="agree">
          <span v-html="form.agreement"></span>
        </label>
      </form>
      <div v-show="formSent" class="form-sent">
        <div v-html="form.sent.desc"></div>
      </div>
      <div class="recal" v-html="form.recal.text"></div>
      <h2>
        <BaseLabel icon-class="icon-phone-form">
          <a class="phone-link" :href="utils.getPhoneLink(form.recal.phone)">{{
            form.recal.phone
          }}</a>
        </BaseLabel>
      </h2>
    </div>
  </div>
</template>

<script>
import Description from "../components/Description";
import ButtonComponent from "../components/ButtonComponent";
import InputComponent from "../components/InputComponent";
import utils from "../utils";
import AppData from "../AppData";
export default {
  name: "Callback",
  props: {
    sent: Object
  },
  data() {
    return {
      description: undefined,
      form: undefined,
      formSent: false,
      buttonDisabled: false,
      utils: utils,
      errorField: false,
      errorPhone: false,
      phone: "",
      name: ""
    };
  },
  components: {
    Description,
    ButtonComponent,
    InputComponent
  },
  mounted() {
    AppData.fetchData("callback").then(result => {
      this.description = result.info.description;
      this.form = result.info.form;
    });
  },
  methods: {
    onClickButton() {
      this.errorField = this.name.length < 2;
      this.errorPhone =
        this.phone.indexOf("_") !== -1 || this.phone.length === 0;
      if (!this.errorPhone && !this.errorField) {
        AppData.callRequest(this.name, this.phone)
          .then(() => {
            this.formSent = true;
            this.requestSended = false;
            this.name = "";
            this.email = "";
          })
          .catch(() => {
            this.requestSended = false;
            this.error = "Возникла ошибка при отправке письма, попробуйте еще";
          });
      }
    },
    onChangeCheckbox(e) {
      this.buttonDisabled = !e.target.checked;
    },
    onInputName(value) {
      this.errorField = false;
      this.name = value;
    },
    onInput(value) {
      this.errorField = false;
      this.phone = value;
    }
  }
};
</script>

<style lang="scss">
.App-Callback {
  .App-callback-container {
    @extend %float-shadow;
    align-self: flex-start;
    background: $bg-main;
    padding: 38px 28px 33px 28px;
    height: 505px;
    box-sizing: border-box;
    align-content: stretch;
    display: grid;
    grid-template-rows: max-content 1fr max-content max-content;
  }
  .form-callback {
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 25px;
    display: grid;
    grid-gap: 14px;
    .checkbox-label {
      margin-top: 8px;
      color: $text-inactive;
    }
  }
  #button-callback {
    width: 214px;
    margin-top: 14px;
  }
  .recal {
    align-self: flex-end;
  }
  h2 {
    margin: 0px;
    .base-label {
      font-size: inherit;
      &::before {
        font-size: 18px;
        top: -2px;
      }
    }
  }
  a.phone-link {
    font-weight: bold;
  }
  .form-sent {
    align-items: center;
    align-content: center;
    align-self: center;
    display: grid;
    margin-top: 40px;
    height: 217px;
    color: $color-accent;
    font-weight: bold;
    p {
      margin: 5px 0;
    }
  }
  .description-content {
    grid-gap: 20px;
  }
}
</style>
