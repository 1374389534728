export default {
  getPhoneLink(phone) {
    return "tel:" + phone.replace(/[^0-9+]/g, "");
  },

  handleScroll: function(evt, el) {
    if (el.getBoundingClientRect().y < window.innerHeight + 40) {
      // el.setAttribute("style", "animation: showUp 1s forwards;");
      el.classList.add("show-up");
    }
  },
  scrollElementToTop(scroller) {
    let offset = { top: 0, left: 0 };
    (function smoothscroll() {
      var currentScroll = scroller.scrollTop;
      if (currentScroll > offset.top) {
        window.requestAnimationFrame(smoothscroll);
        scroller.scrollTo(
          0,
          (currentScroll - currentScroll / 5).clamp(
            offset.top,
            Number.MAX_VALUE
          )
        );
      }
    })();
  },
  scrollToTop() {
    let el = document.getElementById("main-banner");
    let offset = this.getOffset(el);
    (function smoothscroll() {
      var currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > offset.top) {
        window.requestAnimationFrame(smoothscroll);

        window.scrollTo(
          0,
          (currentScroll - currentScroll / 5).clamp(
            offset.top,
            Number.MAX_VALUE
          )
        );
      }
    })();
  },
  animating: false,
  scrollTo(el) {
    if (!this.animating) {
      this.animating = true;
      let offset = this.getOffset(el);
      let start = document.documentElement.scrollTop || document.body.scrollTop;
      let end = offset.top;
      let currentFrame = 0;
      let smoothscroll = () => {
        if (currentFrame < 60) {
          window.requestAnimationFrame(smoothscroll);
          currentFrame++;
          var t = currentFrame / 60;
          window.scrollTo(0, start + this.easeInOut(t) * (end - start));
        } else {
          this.animating = false;
        }
      };
      smoothscroll();
    }
  },
  scrollToPosition(position, frames = 60) {
    if (!this.animating) {
      this.animating = true;
      let start = document.documentElement.scrollTop || document.body.scrollTop;
      let end = position.y;
      let currentFrame = 0;
      let smoothscroll = () => {
        if (currentFrame < frames) {
          window.requestAnimationFrame(smoothscroll);
          currentFrame++;
          var t = currentFrame / frames;
          window.scrollTo(
            0,
            start + (this.easeInOut(t) * (end - start)).clamp(0, position.y)
          );
        } else {
          this.animating = false;
        }
      };
      smoothscroll();
    }
  },
  easeInOut(t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  },
  getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  },
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
};
