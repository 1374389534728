<template>
  <div class="recall-component">
    <p class="call" v-if="call" v-html="call"></p>
    <p class="email" v-if="email" v-html="email"></p>
  </div>
</template>

<script>
export default {
  name: "Recall",
  props: {
    theme: {
      default: "default"
    },
    call: String,
    email: String
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.recall-component {
  .email {
    margin-top: 20px;
  }
  p {
    @extend %text-default;
  }
  a {
    color: $text-main;
    border-color: transparent;
    font-weight: bold;
    &:hover {
      border-color: $text-main;
    }
  }
}
</style>
