import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Meta from "vue-meta";
import VueAgile from "vue-agile";
import VueTouch from "vue-touch";
import VueScript2 from "vue-script2";

import Recall from "./components/Recall.vue";
import ButtonComponent from "./components/ButtonComponent.vue";
import InputComponent from "./components/InputComponent.vue";
import TextareaComponent from "./components/TextareaComponent.vue";
import BaseLabel from "./components/BaseLabel.vue";
import BaseInput from "./components/BaseInput.vue";
import Description from "./components/Description.vue";
import Icons from "./assets/fontello/css/fontello.css";
import Typograph from "./typograph.scss";
import Grid from "./grid.scss";
import YmapPlugin from "vue-yandex-maps";
const options = {
  // you may define your apiKey, lang and version or skip this.
  apiKey: "7c82280f-df9a-410a-a73b-edd03240baa3",
  lang: "ru_RU",
  version: "2.1"
};
Vue.use(YmapPlugin, options);

Vue.use(Meta);
Vue.use(VueScript2);
Vue.use(VueAgile);

Vue.config.productionTip = false;
VueTouch.config.swipe = {
  direction: "horizontal"
};
Vue.use(VueTouch, { name: "v-touch" });

Vue.component("TextareaComponent", TextareaComponent);
Vue.component("InputComponent", InputComponent);
Vue.component("Recall", Recall);
Vue.component("ButtonComponent", ButtonComponent);
Vue.component("BaseLabel", BaseLabel);
Vue.component("BaseInput", BaseInput);
Vue.component("Description", Description);

Vue.directive("animated-show", {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    el.classList.add("animated-show");
    f(undefined, el);
    window.addEventListener("scroll", f);
  }
});

Vue.directive("scroll", {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  }
});

new Vue({
  Typograph,
  Grid,
  Icons,
  router,
  store,
  render: h => h(App)
}).$mount("#app", true);

if (!Number.prototype.clamp) {
  // eslint-disable-next-line no-extend-native
  Number.prototype.clamp = function(min, max) {
    return Math.min(Math.max(this, min), max);
  };
}
