<template>
  <div style="position:relative;" class="textarea-container">
    <textarea
      :name="name"
      :id="id"
      class="c-textarea"
      v-model="inputValue"
      @input="onInput"
      :rows="rows"
      :class="['form-control', { filled: value !== '' }]"
    ></textarea>
    <label class="form-control-placeholder" :for="id">{{ textDefault }}</label>
  </div>
</template>

<script>
export default {
  name: "TextareaComponent",
  props: {
    name: { default: "" },
    id: { default: "" },
    value: String,
    textDefault: String,
    rows: {
      default: 3
    }
  },
  data() {
    return {
      inputValue: ""
    };
  },
  watch: {
    value(val) {
      this.inputValue = val;
    }
  },
  methods: {
    onInput(e) {
      this.inputValue = e.target.value;
      this.$emit("input", e.target.value);
    }
  }
};
</script>

<style lang="scss">
.textarea-container {
  display: grid;
  align-content: center;
  border-radius: 5px;
  border: 1px solid $border-grey-dark;
  background: $bg-main;
  padding: 0px 4px 0 14px;
  padding-bottom: 4px;
  &.error {
    border: 1px solid $color-error;
    textarea {
      color: $color-error;
    }
  }
  display: grid;

  .form-control {
    transform: translateY(0);
  }
  .form-control:focus,
  .form-control.filled {
    transform: translateY(0);
    opacity: 1;
  }

  .c-textarea {
    min-height: 20px;
    padding: 0;
    padding-top: 24px;
    resize: vertical !important;
    border: 0;
    height: 49px;
    width: 100%;
    background: transparent !important;
    color: $text-main;
    font-size: 15px;
    align-self: flex-end;
    outline: none;
    &::placeholder {
      color: $text-inactive;
      opacity: 1;
    }
    position: relative;
  }
  .form-control-placeholder {
    pointer-events: none;
    @media all and (max-width: $md) {
      top: 15px;
    }

    position: absolute;
    top: 12px;
    padding: 2px 0 0 18px;
    transition: all 200ms;
    opacity: 1;
    color: $text-inactive;
    @extend %text-default;
    transform-origin: left top;
  }

  .form-control:focus + .form-control-placeholder,
  .form-control.filled + .form-control-placeholder {
    transform: translateY(-40%) scale(0.75);
    opacity: 1;
  }
}
</style>
