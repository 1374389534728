var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"button-component",class:[
    'default',
    {
      mini: _vm.isMini,
      inverted: _vm.isInverted,
      'style-transparent': _vm.isTransparent,
      'style-deactive': _vm.isDeactive,
      'style-mini-border-none': _vm.isArrowUp
    }
  ],attrs:{"tag":"a","to":_vm.url === undefined ? '' : _vm.url,"href":_vm.url}},[(_vm.isMini)?_c('div',{class:[
      'icon',
      {
        'arrow-up': _vm.isArrowUp,
        'arrow-right': _vm.isArrowRight,
        'arrow-left': _vm.isArrowLeft,
        white: _vm.isWhite,
        deactive: _vm.isDeactive
      }
    ]}):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }