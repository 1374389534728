<template>
  <div class="contacts-dialog">
    <ModalDialog ref="dialog" @hide="onHide">
      <div class="contacts-container" v-if="data && form">
        <div class="area">
          <div class="row">
            <h2 v-html="data.title" class="col-4 col-md-12"></h2>
            <form v-if="!formSent" class="form-callback col-7 col-md-12">
              <div class="row">
                <p
                  class="error col-12 col-md-12"
                  v-if="error"
                  v-html="error"
                ></p>
                <InputComponent
                  class="col-6 col-md-12"
                  id="name"
                  name="name"
                  :text-default="form.name"
                  v-model="name"
                  @input="onInputName"
                  :valid="name !== ''"
                  :class="[{ error: errorName }]"
                />
                <div class="col-6 col-md-hidden"></div>
                <InputComponent
                  class="col-6 col-md-12"
                  id="phone"
                  name="phone"
                  :text-default="form.phone"
                  @input="onInputPhone"
                  v-model="phone"
                  :valid="phone !== ''"
                  :class="[{ error: errorPhone }]"
                />
                <InputComponent
                  class="col-6 col-md-12"
                  id="email"
                  name="email"
                  :text-default="form.email"
                  @input="onInputEmail"
                  v-model="email"
                  :valid="email !== ''"
                  :class="[{ error: errorEmail }]"
                />
                <TextareaComponent
                  class="col-12 col-md-12"
                  id="name"
                  name="name"
                  :text-default="form.message"
                  v-model="message"
                  :valid="message !== ''"
                  :class="[{ error: errorMessage }]"
                />
                <input
                  type="checkbox"
                  class="checkbox-input"
                  style="display:none"
                  id="agree-contacts"
                  checked="true"
                  @change="onChangeCheckbox"
                  @input="onChangeCheckbox"
                />
                <label
                  class="checkbox-label col-8 col-md-12"
                  for="agree-contacts"
                >
                  <span v-html="form.agreement"></span>
                </label>
                <ButtonComponent
                  class="col-4 col-md-12"
                  id="button-callback"
                  :is-deactive="buttonDisabled"
                  @click.native="onClickButton"
                  >{{ form.button }}</ButtonComponent
                >
              </div>
            </form>
            <div v-else class="form-sent">
              <i class="icon-phone"></i>
              <span v-html="form.sent.desc"></span>
            </div>
            <Contacts
              class="contacts col-4 col-md-12"
              :place="data.address"
              :schedule="data.schedule"
              :phone="data.phone"
              :email="data.email"
            />
          </div>
        </div>
      </div>
      <div class="map">
        <yandex-map
          :coords="[55.76823, 37.649814]"
          zoom="10"
          style="width: 100%; height: 450px;"
          :cluster-options="{
            1: { clusterDisableClickZoom: true }
          }"
          :behaviors="['drag']"
          :controls="[]"
          @map-was-initialized="initHandler"
        >
          <ymap-marker
            marker-id="1"
            marker-type="placemark"
            :coords="[55.846113, 37.539105]"
            hint-content="125438, Москва,  ул. Автомоторная, д. 1/3, стр. 2, 6 этаж"
            :balloon="{
              header: 'Квадрант Инвест',
              body: '125438, Москва, ул. Автомоторная, д. 1/3, стр. 2, 6 этаж'
            }"
            :icon="icon"
            cluster-name="1"
          ></ymap-marker>
        </yandex-map>
      </div>
    </ModalDialog>
  </div>
</template>

<script>
import ModalDialog from "../components/ModalDialog.vue";
import Contacts from "../components/Contacts.vue";

import AppData from "../AppData";
import utils from "../utils";
export default {
  name: "ContactsDialog",
  data() {
    return {
      data: undefined,
      form: undefined,
      utils: utils,
      formSent: false,
      errorEmail: false,
      errorPhone: false,
      errorName: false,
      errorMessage: false,
      name: "",
      email: "",
      phone: "",
      message: "",
      error: "",
      buttonDisabled: false,
      icon: {
        layout: "default#image",
        imageHref: AppData.placemark,
        imageSize: [51, 51],
        imageOffset: [-15, -25]
      }
    };
  },
  methods: {
    initHandler() {},
    onChangeCheckbox(e) {
      this.buttonDisabled = !e.target.checked;
    },
    onInputPhone() {
      this.errorPhone = false;
    },
    onInputName() {
      this.errorName = false;
    },
    onInputEmail() {
      this.errorEmail = false;
    },
    onInputMessage() {
      this.errorMessage = false;
    },
    show() {
      this.$refs.dialog.show();
      this.$router.push(this.$route.path + "#contacts");
    },
    hide() {
      this.$refs.dialog.hide();
    },
    onHide() {
      this.$router.push(this.$route.path.replace("#contacts", ""));
    },
    onClickButton() {
      this.errorMessage = this.message.length < 2;
      this.errorName = this.name.length < 2;
      this.errorPhone =
        this.phone.indexOf("_") !== -1 || this.phone.length === 0;
      this.errorEmail = !utils.validateEmail(this.email);

      if (
        !this.errorPhone &&
        !this.errorField &&
        !this.errorMessage &&
        !this.errorEmail &&
        !this.requestSended
      ) {
        this.requestSended = true;
        AppData.sendMail(this.name, this.email, this.phone, this.message)
          .then(() => {
            this.requestSended = false;
            this.name = "";
            this.email = "";
            this.phone = "";
            this.message = "";
            this.$refs.dialog.hide();
          })
          .catch(() => {
            this.requestSended = false;
            this.error = "Возникла ошибка при отправке письма, попробуйте еще";
          });
      }
    }
  },
  mounted() {
    AppData.fetchData("contacts").then(result => {
      this.data = result.info;
      this.form = result.info.form;
    });
    if (this.$route.hash === "#contacts") {
      this.show();
    }
  },
  components: {
    ModalDialog,
    Contacts
  }
};
</script>

<style lang="scss">
.contacts-dialog {
  .contacts-container {
    @extend %page-block;
    .row {
      grid-row-gap: 28px;
      align-content: flex-start;
      align-items: flex-start;
      grid-template-rows: max-content 1fr;
    }
    .button-description {
      width: 186px;
    }
  }
  .banner-header .description .more {
    @media all and (max-width: $md) {
      margin-bottom: 25px;
      margin-top: 20px;
    }
  }
  .banner {
    .img-banner {
      @media all and (max-width: $md) {
        object-fit: cover;
        max-height: 180px;
        max-width: auto;
        height: 180px;
        transform: translateX(10%);
      }
      @media all and (max-width: $sm) {
        transform: translateX(10%);
      }
    }
  }
  .contacts-component {
    margin-top: 20px;
  }
  .form-callback {
    @media all and (max-width: $md) {
      grid-column: span 12;
      grid-row: unset;
    }
    margin-top: 0;
    grid-column: 5 / span 7;
    grid-row: 1 / span 2;
    display: grid;
    grid-gap: 14px;
    .checkbox-label {
      color: $text-inactive;
    }
    .row {
      @media all and (max-width: $md) {
        grid-row-gap: 18px;
      }
      grid-row-gap: 28px;
    }
  }
  #button-callback {
    @media all and (max-width: $md) {
      justify-self: flex-start;
    }
    width: 154px;
    justify-self: flex-end;
  }
  .recal {
    margin-top: 60px;
  }
  h2 {
    margin: 0px;
    .base-label {
      font-size: inherit;
      &::before {
        font-size: 18px;
        top: -2px;
      }
    }
  }
  .place {
    font-weight: bold;
  }
  .contacts {
    align-self: flex-start;
  }
}
</style>
