var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"services-container"},[_c('div',{staticClass:"area"},[_c('div',{directives:[{name:"animated-show",rawName:"v-animated-show",value:(_vm.utils.handleScroll),expression:"utils.handleScroll"}],staticClass:"row"},[(_vm.title)?_c('h2',{staticClass:"col-12",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_c('div',{staticClass:"nav-arrows col-4 col-md-4"},[_c('a',{staticClass:"nav-arrow nav-arrow--prev",attrs:{"href":"/"},on:{"click":function($event){$event.preventDefault();return (function () {
              _vm.next(-1);
            })($event)}}}),_c('a',{staticClass:"nav-arrow nav-arrow--next",attrs:{"href":"/"},on:{"click":function($event){$event.preventDefault();return (function () {
              _vm.next(1);
            })($event)}}})]),_c('Slide',{ref:"slide",staticClass:"col-12",attrs:{"mobile-fixed":true,"item-width":465,"pages-count":_vm.list.length},on:{"ondrag":_vm.onDrag}},[_c('div',{staticClass:"container-slidable"},_vm._l((_vm.list),function(l,i){return _c('a',{key:i,staticClass:"link inverse list list-section-baner",style:({
              'background-color': l.bgColor,
              'background-position': l.bgPosition,
              'background-image': ("url(" + (l.img) + ")")
            }),attrs:{"tag":"a","href":l.href},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.navigateTo(l.href); })($event)}}},[_c('h3',{staticClass:"title",domProps:{"innerHTML":_vm._s(l.title)}}),_c('div',[_c('span',{staticClass:"more link inverse",domProps:{"innerHTML":_vm._s(l.button)}})])])}),0)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }