<template>
  <div class="main">
    <BasicTemplate :page-id="0">
      <Banner v-if="data" :list="data.banner.list" />
      <Services
        v-if="data"
        :title="data.services.title"
        :list="data.services.list"
      />
      <Events v-if="data" :title="data.events.title" :list="data.events.list" />
    </BasicTemplate>
  </div>
</template>

<script>
import BasicTemplate from "./BasicTemplate.vue";
import Banner from "../containers/Banner.vue";
import Events from "../containers/Events.vue";
import Services from "../containers/Services.vue";

import AppData from "../AppData";
import utils from "../utils";
export default {
  name: "Home",
  data() {
    return {
      data: undefined,
      utils: utils,
      metaTitle: undefined,
      metaDescription: undefined,
      metaKeywords: undefined
    };
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "yandex-verification", content: "c6c7a4772feb5174" },
        { name: "title", property: "title", content: this.metaTitle },
        {
          name: "description",
          property: "description",
          content: this.metaDescription
        },
        {
          name: "keywords",
          property: "keywords",
          content: this.metaKeywords
        }
      ]
    };
  },

  methods: {},
  mounted() {
    AppData.fetchData("main").then(data => {
      this.data = data;
      this.metaTitle = data.meta.title;
      this.metaDescription = data.meta.description;
      this.metaKeywords = data.meta.keywords;

      setTimeout(() => {
        document.dispatchEvent(new Event("x-app-rendered"));
      }, 5000);
    });
  },
  components: {
    BasicTemplate,
    Banner,
    Events,
    Services
  }
};
</script>

<style lang="scss">
.main {
  .banner-header .description .more {
    @media all and (max-width: $md) {
      margin-bottom: 25px;
      margin-top: 20px;
    }
  }
  .banner {
    .list-section-baner:nth-child(3) {
      .img-banner {
        @media all and (max-width: $md) {
          object-fit: cover;
          max-height: 180px;
          max-width: auto;
          height: 180px;
          transform: translateX(10%);
        }
        @media all and (max-width: $sm) {
          transform: translateX(20%);
        }
      }
    }
  }
}
</style>
