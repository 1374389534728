<template>
  <div
    class="modal-container"
    v-if="data && footer"
    v-show="isVisible"
    :class="[{ mini: isMini }]"
  >
    <div class="hider" ref="hider" @click.prevent="hide"></div>
    <div class="modal-wrapper" ref="modalWrapper">
      <div class="modal-content-container">
        <a
          class="nav-arrow nav-arrow--close"
          href="/"
          @click.prevent="
            () => {
              hide();
            }
          "
        ></a>
        <Header
          v-if="!isMini"
          :type-effect="true"
          class="main"
          :is-main="true"
          :logo="data.logo"
          :is-mini="true"
        />
        <div class="modal-content"><slot></slot></div>
        <Footer
          v-if="!isMini"
          @scrolltotop="onScrollToTop"
          class="mini"
          :desc="footer.info.desc"
          :policy="footer.info.policy"
          :madein="footer.info.madein"
          :menu="footer.menu.list"
          :contacts="footer.contacts"
          :is-mini="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../containers/Header.vue";
import Footer from "../containers/Footer";
import AppData from "../AppData";
import utils from "../utils";

export default {
  name: "ModalDialog",
  props: {
    isMini: Boolean
  },
  data() {
    return {
      isVisible: false,
      data: undefined,
      footer: undefined
    };
  },
  methods: {
    onScrollToTop() {
      utils.scrollElementToTop(this.$refs.modalWrapper);
    },
    show() {
      this.isVisible = true;
      if (!this.isMini) {
        document.body.classList.add("stop-scrolling");
        var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
        root.setAttribute("class", "stop-scrolling");
      }
    },
    hide() {
      this.isVisible = false;
      if (!this.isMini) {
        document.body.classList.remove("stop-scrolling");
        var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
        root.setAttribute("class", "");
      }
      this.$emit("hide");
    }
  },
  created() {
    AppData.fetchData("main").then(result => {
      this.data = result;
    });
    AppData.fetchData("footer").then(result => {
      this.footer = result;
    });

    document.onkeydown = evt => {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc";
      } else {
        isEscape = evt.keyCode === 27;
      }
      if (isEscape) {
        this.hide();
      }
    };
  },
  components: {
    Header,
    Footer
  }
};
</script>

<style lang="scss">
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  background: #fff;
  min-height: 100vh;
  height: 100vh;
  .App-header {
    background: $bg-green2-half;
  }
  .modal-wrapper {
    height: 100vh;
    overflow-y: scroll;
    width: 100%;
    overflow-x: hidden;
    .modal-content-container {
      min-height: 100vh;
      position: relative;
      display: grid;
      grid-template-rows: auto 1fr auto;
      .modal-content {
        background: $bg-green2-half;
      }
    }
  }
  .nav-arrow {
    @media all and (max-width: $lg - 1) {
      right: 28px;
    }
    @media all and (max-width: $md) {
      right: 14px;
    }
    @extend %action-shadow;
    position: absolute;
    right: calc((100vw - 1088px) / 2 - 28px);
    top: 42px;
    width: 28px;
    height: 28px;
    padding: 0;
    background: $bg-main;
    cursor: pointer;
    display: grid;
    text-decoration: none;
    justify-content: center;
    align-content: center;
    &::before {
      font-family: fontello;
      font-weight: 300;
    }
    &.nav-arrow--close {
      &::before {
        content: "\e800";
        color: $color-accent;
        transition: transform 0.5s;
        transform: rotate(0deg);
      }
      &:hover {
        &::before {
          transition: transform 0.5s;
          transform: rotate(360deg);
        }
      }
    }
    &:hover {
      transition: background-color 0.5s transform 0.5s;
      background: $color-accent;
      &::before {
        transition: color 0.5s;
        color: $text-inverse-main;
      }
    }
  }
  &.mini {
    background: #000000d0;
    display: grid;
    .modal-wrapper {
      display: grid;
      align-items: center;
      align-content: center;
      justify-items: center;
      justify-content: center;
      overflow-x: hidden;
      overflow-y: hidden;
      .modal-content-container {
        width: auto;
        min-height: auto;
        padding: 0;
        background: transparent;
        .modal-content {
          background: $bg-main;
        }
      }
    }

    .nav-arrow {
      z-index: 1000;
      right: 28px;
      top: 32px;
    }
  }
  .hider {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
