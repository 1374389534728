<template>
  <div
    class="contacts-component h-card"
    itemscope
    itemtype="http://schema.org/Organization"
  >
    <span itemprop="name" style="display:none;">Квадрант Инвест</span>
    <span itemprop="url" style="display:none;">www.kvadrant-invest.ru</span>
    <strong v-if="title" v-html="title" class="title"></strong>
    <p
      itemprop="address"
      itemscope
      itemtype="http://schema.org/PostalAddress"
      class="place p-street-address"
      v-if="place"
      v-html="place"
    ></p>
    <p class="schedule" v-if="schedule" v-html="schedule"></p>
    <p class="phone" v-if="phone">
      <strong
        class="p-tel"
        v-for="(l, i) in phone"
        :key="i"
        itemprop="telephone"
        ><a
          class="phone-link"
          :class="[{ inverse: isInverse }]"
          :href="utils.getPhoneLink(l)"
          >{{ l }}</a
        ></strong
      >
    </p>
    <p class="email u-email" v-if="email" v-html="email"></p>
  </div>
</template>

<script>
import utils from "../utils";
export default {
  name: "Contacts",
  props: {
    theme: {
      default: "default"
    },
    title: String,
    place: String,
    metro: String,
    schedule: String,
    phone: Array,
    email: String,
    isInverse: Boolean
  },
  data() {
    return {
      utils: utils
    };
  }
};
</script>

<style lang="scss">
.contacts-component {
  grid-gap: 25px;
  display: grid;
  align-content: flex-start;
  .email {
    margin-top: 0;
  }
  p {
    @extend %text-default;
    font-size: 15px;
    margin: 0;
  }
  a {
    color: $text-main;
    border-color: transparent;
    font-weight: bold;
    &:hover {
      border-color: $text-main;
    }
  }
  .phone {
    display: grid;
  }
  &.inverse {
    .title {
      color: $text-inverse-main;
    }
    .phone {
      color: $text-inverse-main;
    }
    .email {
      a {
        border-color: transparent;
        color: $text-inverse-main-half;
      }
      margin-top: 0;
    }
  }
}
</style>
