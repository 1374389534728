<template>
  <div class="expandable-list">
    <ul class="expandable" v-if="list">
      <li
        v-for="(item, index) in list"
        :key="index"
        :class="[{ opened: current === index }, { closed: current !== index }]"
      >
        <div
          class="title"
          @click.prevent="
            () => {
              onExpand(index);
            }
          "
        >
          <span class="title-span" v-html="item.title"></span>
          <a
            class="button"
            href="/"
            @click.prevent="
              () => {
                onExpand(index);
              }
            "
          >
            <span class="horizontal"></span>
            <span class="vertical"></span>
          </a>
        </div>
        <div :class="['desc']"><div v-html="item.desc"></div></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ExpandableList",
  props: {
    list: Array
  },
  data() {
    return {
      current: 0
    };
  },
  methods: {
    onExpand(index) {
      this.current = index;
    }
  }
};
</script>

<style lang="scss">
.expandable-list {
  display: grid;
  overflow: hidden;
  @media all and (max-width: $md) {
    margin: 0 -14px;
  }
  .title {
    cursor: pointer;
    @media all and (max-width: $md) {
      padding: 21px 14px;
    }
    transition: all 0.5s ease-in-out;
    position: relative;
    margin-left: 28px;
    padding: 21px 28px;
    background: $bg-grey-light;
    @extend %text-h4;
    .title-span {
      padding-right: 60px;
    }
  }
  .desc {
    @media all and (max-width: $md) {
      padding: 0 14px;
    }
    transition: all 0.5s ease-in-out;
    margin-left: 28px;
    @extend %text-default;
    overflow: hidden;
    padding: 0 28px 0 28px;
    opacity: 0;
    max-height: 1px;
  }
  .button {
    position: absolute;
    right: 20px;
    top: 16px;
    display: block;
    width: 28px;
    height: 28px;
    background: $bg-grey-light;
    display: grid;
    justify-content: center;
    align-content: center;
    @extend %action-shadow;
    border-radius: 50%;
    span {
      display: block;
      width: 12px;
      height: 1px;
      background: $text-inactive;
      &.vertical {
        position: relative;
        top: -1px;
        transform: scale(1) rotate(90deg);
      }
    }
  }
  li.closed {
    .desc {
      max-height: 0px;
    }
  }
  li.opened {
    .title {
      background: $bg-main;
    }
    .desc {
      @media all and (max-width: $md) {
        padding: 0 28px 0 14px;
      }
      max-height: 500px;
      opacity: 1;
      padding: 0 28px 0 28px;
    }
    .button {
      span {
        &.vertical {
          transform: scale(0) rotate(90deg);
        }
      }
    }
  }
  ul.expandable {
    @media all and (max-width: $md) {
      grid-gap: 21px;
    }
    margin-left: -28px;
    grid-gap: 21px;
  }
  @media all and (max-width: $md) {
    grid-gap: 21px;
  }
  grid-gap: 21px;
}
</style>
