<template>
  <div
    class="App-header"
    :class="[`theme-${theme}`]"
    v-if="navigation && contacts"
  >
    <div class="area">
      <div class="row">
        <router-link to="/" class="logo-header"></router-link>
        <div class="nav-container" v-if="!isMini && navigation">
          <Nav
            @contacts="showContacts"
            class="nav"
            :menu="navigation"
            :page-id="pageId"
          />
        </div>
        <div class="contacts" v-if="!isMini">
          <BaseLabel icon-class="icon-phone-head" icon-size="12px">
            <a class="phone-link" :href="utils.getPhoneLink(contacts.phone)">{{
              contacts.phone
            }}</a>
          </BaseLabel>
          <ButtonComponent @click.native="showContacts"
            >Контакты</ButtonComponent
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../components/Nav";
import utils from "../utils";
import firebase from "../firebase";
import AppData from "../AppData";
export default {
  name: "Header",
  props: {
    pageId: Number,
    isMain: Boolean,
    isMini: Boolean,
    nav: Array,
    theme: {
      default: "default"
    },
    isUnderline: {
      default: true
    },
    title: String,
    list: Array,
    button: {
      default: "Подробнее",
      type: String
    },
    typeEffect: Boolean,
    titleTyper: Array || String
  },
  data() {
    return {
      utils: utils,
      firebase: firebase,
      navigation: undefined,
      contacts: undefined,
      data: {},
      created: {}
    };
  },

  methods: {
    showContacts() {
      this.$emit("contacts");
    }
  },
  mounted() {
    const db = firebase.firestore();
    const settings = {};
    db.settings(settings);
    AppData.fetchData("navigation").then(result => {
      this.navigation = result.menu.list;
      this.contacts = result.contacts;
    });
  },
  components: {
    Nav
  }
};
</script>

<style lang="scss">
.App-header {
  overflow: hidden;
  background: $bg-main;
  display: grid;
  .logo-header {
    @media all and (max-width: $md) {
      grid-column: unset;
      margin-left: 0;
      margin-top: 27px;
      grid-column: 1 / span 8;
      grid-row: 1;
    }
    grid-column: 1 / span 2;
    margin: 25px;
    margin-left: 0;
    display: block;
    width: 142px;
    height: 54px;
    background: url("../assets/icons/logo-head.svg") center / contain no-repeat;
  }
  .nav-container {
    @media all and (max-width: $md) {
      grid-column: 1 / span 3;
      grid-row: 2;
      grid-template-columns: unset;
      overflow: unset;
      margin-top: -20px;
    }
    overflow: hidden;
    grid-column: 3 / span 10;
    grid-row: 1;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    .nav {
      @media all and (max-width: $md) {
        grid-column: unset;
      }
      grid-column: span 8;
      @extend %layout-default-two-thirds;
    }
  }
  .contacts {
    @media all and (max-width: $md) {
      grid-column: 5 / span 8;
      grid-row: 1;
      grid-template-rows: unset;
    }
    z-index: 1;
    grid-column: 11 / span 2;
    display: grid;
    grid-gap: 0;
    grid-template-rows: 53px 58px;
    align-items: center;
    grid-row: 1;
    justify-content: flex-end;
    justify-items: flex-end;
    .base-label {
      @extend %text-small;
      @media all and (max-width: $md) {
        align-self: center;
        grid-row: 1 / span 2;
      }
    }
    .icon-phone-head::before {
      color: $color-accent;
    }
    .button-component {
      @media all and (max-width: $md) {
        display: none;
      }
      height: 33px;
      width: 100px;
    }
  }
  .title-header {
    @media all and (max-width: $md) {
      font-size: 21px;
      grid-column: unset;
    }
    margin-top: 25px;

    grid-column: 2 / span 5;
    grid-row: 2;

    font-size: 36px;
    font-family: Axiforma-Regular;
    font-variant-caps: normal;
    line-height: 1.4em;
    color: #243141;

    strong {
      font-family: Axiforma-ExtraBold;
    }
  }

  .banner-header {
    @media all and (max-width: $lg) {
      width: 100%;
    }
    @media all and (max-width: $md) {
      grid-column: 1;
      width: 100%;
      height: 252px;
      margin-left: -14px;
      margin-right: -14px;
      margin-top: 35px;
      margin-bottom: 0;
      box-shadow: 0 0 40px 0px #00000020;
    }
    margin-top: 50px;
    margin-bottom: 100px;

    width: 776px;
    height: 294px;

    grid-column: 2 / span 8;
    grid-row: 3;

    background: #243141;
  }
  &.theme-full {
    @media all and (max-width: $lg) {
      grid-template-columns: 100px repeat(12, 1fr) 100px;
    }
    @media all and (max-width: $md) {
      grid-template-columns: 1fr;
      padding: 0 14px;
    }
    display: grid;
    grid-template-columns: 100px repeat(8, 1fr) 350px;
    grid-template-rows: auto;
    .logo-header {
      @media all and (max-width: $md) {
        grid-row: 1;
        grid-column: 1;
      }
      grid-row: 1;
      z-index: 2;
      background: #fff;
    }
    .banner-header {
      @media all and (max-width: $lg) {
        grid-column: 1 / span 14;
      }
      @media all and (max-width: $md) {
        height: auto;
        grid-template-columns: 1fr;
      }
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      height: 435px;
      grid-column: 1 / span 9;
      grid-row: 1 / span 2;
    }
  }
  &.theme-mini {
    background: #fff;
  }
}

.title-incompleted {
  .custom.caret {
    background: #243141 !important;
  }
}
</style>
