var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"slideBlock",staticClass:"slide-block",class:[
    {
      'is-horisontal': _vm.isHorizontal,
      'desktop-fixed': _vm.desktopFixed,
      'mobile-fixed': _vm.mobileFixed,
      centered: _vm.isCentered
    }
  ]},[_c('v-touch',{attrs:{"pan-options":{ direction: 'horizontal', threshold: 40 }},on:{"panstart":_vm.movedHandler,"panmove":_vm.movingHandler,"panend":_vm.endHandler}},[_c('div',{ref:"slidable",staticClass:"slidable",class:[
        {
          'is-touching': _vm.isTouching,
          'desktop-fixed': _vm.desktopFixed,
          'mobile-fixed': _vm.mobileFixed
        }
      ],style:({
        transform: _vm.transform,
        '--transition-duration': _vm.transitionDuration
      })},[_vm._t("default")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }