<template>
  <div class="feedback-container">
    <div class="area">
      <div class="row" v-animated-show="utils.handleScroll">
        <div class="reason col-4 col-md-12">
          <h2 v-html="title"></h2>
          <ul class="checked">
            <li v-for="(l, i) in cons" :key="i" v-html="l"></li>
          </ul>
        </div>
        <section
          class="feedback-item col-6 col-md-12"
          v-for="(l, i) in list"
          :key="i"
          :class="[{ current: current === i }]"
        >
          <div class="row">
            <img :src="l.avatar" class="col-4 col-md-4" />
            <div class="user-info col-4 col-md-8">
              <span class="name" v-html="l.user"></span>
              <br />
              <span class="company" v-html="l.company"></span>
            </div>
            <div class="nav-arrows col-4 col-md-4">
              <a
                class="nav-arrow nav-arrow--prev"
                href="/"
                @click.prevent="
                  () => {
                    nextFeedback(-1);
                  }
                "
              ></a>
              <a
                class="nav-arrow nav-arrow--next"
                href="/"
                @click.prevent="
                  () => {
                    nextFeedback(1);
                  }
                "
              ></a>
            </div>
            <p class="feedback col-8" v-html="l.desc"></p>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../utils";
export default {
  name: "Feedback",
  props: {
    theme: {
      default: "default"
    },
    title: String,
    list: Array,
    cons: Array
  },
  data() {
    return {
      utils: utils,
      current: 0
    };
  },
  methods: {
    nextFeedback(offset) {
      this.current =
        (this.current + offset + this.list.length) % this.list.length;
    }
  },
  components: {}
};
</script>

<style lang="scss">
.feedback-container {
  @extend %page-block;
  background: $bg-blue;
  .row {
    grid-row-gap: 28px;
    .button-description {
      width: 214px;
      margin-top: 28px;
    }
    .reason {
      @media all and (max-width: $md) {
        grid-row: unset;
        grid-column: span 12;
      }
      grid-row: 1;
      grid-column: 1 / span 4;
      display: grid;
      grid-gap: 38px;
    }
  }
  .feedback-item {
    @extend %switch-anim;
    @media all and (max-width: $md) {
      grid-row: 2;
      grid-column: 1 / span 12;
    }
    grid-row: 1;
    grid-column: 7 / span 6;
    align-self: center;
    align-content: flex-start;
    grid-gap: 20px;
    display: grid;
    &.current {
      position: relative;
      z-index: 2;
    }
    > .row {
      grid-template-columns: 86px 1fr;
      align-items: flex-start;
      align-content: flex-start;
      grid-template-rows: 93px max-content;
    }
    img {
      @media all and (max-width: $md) {
        grid-column: 1;
        grid-row: unset;
      }
      grid-column: 1 / span 4;
      grid-row: 1;
      width: 93px;
      height: 93px;
      object-fit: cover;
    }
    .user-info {
      @media all and (max-width: $md) {
        grid-row: 1 / span 2;
        grid-column: 2;
      }
      grid-row: 2;
      grid-column: 1 / span 4;
      .company {
        @extend %text-small;
        color: $text-inactive;
      }
    }
    .nav-arrows {
      @media all and (max-width: $md) {
        grid-column: 1;
        grid-row: 2;
        align-self: flex-start;
      }
      grid-row: 3;
      grid-column: 1 / span 4;
      align-self: flex-start;
      display: grid;
      grid-template-columns: repeat(2, 28px);
      grid-gap: 24px;
    }
    p.feedback {
      @media all and (max-width: $md) {
        grid-row: 1 / span 2;
        grid-column: 2;
        margin-top: 60px;
        padding-right: 28px;
      }
      grid-row: 1 / span 3;
      grid-column: 5 / span 7;
      @extend %text-default;
      color: $text-main;
      line-height: 1.4em;
      font-weight: normal;
      margin: 0;
    }
  }
}
</style>
