var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['base-input-container', { error: _vm.errorLabel }],style:({
    '--base-height': _vm.inputHeight,
    '--base-margin': _vm.topMargin,
    '--base-bg-color': _vm.bgColor
  }),on:{"click":_vm.onInputClick}},[(_vm.inputLabel && _vm.inputType !== 'checkbox')?_c('h3',{staticClass:"input-label"},[_vm._v("\n    "+_vm._s(_vm.inputLabel)+"\n  ")]):_vm._e(),_c('div',{class:['flex-wrap', { 'base-checkbox': _vm.inputType === 'checkbox' }]},[_c('div',{class:['from-container']},[(_vm.inputPrefix)?_c('span',{staticClass:"input-prefix"},[_vm._v("\n        "+_vm._s(_vm.inputPrefix)+"\n      ")]):_vm._e(),(!_vm.isArea)?_c('input',{ref:"inputField",class:[
          'base-input',
          {
            'checkbox-label': _vm.type === 'checkbox',
            'input-with-prefix': _vm.inputPrefix,
            'input-with-clear': _vm.isClearVisible && _vm.isNeedShowClear
          }
        ],attrs:{"disabled":_vm.disabled,"id":_vm.inputId,"placeholder":_vm.inputPlaceholder,"type":_vm.showPassword ? 'text' : _vm.inputType,"min":_vm.min,"max":_vm.max,"autocomplete":_vm.autocomplete,"name":_vm.inputName},domProps:{"value":_vm.inputType == 'phone'
            ? _vm.inputValue
              ? _vm.inputValue
              : _vm.phonePattern
            : _vm.inputValue,"checked":_vm.isChecked},on:{"focus":_vm.OnInputFocus,"blur":_vm.OnInputhBlue,"input":_vm.onInput,"change":_vm.onChange}}):_vm._e(),(_vm.inputType === 'checkbox')?_c('label',{class:['checkbox-label', 'base-input-label'],attrs:{"for":_vm.inputId}},[_vm._v(_vm._s(_vm.inputLabel)+"\n      ")]):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isClearVisible && _vm.isNeedShowClear),expression:"isClearVisible && isNeedShowClear"}],staticClass:"input-reset icon-cancel",on:{"click":_vm.clearInputField}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.inputType === 'password'),expression:"inputType === 'password'"}],staticClass:"input-reset icon-password-show",on:{"click":_vm.changeType}}),(_vm.inputIcon)?_c('span',{class:['input-right-icon', _vm.inputIcon]}):_vm._e(),(_vm.required)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e(),(_vm.isArea)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"base-input-area",attrs:{"placeholder":_vm.inputPlaceholder},domProps:{"value":(_vm.inputValue)},on:{"change":_vm.onChange,"input":[function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value},_vm.onTextareaInput]}}):_vm._e()]),(_vm.buttonTitle)?_c('ButtonComponent',{attrs:{"on-click":_vm.onInputButtonClick}},[_vm._v(_vm._s(_vm.buttonTitle))]):_vm._e()],1),_c('h4',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorLabel),expression:"errorLabel"}],staticClass:"error-label"},[_vm._v(_vm._s(_vm.errorLabel))])])}
var staticRenderFns = []

export { render, staticRenderFns }