<template>
  <div class="services-container">
    <div class="area">
      <div class="row" v-animated-show="utils.handleScroll">
        <h2 v-if="title" v-html="title" class="col-12"></h2>
        <div class="nav-arrows col-4 col-md-4">
          <a
            class="nav-arrow nav-arrow--prev"
            href="/"
            @click.prevent="
              () => {
                next(-1);
              }
            "
          ></a>
          <a
            class="nav-arrow nav-arrow--next"
            href="/"
            @click.prevent="
              () => {
                next(1);
              }
            "
          ></a>
        </div>
        <Slide
          class="col-12"
          :mobile-fixed="true"
          :item-width="465"
          :pages-count="list.length"
          ref="slide"
          @ondrag="onDrag"
        >
          <div class="container-slidable">
            <a
              tag="a"
              class="link inverse list list-section-baner"
              :href="l.href"
              v-for="(l, i) in list"
              :key="i"
              @click.prevent="() => navigateTo(l.href)"
              :style="{
                'background-color': l.bgColor,
                'background-position': l.bgPosition,
                'background-image': `url(${l.img})`
              }"
            >
              <h3 class="title" v-html="l.title"></h3>

              <div>
                <span class="more link inverse" v-html="l.button"></span>
              </div>
            </a>
          </div>
        </Slide>
      </div>
    </div>
  </div>
</template>

<script>
import Slide from "../components/Slide.vue";
import utils from "../utils";
export default {
  name: "Services",
  props: {
    theme: {
      default: "default"
    },
    list: Array,
    title: String
  },
  data() {
    return { dragged: false, utils: utils };
  },
  methods: {
    next(offset) {
      this.$refs.slide.setPage(this.$refs.slide.currentPage + offset);
    },
    onDrag() {
      this.dragged = true;
    },
    navigateTo(href) {
      if (!this.dragged) {
        this.$router.push({ path: href });
      }
      this.dragged = false;
    }
  },
  components: {
    Slide
  }
};
</script>

<style lang="scss">
.services-container {
  overflow: hidden;
  @extend %page-block;
  .row {
    grid-row-gap: 52px;
  }
  h2 {
    grid-row: 1;
    margin: 0;
    grid-column: 1 / span 8;
  }
  .menu-container {
    margin-top: -20px;
  }
  .nav-arrows {
    @media all and (max-width: $md) {
      display: none;
    }
    grid-row: 1;
    grid-column: 9 / span 4;
    align-self: flex-start;
    display: grid;
    justify-content: flex-end;
    justify-items: flex-end;
    grid-template-columns: repeat(2, 28px);
    grid-gap: 24px;
  }
  .container-slidable {
    @media all and (max-width: $md) {
      grid-template-columns: minmax(0, 1fr);
      grid-auto-flow: row;
      grid-template-rows: unset;
      grid-auto-rows: 168px;
    }
    display: grid;
    grid-template-columns: repeat(3, 465px);
    grid-gap: 13px 64px;
    grid-template-rows: 196px;
    user-select: none;
  }

  .list-section-baner {
    @media all and (max-width: $md) {
      align-items: flex-start;
      background-size: auto 178px;
      padding-top: 42px;
      padding-left: 32px;
      grid-gap: 28px;
    }
    &,
    &::after,
    &::before {
      -webkit-user-select: none;
      -webkit-user-drag: none;
      -webkit-app-region: no-drag;
    }
    cursor: pointer;
    border: 0 !important;
    padding-bottom: 0;
    @extend %float-shadow;
    background-position: right -10% bottom;
    background-repeat: no-repeat;
    background-size: auto 184px;
    position: relative;
    display: grid;
    align-content: flex-start;
    transition: background-color 0.2s;
    padding: 50px 42px 0 42px;
    grid-gap: 46px;
    border-radius: 15px;
    .more {
      border-bottom: 1px solid transparent;
      transition: border-color 0.5s;
    }
    &:hover {
      .more {
        border-bottom: 1px solid $text-inverse-main;
      }
    }
    h3,
    a {
      margin: 0;
      color: $text-inverse-main;
      border-color: transparent;
    }
    .container {
      @media all and (max-width: $md) {
        background: transparent;
        padding: 48px 28px 28px 28px;
      }
      padding: 42px;
      position: relative;
      z-index: 1;
      background: $bg-main;
    }
    &--best {
      @media all and (max-width: $md) {
        background: $bg-main;
      }
      z-index: 2;
      &::before {
        @media all and (max-width: $md) {
          height: auto;
          bottom: 0;
        }
        position: absolute;
        left: 0;
        top: 0px;
        right: 0;
        height: 325px;
        display: block;
        content: "";
        z-index: 0;
        @extend %tarrifs-shadow;
      }
      .container {
        @media all and (max-width: $md) {
          background: $bg-main;
        }
      }
    }
    ul.price-list {
      margin-top: 20px;
      margin-bottom: 0px;
      .price-item {
        .price {
          @extend %text-big;
        }
        .currency {
          @extend %text-mini;
        }
        .duration {
          @extend %text-default;
        }
      }
    }
    .button-description {
      margin-top: 20px;
      @media all and (max-width: $md) {
        width: 120px;
      }
      width: 170px;
    }
    .features {
      @media all and (max-width: $md) {
        margin-top: 18px;
      }
      margin-top: 40px;
      margin-bottom: 00px;
    }
    .best {
      font-size: 13px;
      z-index: 2;
      position: absolute;
      top: 0;
      width: 100%;
      height: 28px;
      font-weight: bold;
      display: grid;
      justify-content: center;
      align-content: center;
      color: $text-inverse-main;
      background: $color-accent;
    }
  }
}
</style>
