import firebase from "firebase";
// Initialize Firebase
var config = {
  apiKey: "AIzaSyDZHYaxSgP5ME2jGGBm2m9pVBBstjQi9vM",
  authDomain: "squadinvest-93d66.firebaseapp.com",
  databaseURL: "https://squadinvest-93d66.firebaseio.com",
  projectId: "squadinvest-93d66",
  storageBucket: "squadinvest-93d66.appspot.com",
  messagingSenderId: "506406362842"
};
firebase.initializeApp(config);

export default firebase;
