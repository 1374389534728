<template>
  <div class="offices-component">
    <div class="row">
      <section
        :id="i === 2 ? 'rooms-nav' : ''"
        class="list-section-baner col-6 col-md-12"
        v-for="(l, i) in list"
        :key="i"
      >
        <div
          class="content"
          v-if="l.title && l.desc"
          v-animated-show="utils.handleScroll"
        >
          <Description
            class="default icon-top"
            ref="bannerDescription"
            :title="l.title"
            :desc="l.desc"
            :more="l.more"
            :list="l.list"
            :icon-src="l.icon"
            :button="l.button"
            icon="icon"
            href="#callback"
          />
        </div>
      </section>
      <section class="image col-6 col-md-12">
        <img :src="img.src" :alt="img.alt" />
      </section>
    </div>
  </div>
</template>

<script>
import utils from "../utils";
export default {
  name: "Offices",
  props: {
    theme: {
      default: "default"
    },
    list: Array,
    img: Object
  },
  data() {
    return { utils: utils };
  }
};
</script>

<style lang="scss">
.offices-component {
  padding: 0;
  .row {
    grid-row-gap: 28px;
    grid-gap: 0;
    .image {
      @media all and (max-width: $md) {
        grid-column: 1 / span 12;
        grid-row: 4;
        min-height: 440px;
      }
      grid-row: 2;
      grid-column: 1 / span 6;
      display: grid;
      align-items: stretch;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        max-height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
    .button-component {
      width: 214px;
    }
    .list-section-baner {
      @media all and (max-width: $lg - 1) {
        padding-left: 28px;
        padding-right: 28px;
      }
      @media all and (max-width: $md) {
        padding-left: 14px !important;
        padding-right: 14px !important;
        padding: 30px 0;
      }
      padding: 50px 0;

      padding-left: calc((100vw - 1088px) / 2);
      padding-right: calc((100vw - 1088px) / 2);
      &:nth-child(1) {
        @media all and (max-width: $lg - 1) {
          padding-right: 28px;
        }
        background: $bg-green2-half;
        padding-right: 100px;
      }
      &:nth-child(2) {
        @media all and (max-width: $lg - 1) {
          padding-left: 28px;
        }
        padding-left: 100px;
      }
      &:nth-child(3) {
        @media all and (max-width: $lg - 1) {
          padding-left: 28px;
        }
        background: $bg-green2-half;
        padding-left: 100px;
      }
    }
    .description {
      grid-gap: 16px;

      .description-content {
        margin-top: 20px;
        grid-gap: 16px;
        .more {
          margin-top: 18px;
        }
      }
    }
    .dotted {
      margin-bottom: 15px;
      li {
        @extend %text-big;
        &::before {
          padding-top: 0;
        }
      }
    }
    .desc {
      p {
        margin: 0;
      }
      p + p {
        margin: 17px 0 0 0;
      }
    }
  }
}
</style>
