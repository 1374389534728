const avatar =
  "https://firebasestorage.googleapis.com/v0/b/squadinvest-93d66.appspot.com/o/man%403x.png?alt=media&token=a0205ac6-60f8-4603-a8aa-9178d35c7754";
const banner1 =
  "https://firebasestorage.googleapis.com/v0/b/squadinvest-93d66.appspot.com/o/rent-office%403x.png?alt=media&token=3add633e-12b2-4460-a484-a28f19aad18d";
const banner2 =
  "https://firebasestorage.googleapis.com/v0/b/squadinvest-93d66.appspot.com/o/virtual-office%403x.png?alt=media&token=de805676-3560-48f2-8502-ebd13480cdda";
const banner3 =
  "https://firebasestorage.googleapis.com/v0/b/squadinvest-93d66.appspot.com/o/registration-business%403x.png?alt=media&token=e393ab81-6265-477c-bd1a-5d7fc264e1cd";
const banner4 =
  "https://firebasestorage.googleapis.com/v0/b/squadinvest-93d66.appspot.com/o/commercial-estate%403x.png?alt=media&token=77432823-ee8e-45f0-80f8-9c71aa95a4cb";

const imgCoworking =
  "https://firebasestorage.googleapis.com/v0/b/squadinvest-93d66.appspot.com/o/coworking%403x.png?alt=media&token=829e594b-e940-41d3-8c1f-71ab8cc1f8ce";

const portfolioNew =
  "https://firebasestorage.googleapis.com/v0/b/squadinvest-93d66.appspot.com/o/cafe-new%403x.png?alt=media&token=ec839a83-163a-4652-b0d8-0659dd6106b0";
const portfolioOld =
  "https://firebasestorage.googleapis.com/v0/b/squadinvest-93d66.appspot.com/o/cafe-old%403x.png?alt=media&token=e6119d42-add7-470b-bd40-675a74ebbd83";
const imgCardBank =
  "https://firebasestorage.googleapis.com/v0/b/squadinvest-93d66.appspot.com/o/card-bank%403x.png?alt=media&token=a356eb49-a920-44ae-92ac-99816bbb6955";

const gallery1 =
  "https://firebasestorage.googleapis.com/v0/b/squadinvest-93d66.appspot.com/o/office%403x.png?alt=media&token=b375a495-bce8-45a5-8fe5-7c00e2930020";
const office1 =
  "https://firebasestorage.googleapis.com/v0/b/squadinvest-93d66.appspot.com/o/office2%403x.png?alt=media&token=24bc021d-8c5c-40aa-b372-4420f56e6669";

import iconBankCard from "./assets/icons/icon-under-head/icon-bank-card.svg";
import iconChat from "./assets/icons/icon-under-head/icon-chat.svg";
import iconCounting from "./assets/icons/icon-under-head/icon-counting.svg";
import iconCoworking from "./assets/icons/icon-under-head/icon-coworking.svg";
import iconLaw from "./assets/icons/icon-under-head/icon-law.svg";
import iconPost from "./assets/icons/icon-under-head/icon-post.svg";
import iconSecurity from "./assets/icons/icon-under-head/icon-security.svg";
import iconSmartOffice from "./assets/icons/icon-under-head/icon-smart-office.svg";
import iconAdress from "./assets/icons/icon-under-head/icon-yr-adress.svg";
import iconRentOffice from "./assets/icons/icon-under-head/icon-rent-office.svg";

import iconKontur from "./assets/icons/kontur.svg";
import iconKonturFooter from "./assets/icons/kontur-logo-footer.svg";
import iconFSB from "./assets/icons/fsb.svg";
import iconLogo from "./assets/icons/logo-icon.svg";
import placemark from "./assets/icons/placemark.svg";
import firebase from "./firebase";
import API from "./API";

export default {
  iconLogo: iconLogo,
  placemark: placemark,
  navigation: {
    menu: [
      {
        title: "Аренда помещений",
        href: "/coworking",
        visible: true,
        subMenu: [
          { title: "Коворкинг", href: "/coworking#coworking", visible: true },
          {
            title: "Смарт-офис",
            href: "/coworking#smart_office",
            visible: true
          },
          { title: "Тарифы", href: "/coworking#tarrifs", visible: true },
          { title: "Помещения", href: "/coworking#rooms", visible: true },
          { title: "Мероприятия", href: "/coworking#events", visible: true },
          {
            title: "Управление недвижимостью",
            href: "/coworking#real_estate",
            visible: true
          }
        ]
      },
      {
        title: "Виртуальный офис",
        href: "/offices",
        visible: true,
        subMenu: [
          { title: "Юр. адрес", href: "/offices#jur_adress", visible: true },
          {
            title: "Услуги ресепшн",
            href: "/offices#reception",
            visible: true
          },
          { title: "Тарифы", href: "/offices#tarrifs", visible: true },
          { title: "Частые вопросы", href: "/offices#faq", visible: true },
          {
            title: "Каталог адресов",
            href: "/offices#catalog",
            visible: true
          }
        ]
      },
      {
        title: "Регистрация и поддержка бизнеса",
        href: "/business",
        visible: true,
        subMenu: [
          {
            title: "Открытие счета",
            href: "/business#account_open",
            visible: true
          },
          {
            title: "Правовое сопровождение",
            href: "/business#legal_support",
            visible: true
          },
          {
            title: "Финансовое и бухгалтерское сопровождение",
            href: "/business#fin_support",
            visible: true
          }
        ]
      },
      {
        title: "Управление недвижимостью",
        href: "/real_estate",
        visible: true,
        subMenu: [
          {
            title: "Комплекс решений",
            href: "/real_estate#solutions",
            visible: true
          },
          {
            title: "Преимущества",
            href: "/real_estate#benefits",
            visible: true
          },
          { title: "Объекты", href: "/real_estate#objects", visible: true },
          { title: "Услуги", href: "/real_estate#services", visible: true }
        ]
      }
    ],
    contacts: { phone: "+7 (963) 960 30 30" }
  },
  main: {
    meta: {
      title: "Квадрант инвест",
      description:
        "Квадрант инвест - Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью",
      keywords:
        "Квадрант инвест, Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью"
    },
    banner: [
      {
        img: {
          src: banner1
        },
        text: {
          title: "Аренда офисов",
          desc:
            "Коворкинг — офис, на поиск и ремонт которого не нужно тратить время"
        },
        button: "Забронировать"
      },
      {
        img: {
          src: banner2
        },
        text: {
          title: "Виртуальный офис",
          desc:
            "Получите юридический адрес с услугами <br/>по обработке почты и приему телефонных звонков"
        },
        button: "Забронировать"
      },
      {
        img: {
          src: banner3
        },
        text: {
          title: "Регистрация <br/>и поддержка бизнеса",
          desc:
            "Услуги по правовому и финансовому сопровождению малого и среднего бизнеса."
        },
        button: "Получить"
      },
      {
        img: {
          src: banner4
        },
        text: {
          title: "Управление коммерческой недвижимостью",
          desc:
            "Рационально используем недвижимое имущество, <br>принося компании максимальную прибыль"
        },
        button: "Получить"
      }
    ],

    events: {
      title: "Мероприятия",
      list: [
        {
          date: "12 марта 2019 г.",
          title: "Внеочередная встреча — у нас в гостях Алексей Сергиенко",
          linkTitle: "Подробнее",
          href: "/event1"
        },
        {
          date: "12 марта 2019 г.",
          title: "Внеочередная встреча — у нас в гостях Алексей Сергиенко",
          linkTitle: "Подробнее",
          href: "/event1"
        },
        {
          date: "12 марта 2019 г.",
          title: "Внеочередная встреча — у нас в гостях Алексей Сергиенко",
          linkTitle: "Подробнее",
          href: "/event1"
        }
      ]
    },

    feedback: {
      title: "Почему выбирают услуги<br/>Квадрант Инвест?",
      cons: [
        "Квадрант Инвест — надежная компания <br>с многолетней историей",
        "В управлении компании более 1000 кв. м <br>офисных помещений",
        "В управлении компании более 1000 кв. м <br>офисных помещений"
      ],
      list: [
        {
          avatar: avatar,
          user: "Кирилл Алферов",
          company: "ООО «Колибрик»",
          desc:
            "Всем добрый день. Хочу написать несколько хороших слов о виртуальном офисе Квадрант Инвест. Благодаря ему мы смогли открыть представительство с московским адресом и номером телефона. Думаем арендовать рабочее место здесь на полгода. Очень выгодно получается по цене."
        },
        {
          avatar: avatar,
          user: "Кирилл Алферов 2",
          company: "ООО «Колибрик»",
          desc:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi"
        },
        {
          avatar: avatar,
          user: "Кирилл Алферов 4",
          company: "ООО «Колибрик»",
          desc:
            "Всем добрый день. Хочу написать несколько хороших слов о виртуальном офисе Квадрант Инвест. Благодаря ему мы смогли открыть представительство с московским адресом и номером телефона. Думаем арендовать рабочее место здесь на полгода. Очень выгодно получается по цене."
        }
      ]
    },
    services: {
      title: "Полезные услуги Квадрант Инвест",
      list: [
        {
          title: "Аренда офисов<br/>и помещений",
          href: "/offices",
          button: "Подробнее",
          img: banner1,
          bgColor: "#F46A45",
          bgPosition: "right -120px bottom -20px"
        },
        {
          title: "Регистрация <br/>и поддержка бизнеса",
          href: "/business",
          button: "Подробнее",
          img: banner3,
          bgColor: "#CA9E67",
          bgPosition: "right -20px bottom -20px"
        },
        {
          title: "Управление коммерческой <br/>недвижимостью",
          href: "/real_estate",
          button: "Подробнее",
          img: banner4,
          bgColor: "#58C8F4",
          bgPosition: "right -130px bottom -20px"
        }
      ]
    }
  },
  coworking: {
    // meta: {
    //   title: "Квадрант инвест",
    //   description:
    //     "Квадрант инвест - Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью",
    //   keywords:
    //     "Квадрант инвест, Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью"
    // },
    // cons: {
    //   list: [
    //     {
    //       icon: iconCoworking,
    //       title: "8 рабочих зон коворкинга",
    //       desc:
    //         "Пространство, где вас окружают люди, по-настоящему увлеченные своей работой."
    //     },
    //     {
    //       icon: iconSmartOffice,
    //       title: "7 смарт-офисов",
    //       desc:
    //         "Офисное помещение небольшой площади для одного человека <br/>или небольшой компании."
    //     },
    //     {
    //       icon: iconChat,
    //       title: "2 переговорные комнаты",
    //       desc:
    //         "Переговорные комнаты оборудованы всем необходимым для длительного размещения до 6 человек."
    //     }
    //   ]
    // },
    // features: {
    //   title: "Коворкинги Квадрант Инвест",
    //   list: [
    //     {
    //       title: "Высокоскоростной интернет и МФУ",
    //       desc:
    //         "Беспроводной интернет через Wi-Fi и цветной лазерный МФУ в вашем распоряжении. ",
    //       icon: "icon-wifi"
    //     },
    //     {
    //       title: "Коммунальные платежи и уборка ",
    //       desc:
    //         "Арендуя место в коворкинге, вам не придется думать о коммунальных платежах и уборке.",
    //       icon: "icon-clean"
    //     },
    //     {
    //       title: "Зона отдыха",
    //       desc:
    //         "<p>Функциональная мини-кухня, напитки и удобные диваны с камином — все, что нужно для комфортного отдыха.</p>" +
    //         "<p>На территории бизнес-центра также находится кафе и столовая, где можно приятно провести время. </p>",
    //       icon: "icon-coffee"
    //     },
    //     {
    //       title: "Бесплатная парковка",
    //       desc: "Вам не придется тратить время на поиски места для парковки.",
    //       icon: "icon-parking"
    //     }
    //   ]
    // },
    // gallery: {
    //   list: [
    //     {
    //       img: {
    //         src: gallery1,
    //         alt: ""
    //       }
    //     },
    //     {
    //       img: {
    //         src: gallery1,
    //         alt: ""
    //       }
    //     },
    //     {
    //       img: {
    //         src: gallery1,
    //         alt: ""
    //       }
    //     },
    //     {
    //       img: {
    //         src: gallery1,
    //         alt: ""
    //       }
    //     },
    //     {
    //       img: {
    //         src: gallery1,
    //         alt: ""
    //       }
    //     }
    //   ]
    // },
    // offices: {
    //   list: [
    //     {
    //       icon: iconSmartOffice,
    //       title: "Смарт-офисы",
    //       more: "Полностью оборудованные <br/>офисные помещения",
    //       desc:
    //         "<p>Готовые офисы внутри коворкинга с красивым, стильным, но в то же время практичным ремонтом для одного или компании численностью до 6 человек.</p> " +
    //         "<p>Офисы оборудованы необходимой современной мебелью (столы, стулья, тумбы) и прекрасно подойдут командам, которым нужно заселиться и начать работать в кратчайшие сроки.</p> ",
    //       list: ["Московский юридический адрес (доп. услуга)"],
    //       button: "Записаться на просмотр"
    //     },
    //     {
    //       icon: iconChat,
    //       title: "Переговорные",
    //       more: "Почасовая аренда переговорных комнат внутри коворкинга",
    //       desc:
    //         "<p>Помещения рассчитаны на 6 человек, меблированы и оснащены презентационным оборудованием, необходимым <br/>для длительных совещаний:</p> ",
    //       list: [
    //         "Led Smart TV",
    //         "Флипчарт и магнитно-маркерная доска",
    //         "Кулер с водой"
    //       ],
    //       button: "Забронировать"
    //     },
    //     {
    //       icon: iconRentOffice,
    //       title: "Аренда помещений",
    //       more: "Долгосрочная аренда коммерческой недвижимости",
    //       desc:
    //         "<p>Снимайте в долгосрочную аренду коммерческую недвижимость, которая поможет реализовать текущие потребности вашего бизнеса.",
    //       list: [
    //         "Складские помещения",
    //         "Торговые помещения",
    //         "Офисные помещения",
    //         "Помещения свободного назначения"
    //       ],
    //       button: "Забронировать"
    //     }
    //   ],
    //   img: office1
    // },
    tarrifs: {
      title: "Тарифы",
      list: [
        {
          atitle: "Коворкинг",
          list: [
            {
              title: "Незакрепленное место",
              prices: [
                {
                  price: "4 000",
                  duration: "за неделю"
                },
                {
                  price: "12 000",
                  duration: "за месяц"
                },
                {
                  price: "10 000",
                  duration: "от 3 месяцев"
                }
              ],
              button: "Забронировать",
              list: [
                "Скоростной интернет",
                "Цветной лазерный МФУ",
                "Рабочая зона",
                "Зона отдыха"
              ]
            },
            {
              more: "Выбор большинства",
              title: "Закрепленное место",
              prices: [
                {
                  price: "4 000",
                  duration: "за неделю"
                },
                {
                  price: "12 000",
                  duration: "за месяц"
                },
                {
                  price: "10 000",
                  duration: "от 3 месяцев"
                }
              ],
              button: "Забронировать",
              list: [
                "Скоростной интернет",
                "Цветной лазерный МФУ",
                "Рабочая зона",
                "Зона отдыха",
                "Приемная/секретарская поддержка"
              ]
            },
            {
              title: "Рабочее место на 1 день",
              prices: [
                {
                  price: "400",
                  duration: "/день"
                }
              ],
              call:
                "Позвоните по номеру <br><strong><a href='tel:+79639603030' class='phone-link'>+7 (963) 960 30 30</a></strong>",
              email:
                "Или напишите на почту <br> <strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>"
            }
          ]
        },
        {
          atitle: "Смарт-офис",
          list: [
            {
              title: "Незакрепленное место",
              prices: [
                {
                  price: "4 000",
                  duration: "за неделю"
                },
                {
                  price: "12 000",
                  duration: "за месяц"
                },
                {
                  price: "10 000",
                  duration: "от 3 месяцев"
                }
              ],
              button: "Забронировать",
              list: [
                "Скоростной интернет",
                "Цветной лазерный МФУ",
                "Рабочая зона",
                "Зона отдыха"
              ]
            },
            {
              more: "Выбор большинства",
              title: "Закрепленное место",
              prices: [
                {
                  price: "4 000",
                  duration: "за неделю"
                },
                {
                  price: "12 000",
                  duration: "за месяц"
                },
                {
                  price: "10 000",
                  duration: "от 3 месяцев"
                }
              ],
              button: "Забронировать",
              list: [
                "Скоростной интернет",
                "Цветной лазерный МФУ",
                "Рабочая зона",
                "Зона отдыха",
                "Приемная/секретарская поддержка"
              ]
            },
            {
              title: "Рабочее место на 1 день",
              prices: [
                {
                  price: "400",
                  duration: "/день"
                }
              ],
              call:
                "Позвоните по номеру <br><strong><a href='tel:+79639603030' class='phone-link'>+7 (963) 960 30 30</a></strong>",
              email:
                "Или напишите на почту <br> <strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>"
            }
          ]
        },
        {
          atitle: "Переговорные",
          list: [
            {
              title: "Незакрепленное место",
              prices: [
                {
                  price: "4 000",
                  duration: "за неделю"
                },
                {
                  price: "12 000",
                  duration: "за месяц"
                },
                {
                  price: "10 000",
                  duration: "от 3 месяцев"
                }
              ],
              button: "Забронировать",
              list: [
                "Скоростной интернет",
                "Цветной лазерный МФУ",
                "Рабочая зона",
                "Зона отдыха"
              ]
            },
            {
              more: "Выбор большинства",
              title: "Закрепленное место",
              prices: [
                {
                  price: "4 000",
                  duration: "за неделю"
                },
                {
                  price: "12 000",
                  duration: "за месяц"
                },
                {
                  price: "10 000",
                  duration: "от 3 месяцев"
                }
              ],
              button: "Забронировать",
              list: [
                "Скоростной интернет",
                "Цветной лазерный МФУ",
                "Рабочая зона",
                "Зона отдыха",
                "Приемная/секретарская поддержка"
              ]
            },
            {
              title: "Рабочее место на 1 день",
              prices: [
                {
                  price: "400",
                  duration: "/день"
                }
              ],
              call:
                "Позвоните по номеру <br><strong><a href='tel:+79639603030' class='phone-link'>+7 (963) 960 30 30</a></strong>",
              email:
                "Или напишите на почту <br> <strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>"
            }
          ]
        },
        {
          atitle: "Коммерческие помещения",
          list: [
            {
              title: "Незакрепленное место",
              prices: [
                {
                  price: "4 000",
                  duration: "за неделю"
                },
                {
                  price: "12 000",
                  duration: "за месяц"
                },
                {
                  price: "10 000",
                  duration: "от 3 месяцев"
                }
              ],
              button: "Забронировать",
              list: [
                "Скоростной интернет",
                "Цветной лазерный МФУ",
                "Рабочая зона",
                "Зона отдыха"
              ]
            },
            {
              more: "Выбор большинства",
              title: "Закрепленное место",
              prices: [
                {
                  price: "4 000",
                  duration: "за неделю"
                },
                {
                  price: "12 000",
                  duration: "за месяц"
                },
                {
                  price: "10 000",
                  duration: "от 3 месяцев"
                }
              ],
              button: "Забронировать",
              list: [
                "Скоростной интернет",
                "Цветной лазерный МФУ",
                "Рабочая зона",
                "Зона отдыха",
                "Приемная/секретарская поддержка"
              ]
            },
            {
              title: "Рабочее место на 1 день",
              prices: [
                {
                  price: "400",
                  duration: "/день"
                }
              ],
              call:
                "Позвоните по номеру <br><strong><a href='tel:+79639603030' class='phone-link'>+7 (963) 960 30 30</a></strong>",
              email:
                "Или напишите на почту <br> <strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>"
            }
          ]
        }
      ]
    }
    // map: {
    //   title: "Каталог адресов",
    //   places: {
    //     title: "Адрес коворкинга",
    //     list: [
    //       {
    //         coords: [55.846113, 37.539105],
    //         title: "Автомоторная улица",
    //         place:
    //           "Москва, ул. Автомоторная, д. 1/3, стр. 2, территория МЗ «Спринт»",
    //         metro: "10 минут от станции «Лихоборы» ",
    //         schedule: {
    //           label: "Режим работы:",
    //           desc: "с Пн по Пт с 8:00 до 20:00"
    //         },
    //         phone: ["+7 (963) 960 30 30", "+7 (495) 797 81 22"],
    //         email:
    //           "<strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>"
    //       },
    //       {
    //         coords: [55.656113, 37.649105],
    //         title: "Бакинская улица",
    //         place:
    //           "Москва, ул. Бакинская, д. 1/3, стр. 2, территория МЗ «Спринт»",
    //         metro: "10 минут от станции «Лихоборы» ",
    //         schedule: {
    //           label: "Режим работы:",
    //           desc: "с Пн по Пт с 8:00 до 20:00"
    //         },
    //         phone: ["+7 (963) 960 30 30", "+7 (495) 797 81 22"],
    //         email:
    //           "<strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>"
    //       },
    //       {
    //         coords: [55.746113, 37.439105],
    //         title: "Бойцовская улица",
    //         place:
    //           "Москва, ул. Бойцовская, д. 1/3, стр. 2, территория МЗ «Спринт»",
    //         metro: "10 минут от станции «Лихоборы» ",
    //         schedule: {
    //           label: "Режим работы:",
    //           desc: "с Пн по Пт с 8:00 до 20:00"
    //         },
    //         phone: ["+7 (963) 960 30 30", "+7 (495) 797 81 22"],
    //         email:
    //           "<strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>"
    //       }
    //     ]
    //   }
    // },
    // join: {
    //   img: imgCoworking,
    //   title: "Присоединяйтесь к нашей сети коворкингов!",
    //   desc:
    //     "Если у вас есть свободное помещение и вы хотите использовать его как коворкинг, ты мы готовы помочь реализовать вашу идею.",
    //   button: "Присоединиться"
    // },
    // faq: {
    //   description: {
    //     title: "Частые вопросы<br/> о коворкинге",
    //     desc: "Мы собрали часто задаваемые <br/>вопросы в одном месте."
    //   },
    //   more:
    //     "Если у вас остались вопросы, <br/>позвоните по номеру <strong><a href='tel:+79639603030' class='phone-link'>+7 (963) 960 30 30</a></strong>,<br/>напишите на почту <strong class='email'><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong><br/>или закажите обратный звонок",
    //   button: "Перезвоните мне",
    //   list: [
    //     {
    //       title: "Что такое виртуальный офис?",
    //       desc:
    //         "Виртуальный офис — это услуга предоставления юридического адреса <br/>и секретаря по приему и обработке звонков и другой корреспонденции операторами контакт-центра. Например, многим региональным компаниям может быть накладно иметь представительство в Москве. При помощи виртуального офиса такое представительство может быть организовано."
    //     },
    //     {
    //       title: "Чем виртуальный офис отличается от других офисов?",
    //       desc:
    //         "Виртуальный офис — это услуга предоставления юридического адреса <br/>и секретаря по приему и обработке звонков и другой корреспонденции операторами контакт-центра. Например, многим региональным компаниям может быть накладно иметь представительство в Москве. При помощи виртуального офиса такое представительство может быть организовано."
    //     },
    //     {
    //       title: "Какие возможны другие варианты и отдыха в виртуальном офисе?",
    //       desc:
    //         "Виртуальный офис — это услуга предоставления юридического адреса <br/>и секретаря по приему и обработке звонков и другой корреспонденции операторами контакт-центра. Например, многим региональным компаниям может быть накладно иметь представительство в Москве. При помощи виртуального офиса такое представительство может быть организовано."
    //     },
    //     {
    //       title: "Кто может арендовать виртуальный офис?",
    //       desc:
    //         "Виртуальный офис — это услуга предоставления юридического адреса <br/>и секретаря по приему и обработке звонков и другой корреспонденции операторами контакт-центра. Например, многим региональным компаниям может быть накладно иметь представительство в Москве. При помощи виртуального офиса такое представительство может быть организовано."
    //     }
    //   ]
    // }
  },
  // -------------------------- Виртуальный офис --------------------------
  office: {
    // meta: {
    //   title: "Квадрант инвест",
    //   description:
    //     "Квадрант инвест - Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью",
    //   keywords:
    //     "Квадрант инвест, Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью"
    // },
    // cons: [
    //   {
    //     icon: iconAdress,
    //     title: "Юридический адрес",
    //     desc:
    //       "Надежный юридический адрес <br>для вашей организации <br>от собственника помещения.",
    //     list: ["Подтверждение от собственника"],
    //     button: "Подробнее"
    //   },
    //   {
    //     icon: iconPost,
    //     title: "Почтовое обслуживание",
    //     desc:
    //       "Комплекс корреспондентского обслуживания: от получения писем <br/>в почтовых отделениях до доставки <br/>в любую точку России.",
    //     list: ["Почтовые услуги и подтверждение вашего присутствия"]
    //   },
    //   {
    //     icon: iconSecurity,
    //     title: "Защита от проверок",
    //     desc:
    //       "Создадим условия для эффективного прохождения проверок сотрудниками службы безопасности банка <br/>или инспекторами налоговой службы.",
    //     list: [
    //       "Гарантия прохождения адреса в инстанциях, немассовое написание",
    //       "Выездные проверки в наши офисы"
    //     ]
    //   }
    // ],

    // features: {
    //   title: "Услуги ресепшн",
    //   list: [
    //     {
    //       title: "Обработка почтовой корреспонденции",
    //       desc:
    //         "Используйте адрес в качестве почтового адреса компании. Вся корреспонденция будет переслана вам. <br/>При желании, вы также сможете забрать ее самостоятельно. ",
    //       icon: "icon-post"
    //     },
    //     {
    //       title: "Обработка телефонных звонков ",
    //       desc:
    //         "Получите локальный номер телефона, звонки на который будут приняты от лица вашей компании.",
    //       icon: "icon-phone"
    //     },
    //     {
    //       title: "Курьерские услуги",
    //       icon: "icon-delivery"
    //     },
    //     {
    //       title: "Администрирование встреч и другие услуги",
    //       icon: "icon-handshake"
    //     }
    //   ]
    // },

    tarrifs: {
      title: "Тарифы",
      list: [
        {
          atitle: "Аренда",
          list: [
            {
              title: "Минимальный",
              desc: "Договор аренды сроком на 6 месяцев",
              prices: [
                {
                  price: "40 000",
                  suffix: "руб./полгода"
                }
              ],
              button: "Оформить",
              list: [
                "Юридический адрес",
                "Почтовый адрес",
                "Подтверждение присутствия"
              ]
            },
            {
              more: "Выбор большинства",
              title: "Полный",
              desc: "Договор аренды сроком <br/>на 12 месяцев",
              prices: [
                {
                  price: "75 000",
                  suffix: "руб./год",
                  discount: "вы экономите 5 тыс. рублей"
                }
              ],
              button: "Оформить",
              list: [
                "Юридический адрес",
                "Почтовый адрес",
                "Подтверждение присутствия",
                "Почтовое обслуживание"
              ]
            },
            {
              title: "Индивидуальный",
              desc: "Описание тарифа в несколько строчек",
              call:
                "Позвоните по номеру <br><strong><a href='tel:+79639603030' class='phone-link'>+7 (963) 960 30 30</a></strong>",
              email:
                "Или напишите на почту <br> <strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>"
            }
          ]
        }
      ]
    }

    // faq: {
    //   description: {
    //     title: "Частые вопросы<br/> о виртуальном офисе",
    //     desc: "Мы собрали часто задаваемые <br/>вопросы в одном месте."
    //   },
    //   more:
    //     "Если у вас остались вопросы, <br/>позвоните по номеру <strong><a href='tel:+79639603030' class='phone-link'>+7 (963) 960 30 30</a></strong>,<br/>напишите на почту <strong class='email'><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong><br/>или закажите обратный звонок",
    //   button: "Перезвоните мне",
    //   list: [
    //     {
    //       title: "Что такое виртуальный офис?",
    //       desc:
    //         "Виртуальный офис — это услуга предоставления юридического адреса <br/>и секретаря по приему и обработке звонков и другой корреспонденции операторами контакт-центра. Например, многим региональным компаниям может быть накладно иметь представительство в Москве. При помощи виртуального офиса такое представительство может быть организовано."
    //     },
    //     {
    //       title: "Чем виртуальный офис отличается от других офисов?",
    //       desc:
    //         "Виртуальный офис — это услуга предоставления юридического адреса <br/>и секретаря по приему и обработке звонков и другой корреспонденции операторами контакт-центра. Например, многим региональным компаниям может быть накладно иметь представительство в Москве. При помощи виртуального офиса такое представительство может быть организовано."
    //     },
    //     {
    //       title: "Какие возможны другие варианты и отдыха в виртуальном офисе?",
    //       desc:
    //         "Виртуальный офис — это услуга предоставления юридического адреса <br/>и секретаря по приему и обработке звонков и другой корреспонденции операторами контакт-центра. Например, многим региональным компаниям может быть накладно иметь представительство в Москве. При помощи виртуального офиса такое представительство может быть организовано."
    //     },
    //     {
    //       title: "Кто может арендовать виртуальный офис?",
    //       desc:
    //         "Виртуальный офис — это услуга предоставления юридического адреса <br/>и секретаря по приему и обработке звонков и другой корреспонденции операторами контакт-центра. Например, многим региональным компаниям может быть накладно иметь представительство в Москве. При помощи виртуального офиса такое представительство может быть организовано."
    //     }
    //   ]
    // },

    // map: {
    //   title: "Каталог адресов",
    //   places: {
    //     title: "Адрес коворкинга",
    //     list: [
    //       {
    //         coords: [55.846113, 37.539105],
    //         title: "Автомоторная улица",
    //         place:
    //           "Москва, ул. Автомоторная, д. 1/3, стр. 2, территория МЗ «Спринт»",
    //         metro: "10 минут от станции «Лихоборы» ",
    //         schedule: {
    //           label: "Режим работы:",
    //           desc: "с Пн по Пт с 8:00 до 20:00"
    //         },
    //         phone: ["+7 (963) 960 30 30", "+7 (495) 797 81 22"],
    //         email:
    //           "<strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>"
    //       },
    //       {
    //         coords: [55.656113, 37.649105],
    //         title: "Бакинская улица",
    //         place:
    //           "Москва, ул. Бакинская, д. 1/3, стр. 2, территория МЗ «Спринт»",
    //         metro: "10 минут от станции «Лихоборы» ",
    //         schedule: {
    //           label: "Режим работы:",
    //           desc: "с Пн по Пт с 8:00 до 20:00"
    //         },
    //         phone: ["+7 (963) 960 30 30", "+7 (495) 797 81 22"],
    //         email:
    //           "<strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>"
    //       },
    //       {
    //         coords: [55.746113, 37.439105],
    //         title: "Бойцовская улица",
    //         place:
    //           "Москва, ул. Бойцовская, д. 1/3, стр. 2, территория МЗ «Спринт»",
    //         metro: "10 минут от станции «Лихоборы» ",
    //         schedule: {
    //           label: "Режим работы:",
    //           desc: "с Пн по Пт с 8:00 до 20:00"
    //         },
    //         phone: ["+7 (963) 960 30 30", "+7 (495) 797 81 22"],
    //         email:
    //           "<strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>"
    //       }
    //     ]
    //   }
    // }
  },
  // -------------------------- Виртуальный офис --------------------------
  business: {
    meta: {
      title: "Квадрант инвест",
      description:
        "Квадрант инвест - Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью",
      keywords:
        "Квадрант инвест, Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью"
    },
    cons: [
      {
        icon: iconBankCard,
        title: "Открытие счета в банке",
        desc:
          "Бесплатно оформим счет <br/>в надежном банке для ИП <br/>и малого бизнеса."
      },
      {
        icon: iconLaw,
        title: "Правовое сопровождение",
        desc:
          "Полная юридическая поддержка бизнеса, от регистрации до ведения сложных судебных процессов."
      },
      {
        icon: iconCounting,
        title: "Фин. и бух. сопровождение",
        desc:
          "Учет финансов, сдача отчетности <br/>и управление активами. Взаимодействие с государственными органами."
      }
    ],

    join: {
      img: imgCardBank,
      title: "Бесплатный счет в банке-партнере",
      desc:
        "Откроем счет с бесплатным обслуживанием <br/>на 3 месяца* в банках-партнерах:",
      list: ["Райффайзен", "ВТБ", "Альфа-Банк"],
      more: "*Условия открытия счета могут различаться.",
      button: "Открыть счет"
    },

    features: {
      tabs: ["Правовое,", "финансовое и бухгалтерское  сопровождение"],
      list: [
        {
          list: [
            {
              title: "Первичные регистрационные действия",
              desc:
                "Подготовим необходимый пакет документов, зарегистрируем бизнес в нужной форме<br/>(ООО, ИП, АО, НКО).",
              list: ["Онлайн-регистрация компании (ООО и ИП)"],
              icon: "icon-doc"
            },
            {
              title: "Подготовка пакета рабочих документов ",
              desc:
                "Разработаем удобные и надежные формы договора <br/>и закрывающих документов с учетом специфики бизнеса <br/>и пожеланий. ",
              icon: "icon-doc2"
            },
            {
              title: "Выстраивание трудовых отношений",
              desc:
                "Предложим гибкие формы взаимоотношений с наемными работниками и сторонними подрядчиками при соблюдении норм трудового законодательства.",
              icon: "icon-people"
            },
            {
              title: "Защита и поддержка в суде",
              desc:
                "Ведение судебных процессов в Арбитражном суде, <br/>суде общей юрисдикции и при третейском разбирательстве. Отстоим интересы при защите, получим свое при нападении.",
              icon: "icon-law"
            },
            {
              title:
                "Представление интересов при работе <br/>с государственными органами",
              desc:
                "Окажем содействие в лицензировании и получении разрешительной документации. Оспорим действия <br/>и решения органов власти.",
              icon: "icon-tie"
            }
          ]
        },
        {
          list: [
            {
              title: "Составление с нуля и аудит готовых бизнес моделей",
              desc:
                "Формирование Cash Flow (CF), разработка Unit-экономики, подготовка экономических расчетов, расчет активов, первичный инвестиционный анализ",
              icon: "icon-doc"
            },
            {
              title: "Работа с активами ",
              desc:
                "Управление активами любой сложности с целью получения стабильного дохода или выхода на безубыточность.",
              icon: "icon-doc2"
            },
            {
              title: "Бухгалтерское сопровождение и учет",
              desc:
                "Подготовка и сдача отчетности, контроль налогообложения, проведение сверок. Работа с первичными документами Восстановление бухгалтерского и кадрового учета.",
              icon: "icon-people"
            },
            {
              title: "Финансовое сопровождение",
              desc:
                "Формирование и работа с БДДС любой структуры и объема. Бюджетирование и оптимизация, подготовка управленческой отчетности.",
              icon: "icon-law"
            },
            {
              title: "Аудит",
              desc:
                "Помощь в организации проведения или содействие в прохождении полного финансового аудита или due diligence.",
              icon: "icon-tie"
            }
          ]
        }
      ]
    },
    featuresKontur: {
      title:
        "Квадрант Инвест — официальный партнер <a rel='nofollow'  href='https://kontur.kvadrant-invest.ru' class='kontur'>Контура</a>",
      list: [
        {
          title: "Электронно-цифровая подпись",
          href: "https://kontur.kvadrant-invest.ru",
          desc:
            "Подготовим документы и сделаем электронную цифровую подпись с вашим минимальным участием",
          list: ["Онлайн-регистрация компании (ООО и ИП)"],
          icon: "icon-signature"
        },
        {
          title: "Онлайн-кассы и кассовое оборудование ",
          href: "https://kontur.kvadrant-invest.ru",
          desc:
            "Если вашему проекту нужен кассовый аппарат, <br/>мы готовы помочь в его онлайн-подключении <br/>и поддержке с помощью Контур.ОФД.",
          icon: "icon-cashbox",
          button: "Подключить кассу"
        },
        {
          title: "Сдача отчетности",
          href: "https://kontur.kvadrant-invest.ru",
          desc:
            "Формируйте и сдавайте отчетность во все контролирующие органы с помощью Контур.Экстерн.",
          icon: "icon-book"
        },
        {
          title: "Электронный документооборот",
          href: "https://kontur.kvadrant-invest.ru",
          desc:
            "Обменивайтесь документами через Контур.Диадок <br/>без дублирования на бумаге — счетами-фактурами, актами, накладными и другими документами.",
          icon: "icon-monitor"
        }
      ],
      partners: [
        {
          img: iconKontur,
          href: "https://kontur.kvadrant-invest.ru",
          style: { width: "110px", height: "30px" }
        },
        {
          img: iconFSB,
          href:
            "https://drive.google.com/open?id=17ZTpUisA_MG5qU02NckbVeucFKBjfPS6",
          style: { width: "72px", height: "45px" }
        }
      ]
    },

    tarrifs: {
      title: "Тарифы",
      items: [
        {
          title: "Минимальный",
          desc: "Договор аренды сроком на 6 месяцев",
          prices: [
            {
              price: "40 000",
              suffix: "руб./полгода"
            }
          ],
          button: "Оформить",
          list: [
            "Юридический адрес",
            "Почтовый адрес",
            "Подтверждение присутствия"
          ]
        },
        {
          more: "Выбор большинства",
          title: "Полный",
          desc: "Договор аренды сроком <br/>на 12 месяцев",
          prices: [
            {
              price: "75 000",
              suffix: "руб./год",
              discount: "вы экономите 5 тыс. рублей"
            }
          ],
          button: "Оформить",
          list: [
            "Юридический адрес",
            "Почтовый адрес",
            "Подтверждение присутствия",
            "Почтовое обслуживание"
          ]
        },
        {
          title: "Индивидуальный",
          desc: "Описание тарифа в несколько строчек",
          call:
            "Позвоните по номеру <br><strong><a href='tel:+79639603030' class='phone-link'>+7 (963) 960 30 30</a></strong>",
          email:
            "Или напишите на почту <br> <strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>"
        }
      ]
    }
  },
  realty: {
    meta: {
      title: "Квадрант инвест",
      description:
        "Квадрант инвест - Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью",
      keywords:
        "Квадрант инвест, Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью"
    },
    help: {
      desc:
        "Профессиональная помощь собственникам производственных, складских и офисных помещений, любого формата и назначения, желающих получать стабильный доход от сдачи недвижимости <br/>в аренду, сохраняя свои временные ресурсы.",
      title:
        "Полный комплекс решений по управлению коммерческой недвижимостью:",
      list: [
        "Повышение эффективности использования объектов коммерческой недвижимости;",
        "Создание базы арендаторов и взаимодействие с ними;",
        "Оптимизация кадастровой стоимости объекта <br/>и снижение налоговой нагрузки;",
        "Проведение текущих и капитальных ремонтных работ;",
        "Юридическое сопровождение всех процессов, связанных с коммерческой недвижимостью;",
        "Бухгалтерское сопровождение процессов коммерческого использования недвижимости (первичная документация, учет, отчетность);",
        "Организация ИТ-инфраструктуры и режима (интернет, телефония, парковочные системы, видеонаблюдение, СКУД, ЧОП)."
      ]
    },
    features: {
      title: "Наши преимущества:",
      list: [
        {
          title: "Проводим аудит",
          desc:
            "Определяем отправную точку, вектор развития и потенциал объекта.",
          icon: "icon-check-1"
        },
        {
          title: "Максимально эффективно реализовываем площади ",
          desc:
            "Мы умеем проводить процессы перемещения и уплотнения арендаторов для систематизации процесса сдачи коммерческой недвижимости в аренду. Устраняя стихийность, создаем дополнительные коммерческие площади.",
          icon: "icon-move"
        },
        {
          title: "Прозрачная система арендных ставок",
          desc:
            "Процесс переговоров со всеми существующими арендаторами — очень важный аспект. Используем <br/>и развиваем синергию каждого объекта, формируя уникальный в каждом случае пул арендаторов, <br/>внимательно отслеживая его состав и качество.",
          icon: "icon-loupe"
        },
        {
          title: "Арендатор — наш уважаемый партнер",
          desc:
            "За годы работы мы хорошо научились понимать арендаторов <br/>и их потребности. Наша задача — обеспечить комфортное пребывание арендаторов на наших объектах, что способствует развитию бизнеса, будь это торговые центры, складские помещения или любая другая коммерческая недвижимость.",
          icon: "icon-handshake"
        }
      ]
    },
    portfolio: {
      title: "Наши объекты:",
      labelBefore: "До",
      labelAfter: "После",
      list: [
        {
          before: {
            img: portfolioOld
          },
          after: {
            img: portfolioNew
          },
          title: "Кафе «Пушкин»",
          desc:
            "Кафе в стиле дворянского дома: предметы антиквариата, шикарное обслуживание и традиционная русская кухня.",
          time: "Срок — 3 месяца"
        },
        {
          before: {
            img: portfolioOld
          },
          after: {
            img: portfolioNew
          },
          title: "Кафе «Пушкин» 2",
          desc:
            "Кафе в стиле дворянского дома: предметы антиквариата, шикарное обслуживание и традиционная русская кухня.",
          time: "Срок — 3 месяца"
        },
        {
          before: {
            img: portfolioOld
          },
          after: {
            img: portfolioNew
          },
          title: "Кафе «Пушкин» 3",
          desc:
            "Кафе в стиле дворянского дома: предметы антиквариата, шикарное обслуживание и традиционная русская кухня.",
          time: "Срок — 3 месяца"
        }
      ]
    }
  },
  contacts: {
    info: {
      title: "Свяжитесь с нами",
      address:
        "<span itemprop='postalCode'>125438</span>, <span itemprop='addressLocality'>Москва</span>, <br><span itemprop='streetAddress'>ул. Автомоторная, д. 1/3, стр. 2, 6 этаж</span>",
      schedule:
        "<time itemprop='openingHours' datetime='Mo, Tu, We, Th, Fr'>С понедельника по пятницу <br>10:00 — 19:00</time>",
      phone: ["+7 (963) 960 30 30", "+7 (495) 797 81 22"],
      email:
        "<strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>",
      form: {
        name: "Как вас зовут?",
        email: "Эл. почта",
        phone: "Номер телефона",
        message: "Сообщение",
        agreement:
          'Соглашаюсь на <a href="/agreement" class="link-agreement">обработку персональных данных</a>',
        button: "Отправить"
      }
    }
  },
  events: {
    event1: {
      meta: {
        title: "Квадрант инвест",
        description:
          "Квадрант инвест - Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью",
        keywords:
          "Квадрант инвест, Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью"
      },
      id: 1,
      title:
        "Квадрант Инвест в партнерстве c Amdocs начинает цифровую реорганизацию IT-инфраструктуры",
      date: "05 февраля 2019 года",
      desc:
        "<p>Москва / Сент-Луис, 5 февраля 2019 г. — Квадрант Инвест объявляет о начале нового цифрового проекта по модернизации внутренней IT-инфраструктуры, реализуемого в партнерстве с компанией Amdocs, лидером на рынке поставщиков программного обеспечения и сервисов в области коммуникаций и медиа.</p>",
      contacts: {
        address:
          "<span itemprop='postalCode'>125438</span>, <span itemprop='addressLocality'>Москва</span>, <br><span itemprop='streetAddress'>ул. Автомоторная, д. 1/3, стр. 2, 6 этаж</span>",
        phone: "+7 (963) 960 30 30",
        email:
          "<strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>",
        button: "Связаться"
      }
    },
    event2: {
      meta: {
        title: "Квадрант инвест",
        description:
          "Квадрант инвест - Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью",
        keywords:
          "Квадрант инвест, Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью"
      },
      id: 2,
      title:
        "Квадрант Инвест в партнерстве c Amdocs начинает цифровую реорганизацию IT-инфраструктуры",
      date: "05 февраля 2019 года",
      desc:
        "<p>Москва / Сент-Луис, 5 февраля 2019 г. — Квадрант Инвест объявляет о начале нового цифрового проекта по модернизации внутренней IT-инфраструктуры, реализуемого в партнерстве с компанией Amdocs, лидером на рынке поставщиков программного обеспечения и сервисов в области коммуникаций и медиа.</p>",
      contacts: {
        address:
          "<span itemprop='postalCode'>125438</span>, <span itemprop='addressLocality'>Москва</span>, <br><span itemprop='streetAddress'>ул. Автомоторная, д. 1/3, стр. 2, 6 этаж</span>",
        phone: "+7 (963) 960 30 30",
        email:
          "<strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>",
        button: "Связаться"
      }
    },
    event3: {
      meta: {
        title: "Квадрант инвест",
        description:
          "Квадрант инвест - Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью",
        keywords:
          "Квадрант инвест, Аренда помещений, Виртуальный офис, Регистрация и поддержка бизнеса, Управление недвижимостью"
      },
      id: 3,
      title:
        "Квадрант Инвест в партнерстве c Amdocs начинает цифровую реорганизацию IT-инфраструктуры",
      date: "05 февраля 2019 года",
      desc:
        "<p>Москва / Сент-Луис, 5 февраля 2019 г. — Квадрант Инвест объявляет о начале нового цифрового проекта по модернизации внутренней IT-инфраструктуры, реализуемого в партнерстве с компанией Amdocs, лидером на рынке поставщиков программного обеспечения и сервисов в области коммуникаций и медиа.</p>",
      contacts: {
        address:
          "<span itemprop='postalCode'>125438</span>, <span itemprop='addressLocality'>Москва</span>, <br><span itemprop='streetAddress'>ул. Автомоторная, д. 1/3, стр. 2, 6 этаж</span>",
        phone: "+7 (963) 960 30 30",
        email:
          "<strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>",
        button: "Связаться"
      }
    }
  },
  callback: {
    info: {
      description: {
        title: "Консультация",
        desc: "Заполните форму ниже, <br/>и наш специалист перезвонит вам"
      },
      form: {
        name: {
          placeholder: "Представьтесь, пожалуйста"
        },
        phone: {
          placeholder: "Укажите номер телефона"
        },
        btn: {
          title: "Перезвонить мне"
        },
        agreement:
          'Соглашаюсь на <a href="/agreement" class="link-agreement">обработку персональных данных</a>',
        recal: {
          text: "Или позвоните по номеру ",
          phone: "+7 963 960-30-30"
        },
        sent: {
          desc:
            "<p>Спасибо за&nbsp;обращение.</p> <p>Мы&nbsp;свяжемся с&nbsp;вами в&nbsp;ближайшее время.</p>"
        }
      }
    }
  },
  footer: {
    info: {
      desc: "© 1992–2019, «Квадрант Инвест»",
      policy: {
        title: "Пользовательское соглашение",
        href: "/policy"
      },
      madein:
        "Сделано в «<a class='link inverse' href='https://www.bermudas.pro/' target='blank'>Бермудах</a>»<br/>в 2019 году"
    },
    menu: {
      list: [
        {
          title: "Аренда помещений",
          href: "/coworking",
          subMenu: [
            { title: "Коворкинг", href: "/coworking#coworking", visible: true },
            {
              title: "Смарт-офис",
              href: "/coworking#smart_office",
              visible: true
            },
            { title: "Тарифы", href: "/coworking#tarrifs", visible: true },
            { title: "Помещения", href: "/coworking#rooms", visible: true },
            { title: "Мероприятия", href: "/coworking#events", visible: true },
            {
              title: "Управление<br> недвижимостью",
              href: "/coworking#real_estate",
              visible: true
            }
          ]
        },
        {
          title: "Виртуальный офис",
          href: "/offices",
          subMenu: [
            { title: "Юр. адрес", href: "/offices#jur_adress", visible: true },
            {
              title: "Услуги ресепшн",
              href: "/offices#reception",
              visible: true
            },
            { title: "Тарифы", href: "/offices#tarrifs", visible: true },
            { title: "Частые вопросы", href: "/offices#faq", visible: true },
            {
              title: "Каталог адресов",
              href: "/offices#catalog",
              visible: true
            }
          ]
        },
        {
          title: "Регистрация <br>и поддержка бизнеса",
          href: "/business",
          subMenu: [
            {
              title: "Открытие счета",
              href: "/business#account_open",
              visible: true
            },
            {
              title: "Правовое сопровождение",
              href: "/business#legal_support",
              visible: true
            },
            {
              title: "Финансовое и бухгалтерское сопровождение",
              href: "/business#fin_support",
              visible: true
            },
            {
              title:
                "Электронные сервисы <img alt='kontur-logo' class='kontur' src='" +
                iconKonturFooter +
                "'>",
              href: "/business#partners",
              visible: true
            }
          ]
        }
      ]
    },
    contacts: {
      title: "Контакты",
      place: "125438, Россия, Москва, ул. Автомоторная, д. 1/3, стр. 2.",
      schedule: {
        label: "Режим работы:",
        desc: "с Пн по Пт с 8:00 до 20:00"
      },
      phone: ["+7 (963) 960 30 30", "+7 (495) 797 81 22"],
      email:
        "<strong><a href='mailto:info@sitename.ru'><span itemprop='email'>info@sitename.ru</span></a></strong>"
    }
  },
  fetchDoc(collection, doc) {
    return new Promise(resolve => {
      const db = firebase.firestore();
      const settings = {};
      db.settings(settings);
      var data = {};
      db.collection(collection)
        .doc(doc)
        .get()
        .then(result => {
          resolve(result.data());
        })
        .catch(err => {
          data = this[collection][doc];
          resolve(data);
        });
    });
  },
  fetchData(collection, limit) {
    return new Promise(resolve => {
      if (this.cached.hasOwnProperty(collection)) {
        resolve(this.cached[collection]);
      }
      const db = firebase.firestore();
      const settings = {};
      db.settings(settings);
      var data = {};
      if (limit) {
        db.collection(collection)
          .orderBy("id", "desc")
          .limit(limit)
          .get()
          .then(result => {
            result.docs.forEach(element => {
              data[element.id] = element.data();
            });
            this.cached[collection] = data;
            resolve(data);
          })
          .catch(() => {
            data = this[collection];
            this.cached[collection] = data;
            resolve(data);
          });
      } else {
        db.collection(collection)
          .get()
          .then(result => {
            result.docs.forEach(element => {
              data[element.id] = element.data();
            });
            this.cached[collection] = data;
            resolve(data);
          })
          .catch(() => {
            data = this[collection];
            this.cached[collection] = data;
            resolve(data);
          });
      }
    });
  },
  uploadAppData() {
    const db = firebase.firestore();
    const settings = {};
    db.settings(settings);
    let collections = [
      // "navigation",
      // "main",
      // "coworking"
      "office"
      // "business",
      // "realty",
      // "contacts",
      // "events",
      // "callback",
      // "footer"
    ];
    collections.forEach(element => {
      var data = this[element];
      for (let key in data) {
        if (Array.isArray(data[key])) {
          db.collection(element)
            .doc(key)
            .set({ list: data[key] })
            .then(result => {})
            .catch(err => {});
        } else {
          db.collection(element)
            .doc(key)
            .set(data[key])
            .then(result => {})
            .catch(err => {});
        }
      }
    });
  },
  sendMail(name, email, phone, message) {
    return new Promise((resolve, reject) => {
      API.feedback(
        {
          name: name,
          email: email,
          phone: phone,
          message: message
        },
        respone => {
          resolve(respone);
        },
        error => {
          reject(error);
        }
      );
      // const db = firebase.firestore();
      // const settings = {};
      // db.settings(settings);
      // db.collection("mail")
      //   .add({
      //     name: name,
      //     email: email,
      //     phone: phone,
      //     message: message,
      //     date: new Date().getTime()
      //   })
      //   .then(result => {
      //     resolve(result);
      //   })
      //   .catch(err => {
      //     reject(err);
      //   });
    });
  },
  callRequest(name, phone) {
    return new Promise((resolve, reject) => {
      API.feedback(
        {
          name: name,
          email: "",
          phone: phone,
          message: "Запрос звонка"
        },
        respone => {
          resolve(respone);
        },
        error => {
          reject(error);
        }
      );

      // const db = firebase.firestore();
      // const settings = {};
      // db.settings(settings);
      // db.collection("calls")
      //   .add({
      //     name: name,
      //     phone: phone,
      //     date: new Date().getTime()
      //   })
      //   .then(result => {
      //     resolve(result);
      //   })
      //   .catch(err => {
      //     reject(err);
      //   });
    });
  },
  cached: {}
};
