<template>
  <div id="app">
    <div id="App-main">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {}
};
</script>

<style lang="scss">
#app {
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-main;
}

html,
body {
  width: 100%;
  height: 100%;
}
body {
  @media all and (max-width: $sm) {
    cursor: pointer;
  }
  margin: 0 auto;
  display: grid;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  min-height: 100%;
}
.stop-scrolling {
  height: 100vh;
  overflow: hidden;
  position: fixed;
}

@font-face {
  font-family: ProximaNova, Arial, sans-serif;
  font-display: auto;
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/svg/ProximaNova-Bold.svg") format("svg"),
    url("./assets/fonts/woff/ProximaNova-Bold.woff") format("woff"),
    url("./assets/fonts/otf/ProximaNova-Bold.otf") format("otf");
}

@font-face {
  font-family: ProximaNova, Arial, sans-serif;
  font-display: auto;
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/svg/ProximaNova-Regular.svg") format("svg"),
    url("./assets/fonts/woff/ProximaNova-Regular.woff") format("woff"),
    url("./assets/fonts/otf/ProximaNova-Regular.otf") format("otf");
}
</style>
