<template>
  <div class="portfolio-container">
    <div class="area">
      <div class="row" v-animated-show="utils.handleScroll">
        <h2 class="col-12 col-md-12" v-html="title"></h2>
        <section
          class="portfolio-item col-12 col-md-12"
          v-for="(l, i) in list"
          :key="i"
          :class="[{ current: current === i }]"
        >
          <div class="row">
            <a
              class="nav-arrow nav-arrow--prev col-1 col-md-1"
              href="/"
              @click.prevent="
                () => {
                  nextPortfolio(-1);
                }
              "
            ></a>
            <div class="images col-7 col-md-12">
              <span class="before" v-html="before"></span>
              <span class="after" v-html="after"></span>
              <img
                class="img-before"
                :src="l.before.img.src"
                :alt="l.before.img.alt"
              />
              <img
                class="img-after"
                :src="l.after.img.src"
                :alt="l.after.img.alt"
              />
            </div>
            <div class="portfolio col-3 col-md-12">
              <h3 v-html="l.title"></h3>
              <p class="desc" v-html="l.desc"></p>
              <p class="time" v-html="l.time"></p>
            </div>
            <a
              class="nav-arrow nav-arrow--next col-1 col-md-1"
              href="/"
              @click.prevent="
                () => {
                  nextPortfolio(1);
                }
              "
            ></a>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../utils";
export default {
  name: "Portfolio",
  props: {
    theme: {
      default: "default"
    },
    title: String,
    before: String,
    after: String,
    list: Array,
    cons: Array
  },
  data() {
    return {
      current: 0,
      utils: utils
    };
  },
  methods: {
    nextPortfolio(offset) {
      this.current =
        (this.current + offset + this.list.length) % this.list.length;
    }
  },
  components: {}
};
</script>

<style lang="scss">
.portfolio-container {
  @extend %page-block;
  background: $bg-green-half;
  .row {
    grid-row-gap: 28px;
    .button-description {
      width: 214px;
      margin-top: 28px;
    }
    .reason {
      @media all and (max-width: $md) {
        grid-row: unset;
        grid-column: span 12;
      }
      grid-row: 1;
      grid-column: 1 / span 4;
      display: grid;
      grid-gap: 38px;
    }
  }
  .images {
    @media all and (max-width: 1088px) {
      left: 0;
      grid-row: 1;
      grid-column: 1 / span 12;
      align-self: center;
      grid-template-rows: 15px 1fr 15px;
    }
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    grid-template-rows: 35px 1fr 35px;
    span {
      @media all and (max-width: $md) {
        display: none;
      }
      @extend %text-mini;
      font-weight: bold;
      color: $color-accent;
    }
    .before {
      grid-column: 1;
      grid-row: 3;
      align-self: flex-end;
      justify-self: flex-start;
    }
    .after {
      grid-column: 7;
      grid-row: 1;
      align-self: flex-start;
      justify-self: flex-end;
    }
    img {
      max-height: 446px;
      min-width: 100%;
      object-fit: cover;
      min-height: 177px;
      border-radius: 14px;
      align-self: stretch;
      &.img-before {
        grid-column: 1 / span 5;
        grid-row: 1 / span 2;
        -webkit-clip-path: polygon(0 0, 90% 0, 75% 100%, 0 100%);
        clip-path: polygon(0 0, 90% 0, 75% 100%, 0 100%);
      }
      &.img-after {
        -webkit-clip-path: polygon(25% 0, 100% 0, 100% 100%, 10% 100%);
        clip-path: polygon(25% 0, 100% 0, 100% 100%, 10% 100%);
        grid-column: 3 / span 5;
        grid-row: 2 / span 2;
      }
    }
  }
  .portfolio-item {
    @extend %switch-anim;
    align-self: center;
    align-content: flex-start;
    grid-gap: 20px;
    display: grid;
    grid-row: 2;
    grid-column: 1 / span 12;
    &.current {
      position: relative;
      z-index: 2;
    }
    > .row {
      align-items: center;
      align-content: center;
    }
    .portfolio {
      h3 {
        @media all and (max-width: $md) {
          margin-top: 28px;
        }
        margin-top: 100px;
      }
      .desc {
        @extend %text-big;
      }
      .time {
        @extend %text-big;
        @media all and (max-width: $md) {
          margin-top: 28px;
        }
        margin-top: 35px;
      }
    }
  }
  .nav-arrow {
    @extend %action-shadow;
    width: 28px;
    height: 28px;
    padding: 0;
    background: $bg-main;
    cursor: pointer;
    display: grid;
    text-decoration: none;
    justify-content: center;
    align-content: center;
    &::before {
      font-family: fontello;
    }
    &.nav-arrow--prev {
      @media all and (max-width: 1088px) {
        left: 0;
        grid-row: 1;
        grid-column: 1;
        align-self: center;
        z-index: 2;
      }
      &::before {
        content: "\e814";
        color: $color-accent;
      }
    }
    &.nav-arrow--next {
      @media all and (max-width: 1088px) {
        left: unset;
        right: 0;
        grid-row: 1;
        grid-column: 12;
        align-self: center;
        z-index: 2;
      }
      &::before {
        content: "\e813";
        color: $color-accent;
      }
      justify-self: flex-end;
    }
    &:hover {
      transition: background-color 0.5s;
      background: $color-accent;
      &::before {
        transition: color 0.5s;
        color: $text-inverse-main;
      }
    }
  }
}
</style>
