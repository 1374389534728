<template>
  <div class="event-full">
    <BasicTemplate>
      <EventContainer
        v-if="data"
        :title="data.title"
        :desc="data.desc"
        :date="data.date"
        :contacts="data.contacts"
      />
    </BasicTemplate>
  </div>
</template>

<script>
import BasicTemplate from "./BasicTemplate.vue";
import EventContainer from "../containers/EventContainer.vue";

import AppData from "../AppData";
import utils from "../utils";
export default {
  name: "EventFull",
  data() {
    return {
      data: undefined,
      utils: utils,
      metaTitle: undefined,
      metaDescription: undefined,
      metaKeywords: undefined
    };
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "title", property: "title", content: this.metaTitle },
        {
          name: "description",
          property: "description",
          content: this.metaDescription
        },
        { name: "keywords", property: "keywords", content: this.metaKeywords }
      ]
    };
  },
  methods: {},
  mounted() {
    let id = parseInt(this.$route.params.id);
    if (id) {
      AppData.fetchDoc("events", "event" + id)
        .then(result => {
          this.metaTitle = result.meta.title;
          this.metaDescription = result.meta.description;
          this.metaKeywords = result.meta.keywords;
          this.data = result;

          setTimeout(() => {
            document.dispatchEvent(new Event("x-app-rendered"));
          }, 5000);
        })
        .catch(err => {
          this.data = undefined;

          setTimeout(() => {
            document.dispatchEvent(new Event("x-app-rendered"));
          }, 5000);
        });
    }
  },
  components: {
    BasicTemplate,
    EventContainer
  }
};
</script>

<style lang="scss">
.event-full {
  .banner-header .description .more {
    @media all and (max-width: $md) {
      margin-bottom: 25px;
      margin-top: 20px;
    }
  }
  .banner {
    .img-banner {
      @media all and (max-width: $md) {
        object-fit: cover;
        max-height: 180px;
        max-width: auto;
        height: 180px;
        transform: translateX(10%);
      }
      @media all and (max-width: $sm) {
        transform: translateX(10%);
      }
    }
  }
}
</style>
