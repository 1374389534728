<template>
  <div>
    <nav class="App-nav">
      <div class="menu-container" ref="menuContainer">
        <div class="menu-content">
          <ul class="menu">
            <li v-for="(v, i) in menu" :key="i">
              <router-link
                active-class="current"
                :to="v.href"
                tag="a"
                :href="v.href"
                ref="menuItems"
                v-html="v.title"
                @mouseover.native="
                  () => {
                    onMouseOver(i);
                  }
                "
                @mouseout.native="
                  () => {
                    onMouseOut(i);
                  }
                "
              ></router-link>
            </li>
            <li>
              <a
                class="contacts-link"
                v-html="'Контакты'"
                @click.prevent="showContacts"
              ></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="divider"></div>
      <div class="menu-container">
        <div class="menu-content">
          <ul v-if="submenu" class="submenu">
            <li
              v-for="(s, i) in submenu"
              :key="i"
              class="submenu-element"
              v-show="s.visible"
            >
              <router-link
                tag="a"
                :to="s.href"
                :href="s.href"
                v-html="s.title"
                class="active"
                @click.native="onClick"
              ></router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { clearTimeout } from "timers";
export default {
  name: "Nav",
  props: {
    menu: Array,
    pageId: Number
  },
  data: function() {
    return {
      current: 0,
      timer: 0
    };
  },
  computed: {
    submenu() {
      return this.menu[this.current].subMenu;
    }
  },
  mounted() {
    const el = this.$refs.menuItems[this.pageId].$el;
    this.current = this.pageId;
    this.$refs.menuContainer.scrollTo(
      el.getBoundingClientRect().x -
        this.$refs.menuContainer.clientWidth / 2 +
        el.clientWidth / 2,
      0
    );
  },
  methods: {
    showContacts() {
      this.$emit("contacts");
    },
    onMouseOver(index) {
      this.current = index;
      clearTimeout(this.timer);
    },
    onMouseOut() {
      // this.timer = setTimeout(() => {
      //   this.current = this.pageId;
      // }, 500);
    },
    onClick() {}
  }
};
</script>

<style lang="scss">
.App-nav {
  .contacts-link {
    @media all and(max-width: $md) {
      display: block;
    }
    display: none;
  }
  .divider {
    @media all and(max-width: $md) {
      width: 100%;
      display: none;
    }
    width: 2000px;
    height: 1px;
    background: $bg-grey-dark;
    opacity: 0.5;
    position: relative;
    top: -1px;
  }
  .menu-container {
    @media all and(max-width: $md) {
      width: 100vw;
      margin: 0 -14px;
      overflow: scroll;
    }
    display: grid;
    grid-template-columns: 1fr;
    $scrollBarHeight: 1px;
    &::-webkit-scrollbar {
      width: $scrollBarHeight;
      height: $scrollBarHeight;
    }

    &::-webkit-scrollbar-button {
      width: $scrollBarHeight;
      height: $scrollBarHeight;
    }
    .menu-content {
      @media all and(max-width: $md) {
        padding: 0 14px;
      }
      ul.menu {
        @media all and(max-width: $lg) {
          grid-gap: 20px;
        }
        @media all and(max-width: $md) {
          grid-gap: 24px;
          border-bottom: 1px solid $bg-grey-dark;
        }
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        margin-bottom: 0;
        grid-gap: 24px;

        li {
          @extend %text-small;
          display: grid;
          justify-content: flex-start;
          height: 53px;
          align-items: flex-end;
          a {
            @extend %link;
            align-self: flex-end;
            color: $text-inactive;
            border-bottom-width: 2px;
            border-bottom-color: transparent;
            &:hover,
            &.router-link-exact-active {
              color: $text-main;
              border-bottom-color: $color-accent;
            }
            padding-bottom: 16px;
          }
        }
      }
      ul.submenu {
        @media all and(max-width: $md) {
          grid-gap: 24px;
        }
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        margin-bottom: 0;
        grid-gap: 24px;
        li {
          @extend %text-small;
          display: grid;
          height: 55px;
          line-height: 55px;
          align-items: flex-end;
          a {
            @extend %link;
            justify-self: flex-start;
            align-self: flex-end;
            color: $text-main;
            border-bottom: 0;
            &:hover {
              color: $color-accent;
            }
          }
        }
      }
    }
  }
}
</style>
