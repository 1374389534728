<template>
  <div class="cons-component">
    <div class="area">
      <div class="row cons-container" v-animated-show="utils.handleScroll">
        <section
          class="list-section-baner  col-4 col-md-12"
          v-for="(l, i) in list"
          :key="i"
        >
          <div class="content" v-if="l.title && l.desc">
            <Description
              class="small icon-top"
              ref="bannerDescription"
              :title="l.title"
              :desc="l.desc"
              :icon-src="l.icon"
              :list="l.list"
              :button="l.button"
              href="#callback"
              icon="icon"
            />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../utils";
export default {
  name: "Cons",
  props: {
    theme: {
      default: "default"
    },
    list: Array
  },
  data() {
    return { utils: utils };
  }
};
</script>

<style lang="scss">
.cons-component {
  @extend %page-block;
  .row {
    grid-row-gap: 28px;
    &.cons-container {
      @media all and (max-width: $md) {
        grid-row-gap: 28px;
      }
    }
  }
  .button-description {
    @extend %text-big;
    width: 186px;
  }
  .dotted {
    @media all and (max-width: $md) {
      margin: 5px 0;
    }
  }
}
</style>
