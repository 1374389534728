<template>
  <div class="help-container">
    <div class="area">
      <div class="row" v-animated-show="utils.handleScroll">
        <p class="desc col-8 col-md-12" v-html="desc"></p>
        <h2 class="col-7 col-md-12" v-html="title"></h2>
        <ul class="checked col-12 col-md-12">
          <li v-for="(l, i) in list" :key="i" v-html="l"></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../utils";
export default {
  name: "Help",
  props: {
    theme: {
      default: "default"
    },
    title: String,
    desc: String,
    list: Array
  },
  data() {
    return {
      current: 0,
      utils: utils
    };
  },
  methods: {
    nextFeedback(offset) {
      this.current =
        (this.current + offset + this.list.length) % this.list.length;
    }
  },
  components: {}
};
</script>

<style lang="scss">
.help-container {
  @extend %page-block;
  .row {
    grid-row-gap: 28px;
    .button-description {
      width: 214px;
      margin-top: 28px;
    }
    .desc {
      @extend %text-big;
      margin-bottom: 0;
    }
    .checked {
      @media all and (max-width: $md) {
        display: grid;
        max-height: unset;
        grid-gap: 15px;
      }
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 290px;

      li {
        @media all and (max-width: $md) {
          width: auto;
          margin-top: 0;
        }
        width: calc(50% - 40px);
        margin-top: 10px;
      }
    }
  }
}
</style>
