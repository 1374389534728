<template>
  <div class="business">
    <BasicTemplate :page-id="2">
      <Banner :index="2" />
      <Cons v-if="data" :list="data.cons.list" id="account_open-nav" />
      <Join v-if="data" :data="data.join" class="bank" />
      <FeaturesBusiness
        v-if="data"
        :tabs="data.features.tabs"
        :list="data.features.list"
      />
      <Features
        v-if="data"
        id="partners-nav"
        :title="data.featuresKontur.title"
        :list="data.featuresKontur.list"
        :partners="data.featuresKontur.partners"
        :is-double="true"
        :no-consult="true"
        :is-underline="true"
      />
      <Services
        v-if="main"
        :title="main.services.title"
        :list="main.services.list"
      />
    </BasicTemplate>
  </div>
</template>

<script>
import BasicTemplate from "./BasicTemplate.vue";
import Banner from "../containers/Banner.vue";
import Cons from "../containers/Cons.vue";
import FeaturesBusiness from "../containers/FeaturesBusiness.vue";
import Features from "../containers/Features.vue";
import Join from "../containers/Join.vue";
import Services from "../containers/Services.vue";

import AppData from "../AppData";
import utils from "../utils";
export default {
  name: "Business",
  data() {
    return {
      data: undefined, //AppData.business,
      main: undefined, //AppData.main,
      utils: utils,
      metaTitle: undefined,
      metaDescription: undefined,
      metaKeywords: undefined
    };
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "title", property: "title", content: this.metaTitle },
        {
          name: "description",
          property: "description",
          content: this.metaDescription
        },
        {
          name: "keywords",
          property: "keywords",
          content: this.metaKeywords
        }
      ]
    };
  },
  methods: {},
  mounted() {
    AppData.fetchData("business").then(data => {
      this.data = data;
      this.metaTitle = data.meta.title;
      this.metaDescription = data.meta.description;
      this.metaKeywords = data.meta.keywords;
      if (this.data && this.main) {
        setTimeout(() => {
          document.dispatchEvent(new Event("x-app-rendered"));
        }, 5000);
      }
    });
    AppData.fetchData("main").then(data => {
      this.main = data;
      if (this.data && this.main) {
        setTimeout(() => {
          document.dispatchEvent(new Event("x-app-rendered"));
        }, 5000);
      }
    });
  },
  components: {
    BasicTemplate,
    Banner,
    Cons,
    FeaturesBusiness,
    Features,
    Join,
    Services
  }
};
</script>

<style lang="scss">
.business {
  .banner-header .description .more {
    @media all and (max-width: $md) {
      margin-bottom: 25px;
      margin-top: 20px;
    }
  }
  .banner {
    .img-banner {
      @media all and (max-width: $md) {
        object-fit: cover;
        max-height: 180px;
        max-width: auto;
        height: 180px;
        transform: translateX(10%);
      }
      @media all and (max-width: $sm) {
        transform: translateX(10%);
      }
    }
  }
}
</style>
