<template>
  <div>
    <BasicTemplate :page-id="0">
      <Banner :index="0" />
      <Cons v-if="data" :list="data.cons.list" />
      <Features
        v-if="data"
        :title="data.features.title"
        :list="data.features.list"
        id="coworking-nav"
      />
      <Gallery v-if="data" :list="data.gallery.list" />
      <Offices
        v-if="data"
        :list="data.offices.list"
        :img="data.offices.img"
        id="smart_office-nav"
      />
      <Tarrifs
        v-if="data"
        :title="data.tarrifs.title"
        :list="data.tarrifs.list"
        :menu="data.tarrifs.menu"
        id="tarrifs-nav"
      />
      <Events
        v-if="data"
        :title="main.events.title"
        :list="main.events.list"
        id="events-nav"
      />
      <Map v-if="data" :title="data.map.title" :places="data.map.places" />
      <Join v-if="data" :data="data.join" />
      <Faq v-if="data" :data="data.faq" />
      <Feedback
        v-if="main"
        :title="main.feedback.title"
        :list="main.feedback.list"
        :cons="main.feedback.cons"
      />
      <Services
        v-if="main"
        :title="main.services.title"
        :list="main.services.list"
        id="real_estate-nav"
      />
    </BasicTemplate>
  </div>
</template>

<script>
import BasicTemplate from "./BasicTemplate.vue";
import Banner from "../containers/Banner.vue";
import Cons from "../containers/Cons.vue";
import Features from "../containers/Features.vue";
import Gallery from "../containers/Gallery.vue";
import Offices from "../containers/Offices.vue";
import Tarrifs from "../containers/Tarrifs.vue";
import Events from "../containers/Events.vue";
import Map from "../containers/Map.vue";
import Join from "../containers/Join.vue";
import Faq from "../containers/Faq.vue";
import Feedback from "../containers/Feedback.vue";
import Services from "../containers/Services.vue";

import AppData from "../AppData";
import utils from "../utils";
export default {
  name: "Coworking",
  data() {
    return {
      data: undefined, //AppData.coworking,
      main: undefined, //AppData.main,
      utils: utils,
      metaTitle: undefined,
      metaDescription: undefined,
      metaKeywords: undefined
    };
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "title", property: "title", content: this.metaTitle },
        {
          name: "description",
          property: "description",
          content: this.metaDescription
        },
        {
          name: "keywords",
          property: "keywords",
          content: this.metaKeywords
        }
      ]
    };
  },
  methods: {},
  mounted() {
    AppData.fetchData("coworking").then(data => {
      this.data = data;
      this.metaTitle = data.meta.title;
      this.metaDescription = data.meta.description;
      this.metaKeywords = data.meta.keywords;
      if (this.data && this.main) {
        setTimeout(() => {
          document.dispatchEvent(new Event("x-app-rendered"));
        }, 5000);
      }
    });
    AppData.fetchData("main").then(data => {
      this.main = data;
      if (this.data && this.main) {
        setTimeout(() => {
          document.dispatchEvent(new Event("x-app-rendered"));
        }, 5000);
      }
    });
  },
  components: {
    BasicTemplate,
    Banner,
    Cons,
    Features,
    Gallery,
    Offices,
    Tarrifs,
    Events,
    Map,
    Join,
    Faq,
    Feedback,
    Services
  }
};
</script>

<style lang="scss">
.coworking {
  .banner-header .description .more {
    @media all and (max-width: $md) {
      margin-bottom: 25px;
      margin-top: 20px;
    }
  }
}
</style>
