import Vue from "vue";
import Router from "vue-router";
import Home from "./pages/Home.vue";
import Coworking from "./pages/Coworking.vue";
import Office from "./pages/Office.vue";
import Business from "./pages/Business.vue";
import Realty from "./pages/Realty.vue";
import EventFull from "./pages/EventFull.vue";
import utils from "./utils";
Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/coworking/",
      name: "Coworking",
      component: Coworking
    },
    {
      path: "/offices/",
      name: "Office",
      component: Office
    },
    {
      path: "/business/",
      name: "Business",
      component: Business
    },
    {
      path: "/real_estate/",
      name: "Realty",
      component: Realty
    },
    {
      path: "/event:id/",
      name: "EventFull",
      component: EventFull
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      if (savedPosition) {
        return savedPosition;
      } else {
        if (from.path === to.path) {
          let start =
            document.documentElement.scrollTop || document.body.scrollTop;
          setTimeout(() => {
            var el = document.getElementById(to.hash.replace("#", "") + "-nav");
            if (el) {
              utils.scrollTo(el);
            }
          }, 100);
          return { x: 0, y: start };
        } else {
          setTimeout(() => {
            var el = document.getElementById(to.hash.replace("#", "") + "-nav");
            if (el) {
              utils.scrollTo(el);
            }
          }, 1000);
          return { x: 0, y: 0 };
        }
      }
    } else if (savedPosition) {
      // setTimeout(() => {
      //   utils.scrollToPosition(el);
      // }, 500);
      return savedPosition;
    } else {
      if (to.path === from.path && to.hash === "" && from.hash !== "") {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    }
  }
});
